import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";

// reducers
import LoginReducer from "REDUX/Slices/ProviderLoginSlice";
import AppSlice from "REDUX/Slices/AppSlice";
import SiteSlice from "REDUX/Slices/SiteSlice";
import UserSlice from "REDUX/Slices/UserSlice";
import PopupSlice from "./Slices/PopupSlice";

const config = {
  key: 'root',
  blacklist: ['loading'],
  debug: true, // to get useful logging
}

const store = configureStore({
  reducer: {
    login: LoginReducer,
    app: AppSlice,
    site: SiteSlice,
    user: UserSlice,
    popUp: PopupSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(logger),
  devTools: true, // Enable Redux DevTools
});

export default store;

