import React, { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import "./StudiesComponent.scss";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import { resolvePreset } from "@babel/core";
import Switch from "@material-ui/core/Switch";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import Cancel from "COMMON/Cancel/Cancel";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import STRINGS from "CONSTANTS/Strings.js";
import { API_ENDPOINT } from "../../api/ApiBaseUrl";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@material-ui/icons/Refresh";
const drawerWidth = "27.5rem";
var dataFetched = false;
var startDateFromSearch = "";
var endDateFromSearch = "";
function createData(
  studyIuid,
  PatientName,
  DateofBirth,
  DateofStudy,
  PatientEmail,
  MobileNumber,
  Status,
  Site,
  studyId
) {
  const density = DateofStudy / PatientEmail;
  return {
    studyIuid,
    PatientName,
    DateofBirth,
    DateofStudy,
    PatientEmail,
    MobileNumber,

    Status,

    Site,
    density,
    studyId,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 25,
    height: 15,
    padding: 0,
    cursor: "pointer",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(10px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "red",
      border: "6px solid red",
    },
  },
  thumb: {
    width: 12,
    height: 12,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid black`,
    backgroundColor: "grey",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const headCells = [
  { id: "PatientName", label: "Patient Name" },
  { id: "DateofBirth", label: "Date of Birth" },
  {
    id: "DateofStudy",
    label: "Date of Study",
    // minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "PatientEmail",
    label: "Patient Email",
    //  minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "MobileNumber",
    label: "Mobile Number",
    //minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },

  {
    id: "Status",
    label: "Status",
    // minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Site",
    label: "Site",
    //minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Icon1",
    //minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Icon2",
    // minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#C9D9EF" }}
        ></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            style={{ backgroundColor: "#C9D9EF" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: "100%",
  },
  container: {
    maxHeight: "100%",
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: "#E4E4E4",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    color: "#484848",
    font: "normal normal bold 20px Comfortaa",
    marginTop: "1%",
  },
  // AddFilterBtn: {
  //   flex: "1 1 100%",
  //   width: "100%",
  //   height: "100%",
  //   boxShadow: "0px 3px 6px #00000029",
  //   border: "1px solid #2D2929",
  //   borderRadius: "51px",
  //   opacity: 1,
  //   textTransform: "none",
  //   marginLeft: "20px",
  //   font: "normal normal bold 13px Comfortaa",
  // },
  DownloadStudyBtn: {
    flex: "1 1 100%",
    width: "100%",
    height: "42px",
    background: "#484848 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "51px",

    opacity: 1,
    color: "white",
    textTransform: "none",
    font: "normal normal bold 13px Comfortaa",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  refreshBtn: {
    flex: "1 1 100%",
    width: "100%%",
    height: "42px",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #2D2929",
    background: "#484848 0% 0% no-repeat padding-box",
    color: "#fff",
    borderRadius: "51px",
    opacity: 1,
    textTransform: "none",
    font: "normal normal bold 13px Comfortaa",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  // TextField: {
  //     flex: "1 1 100%",
  //     marginTop: "2%",
  //     width: "700px"
  // },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    color: "white",
  },
  SearchField: {
    width: "80%",
    marginTop: "2.6%",
    height: "100%",
    textAlign: "left",
    font: "normal normal bold 15px / 17px Comfortaa",
    letterSpacing: "0px",
    color: "#242424",
    opacity: 0.5,
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const [startDate, setStartDate] = React.useState(props.startDate);
  const [endDate, setEndDate] = React.useState(props.searchEndDate);

  const handleSearch = (e) => {
    props.handleSearch(e.target.value);
  };

  const downloadStudy = (e, studyId, studyIuid) => {
    if (studyId != "") {
      props.setopenDialogDownload(true);
      const config = {
        method: "GET",
        headers: { "content-type": "application/json" },
        credentials: "include",
      };
      fetch(
        `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/studySupportManager?method=getFileAssociatedWithStudy&studyId=${studyId}`,
        config
      )
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          const studyFilesData = JSON.parse(responseData.studyFiles);
          props.setDownloadDataState(studyFilesData);
        });
    }
  };

  const setSDate = (e) => {
    setStartDate(e.target.value);
    props.handleSearchByDates(e, e.target.value, endDate);
  };
  const setEDate = (e) => {
    setEndDate(e.target.value);
    props.handleSearchByDates(e, startDate, e.target.value);
  };
  const refreshBTn = () => {
    var startDateFormated = new Date(startDate);
    var endDateFormated = new Date(endDate);
    startDateFromSearch = startDateFormated;
    endDateFromSearch = endDateFormated;
    props.callGetStudyApi(startDateFormated, endDateFormated);
  };
  return (
    <div className="toolbar">
      <CardContent>
        <div>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={11}>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Recent Studies
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <div>
                  {" "}
                  <TextField
                    id="date"
                    label="Start Date"
                    type="date"
                    value={startDate}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={setSDate}
                  />
                </div>
              </Grid>
              {/* <div ></div> */}
              <Grid item xs={2}>
                <div>
                  {" "}
                  <TextField
                    // style={{ marginLeft: "50px" }}
                    id="date"
                    label="End Date"
                    type="date"
                    value={endDate}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={setEDate}
                  />
                </div>
              </Grid>
              {/* <div style={{ margin: "1%" }}></div> */}
              <Grid item xs={4}>
                <div>
                  <TextField
                    className={classes.SearchField}
                    placeholder="Search for Patient Name,DOB,Email,Mobile No."
                    inputProps={{ "aria-label": "search google maps" }}
                    onChange={handleSearch}
                  />
                  <IconButton type="submit" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </div>
              </Grid>
              <Grid item xs={1}>
                <Button
                  onClick={() => props.holdStudy()}
                  className={classes.DownloadStudyBtn}
                >
                  Hold Study
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  onClick={() => props.releasedStudy()}
                  className={classes.DownloadStudyBtn}
                >
                  Release Study
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  onClick={(e) =>
                    downloadStudy(e, props.studyId, props.studyIuid)
                  }
                  className={classes.DownloadStudyBtn}
                >
                  Download Study
                </Button>
              </Grid>
              <Grid item xs>
                <Button className={classes.refreshBtn}>
                  <RefreshIcon
                    onClick={refreshBTn}
                    style={{ height: "2rem", width: "2rem" }}
                  />
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </CardContent>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "5%",
    flexGrow: 1,
  },
  paper: {
    width: "auto",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
    borderBottomLeftRadius: "30px",
    borderBottomRightRadius: "30px",
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: 450,
    height: 450,
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    paddingLeft: theme.spacing(2),
  },
  tableRow: {
    backgroundColor: "#DADADA",
  },
  toggaleSwitch: {
    Color: "green",
  },
  yestBtn: {
    width: " 20%",
    fontSize: "15px",
    // @extend.Comfortaa_Bold
    borderRadius: "51px",
    backgroundColor: " #484848",
    color: "#ffffff",
    border: "1px solid black",
    opacity: 1,
    letterSpacing: "0px",
  },
  noBtn: {
    marginLeft: "5%",
    width: " 20%",
    fontSize: "15px",
    // @extend.Comfortaa_Bold
    borderRadius: "51px",
    backgroundColor: " #ffffff",
    color: "black",
    border: "1px solid black",
    opacity: 1,
    letterSpacing: "0px",
  },
  cancelBtn: {
    width: " 20%",
    fontSize: "15px",
    // @extend.Comfortaa_Bold
    borderRadius: "51px",
    backgroundColor: "#484848",
    color: "#ffffff",
    border: "1px solid black",
    opacity: 1,
    letterSpacing: "0px",
  },
  loaderStyle: {
    position: "absolute",
    marginLeft: "45%",
    marginTop: "10%",
  },
}));
var status_RealesedHold = "";
var studyId_RealesedHold = "";

export default function StudiesComponent() {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const [open, setOpen] = React.useState(false);
  const [studyResData, setStudyResData] = React.useState([]);
  const [studyIuid, setStudyIuid] = React.useState("");
  const [patientName, setPatientName] = React.useState("");
  const [patientDob, setPatientDOB] = React.useState("");
  const [patientEmail, setPatientEmail] = React.useState("");
  const [patientMobile, setPatientMobile] = React.useState("");
  const [dataEdited, setDataEdited] = React.useState(false);
  const [SelectedCheck, setSelectedCheck] = React.useState("");
  const [studyId, setStudyId] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(true);
  const [status, setStatus] = React.useState("");
  const [isRowPerPageSelect, setIsRowPerPageSelect] = React.useState(true);
  var sDate = new Date();
  sDate.setMonth(sDate.getMonth() - 1);
  var dd = String(sDate.getDate()).padStart(2, "0");
  var mm = String(sDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = sDate.getFullYear();
  sDate = yyyy + "-" + mm + "-" + dd;

  const [startDate, setStartDate] = React.useState(sDate);

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [searchEndDate, setSearchEndDate] = React.useState(today);
  const [temprows, seTemprows] = React.useState([]);
  const [downloadData, setDownloadData] = React.useState([]);
  const [holdReleasedMassage, setHoldReleasedMassage] = React.useState("");
  const [holdReleasedTitle, setHoldReleasedTitle] = React.useState("");
  const [holdButtonClicked, setHoldButtonClicked] = useState(false);
  const [releasButtonClicked, setReleasButtonClicked] = useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [openDeleteStudy, setOpenDeleteStudy] = React.useState(false);

  useEffect(() => {
    var startDateFormated = new Date(startDate);
    var endDateFormated = new Date(searchEndDate);
    startDateFromSearch = startDateFormated;
    endDateFromSearch = endDateFormated;
    callGetStudyApi(startDateFormated, endDateFormated);
  }, []);

  const handleRelesedHold = (event, studyId, Status) => {
    status_RealesedHold = Status;
    studyId_RealesedHold = studyId;
    if (Status === "New") {
      setHoldReleasedMassage(STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_HOLD);
      setHoldReleasedTitle(STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_TITLE);
      handleClickOpenToggleBtnNotification();
    }
    if (Status === "Released") {
      setHoldReleasedMassage(
        STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_RE_RELEASED
      );
      setHoldReleasedTitle(
        STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_TITLE_RE_RELEASED
      );
      handleClickOpenToggleBtnNotification();
    }

    if (Status === "OnHold") {
      setHoldReleasedMassage(
        STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_RELEASED
      );
      setHoldReleasedTitle(
        STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_TITLE_RELEASED
      );
      handleClickOpenToggleBtnNotification();
    }
    if (Status === "AutoReleased") {
      setHoldReleasedMassage(
        STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_RELEASED
      );
      setHoldReleasedTitle(
        STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_TITLE_RELEASED
      );
      handleClickOpenToggleBtnNotification();
    }
  };
  const onHoldReleasedFunction = () => {
    if (status === "OnHold") {
      status_RealesedHold = "Released";
    }
    if (status === "New" && holdButtonClicked) {
      status_RealesedHold = "OnHold";
    }
    if (status === "New" && releasButtonClicked) {
      status_RealesedHold = "Released";
    }
    if (status === "AutoReleased" && releasButtonClicked) {
      status_RealesedHold = "Released";
    }
    if (status === "Released" && releasButtonClicked) {
      status_RealesedHold = "Released";
    }

    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/studySupportManager?method=releaseHoldStudyStudy&studyId=${studyId}&studyStatus=${status_RealesedHold}`,
      {
        method: "GET",
        header: {
          Accept: "application/json",
        },
        body: JSON.stringify(),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        callGetStudyApi(startDateFromSearch, endDateFromSearch);
        console.log(responseData);
        handleCloseToggleBtnNotification();
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  };
  const yesButtonClick = () => {
    setShowLoader(true);
    onHoldReleasedFunction();
    setStudyIuid("");
    setStudyId("");
    setStatus("");
  };
  // useEffect(() => {
  //
  //   callGetStudyApi();
  // }, []);

  const callGetStudyApi = (startDateByDatePicker, endDateByDatePicker) => {
    const config = {
      method: "GET",
      headers: { "content-type": "application/json" },
      credentials: "include",
    };

    let startDateForApi = "";
    let endDateForApi = "";
    if (
      startDateByDatePicker !== undefined &&
      endDateByDatePicker !== undefined
    ) {
      startDateForApi = startDateByDatePicker * 1;
      endDateForApi = endDateByDatePicker * 1;
    } else {
      const currentDate = new Date();
      startDateForApi = new Date().setDate(currentDate.getDate() - 30);
      endDateForApi = new Date().setDate(currentDate.getDate());
    }
    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/studymanager?startTime=${startDateForApi}&stopTime=${endDateForApi}`,
      config
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
        if (responseData != undefined) {
          let studyData = [];
          dataFetched = true;
          responseData.list.sort(
            (a, b) => new Date(b.studyDatetime) - new Date(a.studyDatetime)
          );
          setStudyResData(responseData.list);

          responseData.list.map((item) => {
            let data = createData(
              item.studyIuid,
              item.patientName,
              item.patientDob,
              item.studyDatetime,
              item.patientEmail,
              item.patientMobile,
              item.status,
              item.siteName,
              item.id,
              <div>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </div>
            );
            studyData.push(data);
          });
          setRows(studyData);
          seTemprows(studyData);
          setShowLoader(false);
          if (searchKeyword !== "") {
            handleSearch(searchKeyword);
          }
        }
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  };

  const save = () => {
    const studyData = studyResData;
    var studyObj = studyData.filter(function (entry) {
      return entry.studyIuid === studyIuid;
    });

    const studyReqDataObject = {
      studyIuid: studyIuid,
      status: studyObj[0].status,
      patientEmail: patientEmail,
      releaseAgain: studyObj[0].releaseAgain,
      id: studyObj[0].id,
      patientMobile: patientMobile,
      accessionNo: studyObj[0].accessionNo,
      patientId: studyObj[0].patientId,
      studyDatetime: studyObj[0].studyDatetime,
      patientName: patientName,
      authcode: studyObj[0].authcode,
      processingStatus: studyObj[0].processingStatus,
      studyId: studyObj[0].studyId,
      studyDesc: studyObj[0].studyDesc,
      patientDob: patientDob,
      siteName: studyObj[0].siteName,
    };
    fetch(`${API_ENDPOINT.BaseUrl}/bb-bbwebsite/studymanager`, {
      method: "POST",
      header: {
        Accept: "application/json",
      },
      body: JSON.stringify(studyReqDataObject),
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        callGetStudyApi(startDateFromSearch, endDateFromSearch);
        console.log(responseData);
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
    handleClose();
  };

  const handleChangePatientName = (event) => {
    setDataEdited(true);
    setPatientName(event.target.value);
  };

  const handleChangePatientDOB = (event, lastName) => {
    setDataEdited(true);
    setPatientDOB(event.target.value);
  };

  const handleChangePatientEmail = (event, emailAddress) => {
    setDataEdited(true);
    setPatientEmail(event.target.value);
  };

  const handleChangePatientMobile = (event, phoneNumber) => {
    setDataEdited(true);
    setPatientMobile(event.target.value);
  };

  const handleSearch = (searchKeyword) => {
    setSearchKeyword(searchKeyword);
    setIsRowPerPageSelect(false);
    let val = searchKeyword.toLowerCase();
    if (val === "") {
      setIsRowPerPageSelect(true);
    }
    let matches = temprows.filter((v) => {
      return (
        v.PatientName?.toLowerCase().includes(val) ||
        v.DateofBirth?.toLowerCase().includes(val) ||
        v.PatientEmail?.toLowerCase().includes(val) ||
        v.MobileNumber?.toLowerCase().includes(val)
      );
    });
    console.log(matches);
    setRows(matches);
  };

  const handleSearchByDates = (e, startDate, endDate) => {
    var startDate = new Date(startDate);
    var endDate = new Date(endDate);
    startDateFromSearch = startDate;
    endDateFromSearch = endDate;
    // var resultProductData = temprows.filter(a => {
    //   var date = new Date(a.DateofStudy);
    //   return (date >= startDate && date <= endDate);
    // });
    // setRows(resultProductData);
    callGetStudyApi(startDate, endDate);
  };

  const handleCancelStudyDelete = () => {
    setStudyId("");
    setOpenDeleteStudy(false);
  };

  const handleStudyDelete = () => {
    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/studySupportManager?method=deleteExistingStudy&studyId=${studyId}`,
      {
        method: "GET",
        header: {
          Accept: "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        debugger;
        callGetStudyApi(startDateFromSearch, endDateFromSearch);
        console.log(responseData);
        setStudyId("");
        setOpenDeleteStudy(false);
      })
      .catch((err) => {
        debugger;
        console.log("fetch error" + err);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setIsRowPerPageSelect(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (event, row) => {
    if (open === false) {
      setStudyIuid(row.studyIuid);
      setPatientName(row.PatientName);
      setPatientDOB(row.DateofBirth);
      setPatientEmail(row.PatientEmail);
      setPatientMobile(row.MobileNumber);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setStudyIuid("");
  };

  const selectCheckBox = (e, studyId, sIuid, status) => {
    if (sIuid === studyIuid) {
      setStudyIuid("");
      setStudyId("");
      setStatus("");
    } else {
      setStudyId(studyId);
      setStudyIuid(sIuid);
      setStatus(status);
    }
  };
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  const [openDialogDownload, setopenDialogDownload] = React.useState(false);

  const dialogColseDownload = () => {
    setopenDialogDownload(false);
    setStudyId("");
    setStudyIuid("");
  };

  const setDownloadDataState = (data) => {
    setDownloadData(data);
  };

  const downloadSingleFile = (e, url) => {
    window.location = `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/study/getstudyfile?url=${url}`;
  };
  const [openToggleBtnNotification, setOpenToggleBtnNotification] =
    React.useState(false);

  const handleClickOpenToggleBtnNotification = () => {
    setOpenToggleBtnNotification(true);
  };

  const handleCloseToggleBtnNotification = () => {
    setOpenToggleBtnNotification(false);
  };
  const [openHoldDrawer, setOpenHoldDrawer] = React.useState(false);
  const handleDraweOpen = () => {
    setOpenHoldDrawer(true);
  };
  const handleDraweClose = () => {
    setOpenHoldDrawer(false);
  };
  const holdStudy = () => {
    setHoldButtonClicked(true);
    setReleasButtonClicked(false);
    if (studyId !== "" && status === "New") {
      setHoldReleasedMassage(STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_HOLD);
      setHoldReleasedTitle(STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_TITLE);
      handleClickOpenToggleBtnNotification();
    }
    if (status === "Released") {
      handleDraweOpen();
    }
  };
  const releasedStudy = () => {
    setReleasButtonClicked(true);
    setHoldButtonClicked(false);

    if (studyId !== "") {
      if (studyId !== "" && status === "New") {
        setHoldReleasedMassage(
          STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_RELEASED
        );
        setHoldReleasedTitle(
          STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_TITLE_RELEASED
        );
        handleClickOpenToggleBtnNotification();
      }
      if (status === "OnHold") {
        setHoldReleasedMassage(
          STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_RELEASED
        );
        setHoldReleasedTitle(
          STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_TITLE_RELEASED
        );
        handleClickOpenToggleBtnNotification();
      }
      if (status === "Released") {
        setHoldReleasedMassage(
          STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_RE_RELEASED
        );
        setHoldReleasedTitle(
          STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_TITLE_RE_RELEASED
        );
        handleClickOpenToggleBtnNotification();
      }
      if (status === "AutoReleased") {
        setHoldReleasedMassage(
          STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_RELEASED
        );
        setHoldReleasedTitle(
          STRINGS.TOGGLE_POPPUP_HOLD_MSG.TOGGLE_POPPUP_TITLE_RELEASED
        );
        handleClickOpenToggleBtnNotification();
      }
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          rows={rows}
          handleSearch={handleSearch}
          studyIuid={studyIuid}
          studyId={studyId}
          searchEndDate={searchEndDate}
          startDate={startDate}
          setopenDialogDownload={setopenDialogDownload}
          setDownloadDataState={(e, data) => setDownloadDataState(e, data)}
          handleSearchByDates={(e, startDate, endDate) =>
            handleSearchByDates(e, startDate, endDate)
          }
          holdStudy={holdStudy}
          releasedStudy={releasedStudy}
          callGetStudyApi={callGetStudyApi}
        />

        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              className={classes.tableHeader}
            />
            {showLoader === true ? (
              <CircularProgress className={classes.loaderStyle} />
            ) : (
              <TableBody>
                {isRowPerPageSelect
                  ? stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        let isItemSelected = false;
                        row.studyIuid === studyIuid
                          ? (isItemSelected = true)
                          : (isItemSelected = false);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        let isRelaedOrHold = true;
                        if (row.Status === "OnHold") {
                          isRelaedOrHold = false;
                        } else {
                          isRelaedOrHold = true;
                        }

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index + "Studies"}
                            selected={isItemSelected}
                            className={index % 2 !== 0 ? classes.tableRow : ""}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                onChange={(e) =>
                                  selectCheckBox(
                                    e,
                                    row.studyId,
                                    row.studyIuid,
                                    row.Status
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "12px" }}
                              component="th"
                              id={labelId}
                              scope="row"
                              align="left"
                            >
                              {row.PatientName}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "12px" }}
                              align="left"
                            >
                              {row.DateofBirth}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "12px" }}
                              align="left"
                            >
                              {row.DateofStudy}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "12px" }}
                              align="left"
                            >
                              {row.PatientEmail}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "12px" }}
                              align="left"
                            >
                              {row.MobileNumber}
                            </TableCell>

                            <TableCell
                              style={{ fontSize: "12px" }}
                              align="left"
                            >
                              {row.Status}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "12px" }}
                              align="left"
                            >
                              {row.Site}
                            </TableCell>
                            <TableCell
                              onClick={(e) => handleClickOpen(e, row)}
                              align="left"
                            >
                              {<EditIcon />}
                            </TableCell>
                            <TableCell align="left">
                              {
                                <DeleteIcon
                                  style={{ color: "red" }}
                                  onClick={() => {
                                    setStudyId(row.studyId);
                                    setOpenDeleteStudy(true);
                                  }}
                                />
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        let isItemSelected = false;
                        row.studyIuid === studyIuid
                          ? (isItemSelected = true)
                          : (isItemSelected = false);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        let isRelaedOrHold = true;
                        if (row.Status === "OnHold") {
                          isRelaedOrHold = false;
                        } else {
                          isRelaedOrHold = true;
                        }

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index + "Studies"}
                            selected={isItemSelected}
                            className={index % 2 !== 0 ? classes.tableRow : ""}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                onChange={(e) =>
                                  selectCheckBox(
                                    e,
                                    row.studyId,
                                    row.studyIuid,
                                    row.Status
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "12px" }}
                              component="th"
                              id={labelId}
                              scope="row"
                              align="left"
                            >
                              {row.PatientName}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "12px" }}
                              align="left"
                            >
                              {row.DateofBirth}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "12px" }}
                              align="left"
                            >
                              {row.DateofStudy}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "12px" }}
                              align="left"
                            >
                              {row.PatientEmail}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "12px" }}
                              align="left"
                            >
                              {row.MobileNumber}
                            </TableCell>

                            <TableCell
                              style={{ fontSize: "12px" }}
                              align="left"
                            >
                              {row.Status}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "12px" }}
                              align="left"
                            >
                              {row.Site}
                            </TableCell>
                            <TableCell
                              onClick={(e) => handleClickOpen(e, row)}
                              align="left"
                            >
                              {<EditIcon />}
                            </TableCell>
                            <TableCell align="left">
                              {
                                <DeleteIcon
                                  style={{ color: "red" }}
                                  onClick={() => {
                                    setStudyId(row.studyId);
                                    setOpenDeleteStudy(true);
                                  }}
                                />
                              }
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          style={{ marginRight: "40%" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <div>
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            PaperProps={{
              style: {
                borderRadius: "40px",
                width: "100%",
                height: "50%",
                overflow: "hidden",
              },
            }}
          >
            <div id="customized-dialog-title" onClose={handleClose}></div>
            <div>
              <Fragment>
                <div className="provider__container">
                  <div className="provider__title">
                    <span className="provider__title__text">
                      {STRINGS.EDITSTUDYFORM.TITLE}
                    </span>
                  </div>

                  <div className="profileDetailFields">
                    <div className={classes.root}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id={STRINGS.EDITSTUDYFORM.FIRST_NAME}
                            label={STRINGS.EDITSTUDYFORM.FIRST_NAME}
                            type="text"
                            value={patientName}
                            name={STRINGS.EDITSTUDYFORM.FIRST_NAME}
                            onChange={handleChangePatientName}
                            //errorBorder={isNameHighlighted}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id={STRINGS.EDITSTUDYFORM.LAST_NAME}
                            label={STRINGS.EDITSTUDYFORM.LAST_NAME}
                            type="text"
                            value={patientDob}
                            name={STRINGS.EDITSTUDYFORM.LAST_NAME}
                            onChange={(event) => handleChangePatientDOB(event)}
                            //errorBorder={isNameHighlighted}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id={STRINGS.EDITSTUDYFORM.EMAIL}
                            label={STRINGS.EDITSTUDYFORM.EMAIL}
                            type="text"
                            value={patientEmail}
                            name={STRINGS.EDITSTUDYFORM.EMAIL}
                            onChange={(event) =>
                              handleChangePatientEmail(event)
                            }
                            //errorBorder={isNameHighlighted}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id={STRINGS.EDITSTUDYFORM.PHONE_NO}
                            label={STRINGS.EDITSTUDYFORM.PHONE_NO}
                            type="text"
                            value={patientMobile}
                            name={STRINGS.EDITSTUDYFORM.PHONE_NO}
                            onChange={(event) =>
                              handleChangePatientMobile(event)
                            }
                            //errorBorder={isNameHighlighted}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div className="profileButtons">
                    <Button className={"button__style"} onClick={save}>
                      {STRINGS.EDITSTUDYFORM.SAVE_BUTTON}
                    </Button>
                    <Button className={"cancel_Button"} onClick={handleClose}>
                      {STRINGS.EDITSTUDYFORM.CANCEL_BUTTON}
                    </Button>
                  </div>
                </div>
              </Fragment>
            </div>
          </Dialog>
        </div>
        <div>
          <Dialog
            onClose={dialogColseDownload}
            aria-labelledby="customized-dialog-title"
            open={openDialogDownload}
            PaperProps={{
              style: {
                borderRadius: "30px",
              },
            }}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={dialogColseDownload}
              style={{ color: "#FF4355" }}
            >
              Study
            </DialogTitle>
            <DialogContent dividers>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">File Name</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {downloadData.map((row) => (
                      <TableRow key={row.filename}>
                        <TableCell align="right">{row.type}</TableCell>
                        <TableCell align="right">{row.filename}</TableCell>
                        <TableCell align="right">
                          <SaveAltIcon
                            onClick={(e) => downloadSingleFile(e, row.url)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          </Dialog>
        </div>
        <div>
          <Dialog
            open={openToggleBtnNotification}
            onClose={handleCloseToggleBtnNotification}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                borderRadius: "30px",
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {holdReleasedTitle}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {holdReleasedMassage}
              </DialogContentText>
            </DialogContent>
            <div style={{ textAlign: "center", marginBottom: "5%" }}>
              <Button className={classes.yestBtn} onClick={yesButtonClick}>
                YES
              </Button>
              <Button
                className={classes.noBtn}
                onClick={handleCloseToggleBtnNotification}
              >
                NO
              </Button>
            </div>
          </Dialog>
        </div>

        <div>
          <Dialog
            open={openDeleteStudy}
            onClose={handleCancelStudyDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                borderRadius: "30px",
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">Delete Study?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete study
              </DialogContentText>
            </DialogContent>
            <div style={{ textAlign: "center", marginBottom: "5%" }}>
              <Button className={classes.yestBtn} onClick={handleStudyDelete}>
                YES
              </Button>
              <Button
                className={classes.noBtn}
                onClick={handleCancelStudyDelete}
              >
                NO
              </Button>
            </div>
          </Dialog>
        </div>

        <Dialog
          open={openHoldDrawer}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              borderRadius: "30px",
              width: "100%",
            },
          }}
        >
          <DialogContent>
            <div onClick={handleDraweClose}>
              <Cancel />
            </div>
            <DialogContentText style={{ color: "black", textAlign: "center" }}>
              Hold Study?
            </DialogContentText>
            <DialogContent dividers>
              <Typography
                gutterBottom
                style={{ color: "black", textAlign: "center" }}
              >
                You can not hold released study!
              </Typography>
            </DialogContent>
          </DialogContent>
        </Dialog>
      </Paper>
    </div>
  );
}
