import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// import "./SetupMachineIntructionPop.css";
import Cancel from "COMMON/Cancel/Cancel";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { hideInstruction, hidesiteDetailsForm, showInstruction } from "../../redux/Slices/SiteSlice";
import { useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import { useHistory } from "react-router-dom";
import {
  setupMachineOpen,
} from "../../redux/Slices/AppSlice";
const useStyles = makeStyles((theme) => ({
  orange: {
    color: "white",
    backgroundColor: "#4B5264",
    marginLeft: "10%",
    marginRight: "2.5rem",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "8%",
  },
  para1: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: "6%",
    width: "100%",
  },
  para2: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: "6%",
    width: "100%",
  },
  orange2: {
    color: "white",
    backgroundColor: "#4B5264",
    marginLeft: "10%",
    marginRight: "2.5rem",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "15%",
  },
  para3: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: "15%",
    width: "100%",
  },
  orange3: {
    color: "white",
    backgroundColor: "#4B5264",
    marginLeft: "10%",
    marginRight: "2.5rem",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "25%",
  },
  para4: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: "16%",
    width: "100%",
  },
  orange4: {
    color: "white",
    backgroundColor: "#4B5264",
    marginLeft: "10%",
    marginRight: "2.5rem",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "30%",
  },
  para5: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: "5%",
    width: "100%",
  },
  orange5: {
    color: "white",
    backgroundColor: "#4B5264",
    marginLeft: "10%",
    marginRight: "2.5rem",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "15%",
  },
  para6: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: "5%",
    width: "100%",
  },
  instruction: {

    marginLeft: "1.4rem",
    marginTop: "1%",
    font: "normal normal bold 18px/30px Comfortaa",
    color: "#4B5264",
    opacity: 1,
  },
  yourSite: {
    font: "normal normal normal 15px/25px Comfortaa",
    color: "#000000",
    marginBottom: "2%",
  },
  FindStaticIPBtn: {
    borderRadius: "23px",
    width: "50%",
    height: "45px",
    background: "#484848 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: 1,
    color: "#ffffff",
    font: "normal normal bold  12px Comfortaa",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
    cancelBtn: {
      width: " 20%",
      fontSize: "15px",
      // @extend.Comfortaa_Bold
      borderRadius: "51px",
      backgroundColor: "#484848",
      color: "#ffffff",
      border: "1px solid black",
      opacity: 1,
      letterSpacing: "0px",
    },
    nextBtn: {
      borderRadius: "23px",
      width: "30%",
      maxWidth: "100%",
      height: "45px",
      marginLeft: "50%",
      marginRight: "2%",
      background: "#484848 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000029",
      opacity: 1,
      color: "#ffffff",
      font: "normal normal bold  12px Comfortaa",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#484848",
        boxShadow: "none",
      },
    },
    backBtn: {
      borderRadius: "23px",
      width: "30%",
      maxWidth: "100%",
      height: "45px",
      border: "1px solid black",
      background: "#ffffff 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000029",
      opacity: 1,
      color: "#484848",
      font: "normal normal bold  12px Comfortaa",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#ffffff",
        boxShadow: "none",
      },
    },
  },
}));
export default function FindStaticIPInstructionPopPup(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const handleClick = () => {

    dispatch(hidesiteDetailsForm());
    dispatch(hideInstruction())
    localStorage.setItem("isNewUser", false)
    if (props.handleCloseWidget !== undefined) {
      props.handleCloseWidget()
    }
  }
  const handleBlack = () => {
    props.backButton()
  }
  return (
    <div>
      {/* <Button onClick={handleClickOpen} className={classes.FindStaticIPBtn}>
        Find Static IP
      </Button> */}
      <div>
        {/* <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              borderRadius: "40px",
              width: "100%",
            },
          }}
        > */}
        <div>
          <div className={classes.instruction} id="alert-dialog-title">
            {/* <Cancel handleClose={handleClose} /> */}
              *How to find Static IP in Windows{" "}
          </div>
        </div>
        <DialogContent>
          <div className={classes.yourSite}>
            Here is a step-wise guide to find your Static IP{" "}
          </div>
          <div>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid>
                <Avatar className={classes.orange}>1</Avatar>
              </Grid>
              <Grid>
                <div className={classes.para1}>Open Start Menu </div>
              </Grid>
            </Grid>
            <br />
            <Grid container wrap="nowrap" spacing={2}>
              <Grid>
                <Avatar size={"2"} className={classes.orange2}>
                  2
                  </Avatar>
              </Grid>
              <Grid>
                <div className={classes.para2}>
                  In Search box type cmd and hit enter.{" "}
                </div>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid>
                <Avatar size={"2"} className={classes.orange3}>
                  3
                  </Avatar>
              </Grid>
              <Grid>
                <div className={classes.para3}>
                  Type ipconfig and hit enter{" "}
                </div>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid>
                <Avatar size={"2"} className={classes.orange4}>
                  4
                  </Avatar>
              </Grid>
              <Grid>
                <div className={classes.para4}>
                  IPv4 address is the static IP.{" "}
                </div>
              </Grid>
            </Grid>
            <div className={classes.para6}></div>
          </div>
        </DialogContent>

        {/* </Dialog> */}
      </div>
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid style={{ marginLeft: "8rem" }}>
            <Button style={{
              backgroundColor: "#484848", borderRadius: "23px",
              width: "10rem",
              maxWidth: "100%",
              height: "45px",
              color: "#ffffff"
            }} onClick={handleClick} >
              DONE
        </Button>
          </Grid>
          <Grid style={{ marginLeft: "1rem" }}>
            <Button style={{
              backgroundColor: "#484848", borderRadius: "23px",
              width: "10rem",
              maxWidth: "100%",
              height: "45px",
              color: "#ffffff"
            }} onClick={handleBlack} className={classes.backBtn}>
              BACK
        </Button>
          </Grid>
        </Grid>
      </Box>
    </div >

  );
}
