import Cookies from "js-cookie";
import { API_ENDPOINT } from '../api/ApiBaseUrl'
export function callAPI({ body, ...customConfig } = {}) {

  const headers = { "content-type": "application/json" };

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    credentials: "include",
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  //  refer https://stackoverflow.com/questions/43344819/reading-response-headers-with-fetch-api#:~:text=There%20is%20a%20restriction%20to,Content%2DLanguage

  return (
    window
      .fetch(`${API_ENDPOINT.BaseUrl}/bb-bbwebsite/bbphlogin`, config)
      // .then((response) => {
      //   const mycookie = response.headers.get("Set-Cookie");
      //   console.log(mycookie);
      //   for (var pair of response.headers.entries()) {
      //     console.log(pair[0] + ": " + pair[1]);
      //   }
      //   return response.json();
      // })
      // .then((data) => console.log(data));
      .then(async (response) => {

        console.log("My header");

        console.log("document :" + document.cookie);

        if (response.ok) {

          localStorage.setItem("selectedPage", "Home")
          // read data and headers and look for cookies and store them
          console.log(response.headers);
          return await response.json();
        } else {

          const errorMessage = await response.json();
          console.log(errorMessage);
          return Promise.reject(errorMessage);
        }
      })
  );
}
