import { createSlice } from '@reduxjs/toolkit';

const PopupSlice = createSlice({
    name: 'popup',
    initialState: {
        isGridRendering: true
    },
    reducers: {
        setGridRenderingState: (state, action) => {
            state.isGridRendering = action.payload;
        }
    }
});

export const { setGridRenderingState } = PopupSlice.actions;
export default PopupSlice.reducer;