import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

// import "./SiteDetailsDrawer.scss";
import { hideViewInstructionDrawer } from "REDUX/Slices/SiteSlice";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";

import EditProfileComponentThree from "../ProfileDetails/EditProfileComponentThree";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        width: "100%",
    },
    list: {
        width: 665,
    },
    fullList: {
        width: "auto",
    },
    drawerCompo: {
        borderRadius: "30px",
    },
}));

function ViewInstructionDrawer(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isviewInstructionDrawerOpen = useSelector(
        (state) => state.site.isviewInstructionDrawerOpen
    );
    const toggleDrawer = () => (event) => {
        dispatch(hideViewInstructionDrawer());
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
        >
            {/* <EditProfileComponentThree /> */}
        </div>
    );

    return (
        <>
            {/* {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <div
                        anchor={anchor}
                        PaperProps={{
                            style: {
                                borderTopLeftRadius: "15px",
                                borderBottomLeftRadius: "15px",
                            },
                        }}
                        open={isviewInstructionDrawerOpen}
                        onClose={toggleDrawer()}
                    >
                        {list(anchor)}
                    </div>
                </React.Fragment>
            ))} */}
        </>
    );
}

export default ViewInstructionDrawer;
