import { ValidationError } from "schema-utils";

const STRINGS = {
  LANDINGSCREEN: {
    HOME: "HOME",
    ABOUT: "ABOUT",
    PROVIDERS: "PROVIDERS",
    SIGN_UP_LOG_IN: "SIGN UP / LOG IN",
    JOIN_US: "Simple. Social. Secure. Join",
    TODAY: "today",
    EXCLAMETORY: "!",
    FOR_PROVIDERS: "FOR PROVIDERS",
  },
  PROVIDERS: {
    TITLE: "Login as a Physician/Provider",
    USERNAME: "Username",
    PASSWORD: "Password",
    FORGOT_PWD: "Forgot your password?",
    SIGN_IN: "SIGN IN",
    SIGN_UP_TEXT1: "Want Bellybaloo in your practice?",
    SIGN_UP_TEXT2: "now!",
    SIGN_UP: " Sign Up ",

    //placeholder
    ENTER_USERNAME: "Enter Username",
    ENTER_PASSWORD: "Enter Password",
  },
  SIGNUP_LOGIN: {
    JOIN_BELLYBALOO: "Join Bellybaloo now!",
    INSTRUCTION:
      "Enter the details you can and we will get back to you after verifying your information",
    SITE_DETAILS: {
      TITLE: "SITE DETAILS",
      SITE_NAME: "Site Name",
      INSTITUTION_NAME: "Institution Name",
      AGENT_AE_TITLE: "Agent AE Title",
      PHONENUMBER: "Phone No.",
      ADDRESS_LINE_1: "Address Line One ",
      ADDRESS: "Address Line Two ",
      ZIP_CODE: "Zip Code",
      STATE: "State",
      COUNTRY: "Country",
      CITY: "City",
    },
    ERRORS: {
      SITE_NAME_API_ERROR: "This site is already in use",
      SITE_NAME_REQUIRED: "Sitename required",
      AGENT_AE_API_ERROR: "This AE Title is already in use",
      AGENT_AE_REQUIRED: "AET title required",
      AGENT_AE_CHARLIMIT: "Character limit should be between 5 TO 12 ",
      PHONE_NUMBER_REQUIRED: "Phone Number required",
      ADDRESS_REQUIRED: "Address required",
      ZIPCODE_REQUIRED: "Please enter a valid zip code",
      CITYERROR: "City Required",
      PHONENOVALIDATIONERROR: "Please enter a valid phone number"
    },

    USER_DETAILS: {
      TITLE: "USER DETAILS",
      CREATE_USER: {
        TITLE: "Create New User",
        FIRST_NAME: "First Name",
        LAST_NAME: "Last Name",
        EMAIL: "Email Address",
        PHONE_NO: "Phone Number",
        USER_NAME: "Username",
        PASSWORD: "Password",
        CNF_PASSWORD: "Confirm Password",
        TYPE_CODE: "Type the code",
        ERROR: {
          USERNAME_ERROR: "Username already exists",
          FIRSTNAME_REQUIRED: "Firstname required",
        },
      },
      EXISTING_USER: {
        TITLE: "Select Existing User",
        USER_NAME: "Username",
        PASSWORD: "Password",
        TYPE_CODE: "Type the code",
      },
    },
    BUTTONS: {
      CONFIRM: "CONFIRM",
      CREATE: "CREATE",
      CANCEL: "CANCEL",
      NEXT: "NEXT",
    },
  },
  SIDEBAR_CONSTANT: {
    COLLAPSE: "COLLAPSE",
    HOME: "Home",
    STUDIES: "Studies",
    SITES: "Sites",
    USERManagement: "User Management",
    CONTACT_US: "Contact Us",
  },
  PROFILE_DETAILS: {
    TITLE: "Enter User Profile",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    EMAIL: "Email Address",
    PHONE_NO: "Phone Number",
    SAVE_BUTTON: "SAVE",
    CANCEL_BUTTON: "CANCEL",
    ERROR_FIRST_NAME: "First Name Required",
    ERROR_LAST_NAME: "Last Name Required",
    ERROR_EMAIL: "Email Required",
    ERROR_PHONE_NO: "Phone number Required",
    CHANGEPASSWORDBTN: "Change Password"
  },
  CREATE_SITE_DETAILS: {
    CREATE_SITE: "Edit Site Configuration (1/2)",
    SITE_DETAILS: "Site Details",
    SITE_NAME: " Site Name",
    INSTITUTION_NAME: "Institution Name",
    AGENT_AE_TITLE: "Agent AE Title",
    ADDRESS: "Address",
    PHONE: "Phone No.",
    ZIP_CODE: "Zip Code",
    STATE: "STATE",
    COUNTRY: "COUNTRY",
    NEXT_BUTTON: "NEXT",
    CANCEL_BUTTON: "CANCEL",
  },
  CREATE_SITE_DETAILS_2: {
    CREATE_SITE: "Edit Site Configuration (2/2)",
    SITE_SERVER_CONFIG: " Site Server Configuration",
    STUDY_HOLD_TIME: " Study Hold Time without Patient info ",
    STUDY_AUTO_RELEASE: "Study Auto-Release Time (in hours)",
    SITE_AGENT_CONFIG: "Site Agent Configuration",
    PATIENT_INFO: "Patient Info Dicom Tag:",
    DICOM_TAG: "Dicom Tag:",
    SITE_PASSWORD: "Site Password:",
    COUNTRY: "COUNTRY",
    CREATE_BUTTON: "Update",
    BACK_BUTTON: "BACK",
  },
  CONTACTUS: {
    CONTACT_US: "Contact Us ",
  },
  SITECONFIGFORM: {
    SITE_CONFIGURATION: "Site Configuration",
    SITE_SERVER_CONFIG: "Site Server Configuration",
    STUDY_HOLD_TIME: " Study Hold Time without Patient info ",
    STUDY_AUTO_RELEASE: "Study Auto-Release Time (in hours)",
    SITE_AGENT_CONFIG: "Site Agent Configuration",
    PATIENT_INFO: "Patient Info Dicom Tag:",
    DICOM_TAG: "Dicom Tag:",
    SITE_PASSWORD: "Site Password:",
    SAVE_BUTTON: "SAVE",
    BACK_BUTTON: "BACK",
  },
  CREATE_USER_FORM: {
    CREATE_A_NEW_USER: "Create a New User",
    USER_DETAILS: "User Details",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    EMAIL_ADDRESS: "Email Address",
    PHONE_NO: "Phone Number",
    USERNAME: "Username",
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "Confirm Password",
    CREATE_BUTTON: "CREATE",
    CANCEL_BUTTON: "CANCEL",
  },
  EDITSTUDYFORM: {
    TITLE: "Study Settings",
    FIRST_NAME: "Patient Name",
    LAST_NAME: "Date of Birth",
    EMAIL: "Patient Email",
    PHONE_NO: "Patient Mobile",
    SAVE_BUTTON: "SAVE",
    CANCEL_BUTTON: "CANCEL",
    ERROR_FIRST_NAME: "First Name Required",
    ERROR_LAST_NAME: "Last Name Required",
    ERROR_EMAIL: "Email Required",
    ERROR_PHONE_NO: "Phone number Required",
  },
  CREATE_AND_EDITSITE_DETAILS: {
    CREATE_SITE: "Create a New Site (1/2)",
    SITE_DETAILS: "Site Details",
    SITE_NAME: " Site Name",
    INSTITUTION_NAME: "Institution Name",
    AGENT_AE_TITLE: "Agent AE Title",
    ADDRESS: "Address",
    PHONENUMBER: "Phone Number",
    ZIP_CODE: "Zip Code",
    STATE: "STATE",
    COUNTRY: "COUNTRY",
    NEXT_BUTTON: "NEXT",
    CANCEL_BUTTON: "CANCEL",
  },
  CREATE_AND_EDITSITE_DETAILS_2: {
    CREATE_SITE: "Create a New Site (2/2)",
    SITE_SERVER_CONFIG: " Site Server Configuration",
    STUDY_HOLD_TIME: " Study Hold Time without Patient info ",
    STUDY_AUTO_RELEASE: "Study Auto-Release Time (in hours)",
    SITE_AGENT_CONFIG: "Site Agent Configuration",
    PATIENT_INFO: "Patient Info Dicom Tag:",
    DICOM_TAG: "Dicom Tag:",
    SITE_PASSWORD: "Site Password:",
    COUNTRY: "COUNTRY",
    CREATE_BUTTON: "Create",
    BACK_BUTTON: "BACK",
  },
  TOGGLE_POPPUP_HOLD_MSG: {
    TOGGLE_POPPUP_HOLD: "Are you sure you want to hold selected study?",
    TOGGLE_POPPUP_RELEASED: "Are you sure you want to release selected study?",
    TOGGLE_POPPUP_RE_RELEASED: " Are you sure you want to release selected study? Some of the selected study have already been released. By re-releasing them, the old link will be invalidated and a new link will be sent.",
    TOGGLE_POPPUP_TITLE: "Hold Study?",
    TOGGLE_POPPUP_TITLE_RELEASED: "Release Study?",
    TOGGLE_POPPUP_TITLE_RE_RELEASED: "Re-release Study?"
  },
  CREAT_USER_ERROR_MSG: {
    FIRST_NAME_ERROR: "First Name Required",
    LAST_NAME_ERROR: "Last Name Required",
    EMAIL_ADDRESS_ERROR: "Email Required",
    PHONE_NUMBER_ERROR: "Please enter a valid phone number",
    USER_NAME_ERROR: "User Name Required",
    PASSWORD_ERROR: "Password Required",
    CONFIRM_PASSWORD_ERROR: "Password & Confirm Password Must Be Match",
    USER_ROLE_ERROR: "User Role Required",
    MULTIPAL_SITE_ERROR: "Site Required"
  }
};
export default STRINGS;
