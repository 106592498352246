import React, { useState, Fragment, useEffect } from "react";
import {
    makeStyles,
    MenuItem,
    FormControl,
    InputLabel,
    Select as MuiSelect,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import TextField from "CUSTOM_COMPONENTS/TextField/TextField";
import STRINGS from "CONSTANTS/Strings";
// import "./EditProfileComponentTwo.scss";
import { StateList } from "CONSTANTS/StateCountryList";
import { hideCreateSiteForm, setGridRenderingState } from "REDUX/Slices/SiteSlice";
import Cancel from "COMMON/Cancel/Cancel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import info from "../../resources/images/info.png"

import Select from "@material-ui/core/Select";
// import {
//     showViewInstructionBtn,
//     hidesaveButtonProfileDetailsForm,
// } from "REDUX/Slices/AppSlice";
import { siteSelector, createSite } from "../../redux/Slices/SiteSlice";
import {

    addEditUserSite,
} from "../../redux/Slices/UserSlice";
import { API_ENDPOINT } from '../../api/ApiBaseUrl'
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
var dicamTagRegex = /^([0-9]{4},[0-9]{4}|)$/;
var strongRegex = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,})$/);
const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        width: "50%",
    },
    list: {
        width: "100%",
    },
    fullList: {
        width: "auto",
    },
    textFiledPatient: {
        width: "100%",
        textAlign: "left",
    },
    textFiledAgentConf: {
        width: "100%",
        marginTop: "2%",
    },
    textFiledAgentConf1: {
        width: "11.4rem",
        marginTop: "2%",
    },
}));
const patientInfoDicamTagData = [
    { id: 1, name: "None" },
    { id: 2, name: "Performing Physician" },
    { id: 3, name: "Referring Physician" },
    { id: 4, name: "Accession Number" },
    { id: 5, name: "Station Name" },
    { id: 6, name: "Study Description" },
    { id: 7, name: "Operators Name" },
    { id: 8, name: "Worklist Label" },
    { id: 9, name: "Alernate Tag" },
];
const DICOM_NAME_VALUE_MAP = {
    None: "",
    "Performing Physician": "0008,1050",
    "Referring Physician": "0008,0090",
    "Accession Number": "0008,0050",
    "Station Name": "0008,1010",
    "Study Description": "0008,1030",
    "Operators Name": "0008,1070",
    "Worklist Label": "0074,1202",
    "Alernate Tag": "",

};
function CreateSiteComponentTwo(props) {
    const [dataEdited, setDataEdited] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { selectedSiteDataForEdit } =
        useSelector(siteSelector);
    const [patientInfo, setpatientInfo] = useState("");
    const [StudyReleaseTime, setStudyReleaseTime] = useState("1");
    const [patientInfoDicamTag, setpatientInfoDicamTag] = useState("");
    const [DicamTag, setDicamTag] = useState("");
    const [sitePassword, setsitePassword] = useState("");

    const [instituteName, setInstituteName] = useState("");
    const [location, setLocation] = useState("");
    const [siteStatus, setSiteStatus] = useState("");
    const [siteId, setSiteId] = useState("");
    const [city, setCity] = useState("");
    const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
    const [passwordError, setPasswordError] = useState(true);
    const [isCustomTag, setIsCustomTag] = useState(false);
    const [dicamTagErrorMsg, setDicamTagErrorMsg] = useState("");
    const [dicamTagError, setDicamTagError] = useState(false);
    const isGridRendering = useSelector((state) => state.site.isGridRendering);
    useEffect(() => {
        if (localStorage.getItem("isCreateSite") === "false" && selectedSiteDataForEdit != undefined) {

            const siteData = selectedSiteDataForEdit;
            setpatientInfo(siteData[0].holdHours)
            setStudyReleaseTime(siteData[0].releaseHours)

            setsitePassword(siteData[0].sitePassword)
            setSiteId(siteData[0].id)
            if (siteData[0].mobileTag !== null) {
                setDicamTag(siteData[0].mobileTag)
            }
            const patientInfoTag = Object.keys(DICOM_NAME_VALUE_MAP).find(key => DICOM_NAME_VALUE_MAP[key] === siteData[0].mobileTag)
            if (patientInfoTag !== undefined) {
                setpatientInfoDicamTag(patientInfoTag);
            } else {
                setpatientInfoDicamTag("Alernate Tag");
            }

        }
    }, []);

    const handleChange = (event, type) => {

        const value = event.target.value;
        let capitalized = value.toUpperCase();
        setDataEdited(true);
        switch (type) {
            case "patientInfo":
                setpatientInfo(value);
                // setSnameError(false);
                break;
            case "StudyReleaseTime":
                setStudyReleaseTime(value);
                // setInstitutionError(false);
                break;
            case "patientInfoDicamTag":
                var dicomTagValue = DICOM_NAME_VALUE_MAP[value];
                setpatientInfoDicamTag(value);
                if (dicomTagValue !== undefined) {
                    setDicamTag(dicomTagValue);
                    setIsCustomTag(false);
                } else {
                    setDicamTag("");
                    setIsCustomTag(true);
                }
                // setAetError(false);
                break;
            case "deCamTag":
                if (patientInfoDicamTag === "Alernate Tag") {
                    if (!dicamTagRegex.test(value)) {
                        setDicamTagErrorMsg("Invalid Dicam Tag Format (Ex:0000,0000)");
                        setDicamTagError(true);
                    } else {
                        setDicamTagError(false);
                        setDicamTagErrorMsg(" ");
                    }
                    setDicamTag(value);
                }
                break;
            case "sitePassword":
                setsitePassword(value);

                if (value !== "") {
                    if (!strongRegex.test(value)) {
                        // dataObject.password = "";
                        // props.checkUserFieldValidation(dataObject)
                        setPasswordError(true);
                        setPasswordErrorMsg("Password length must be 8 characters with alphanumeric")
                    } else {
                        setPasswordErrorMsg("");
                        setPasswordError(false);
                        // dataObject.password = value
                        // props.checkUserFieldValidation(dataObject)

                    }

                } else {
                    setPasswordErrorMsg("Password Required")
                    setPasswordError(true);
                    // dataObject.password = value
                    // props.checkUserFieldValidation(dataObject)
                }
                break;
            default:
                break;
        }
    };

    const handleBack = () => (event) => {
        props.backButtonClick();
    };

    const handleCreate = () => (event) => {

        if (!dicamTagError) {
            const EditedSiteDataFormOne = props.EditedSiteDataFormOne;
            const requestData = {
                id: null,
                state: EditedSiteDataFormOne.state,
                country: EditedSiteDataFormOne.country,
                timeZone: "America/New_York",
                uploadStudyWithoutPatientInfo: false,
                sitePassword: sitePassword,
                lastPingTime: null,
                sundayClose: "2012-10-01T09:45:00.000+02:00",
                releaseHours: StudyReleaseTime,
                pingFailureProcessed: false,
                institution: null,
                sitePhoneNumber: EditedSiteDataFormOne.sitePhoneNumber,
                addressString1: EditedSiteDataFormOne.addressString1,
                dateModified: null,
                mondayClose: "2012-10-01T09:45:00.000+02:00",
                addressString2: EditedSiteDataFormOne.addressString2,
                wednesdayOpen: "2012-10-01T09:45:00.000+02:00",
                tuesdayOpen: "2012-10-01T09:45:00.000+02:00",
                fridayClose: "2012-10-01T09:45:00.000+02:00",
                saturdayOpen: "2012-10-01T09:45:00.000+02:00",
                thursdayOpen: "2012-10-01T09:45:00.000+02:00",
                thursdayClose: "2012-10-01T09:45:00.000+02:00",
                saturdayClose: "2012-10-01T09:45:00.000+02:00",
                tuesdayClose: "2012-10-01T09:45:00.000+02:00",
                wednesdayClose: "2012-10-01T09:45:00.000+02:00",
                aet: EditedSiteDataFormOne.aet,
                holdHours: patientInfo,
                aeDesc: "",
                siteStatus: "Approved",
                mobileTag: DicamTag,
                sundayOpen: "2012-10-01T09:45:00.000+02:00",
                siteName: EditedSiteDataFormOne.siteName,
                fridayOpen: "2012-10-01T09:45:00.000+02:00",
                emailTag: null,
                machines: [],
                zipCode: EditedSiteDataFormOne.zipCode,
                city: EditedSiteDataFormOne.city,
                mondayOpen: "2012-10-01T09:45:00.000+02:00",
                dateAdded: null,
            };

            dispatch(createSite(requestData)).then((response) => {
                const siteData = response.payload.site;
                const siteDataObj = JSON.parse(siteData);
                let siteId = siteDataObj.id;
                if (siteId !== null && siteId !== undefined) {
                    siteId = siteId.toString();
                }
                const assignSiteObject = {
                    userLogin: localStorage.getItem("logInUserName"),
                    siteId: [siteId],
                };
                dispatch(addEditUserSite(assignSiteObject)).then(
                    (response) => {

                        props.callGetSiteApi();

                    })
            });
            dispatch(hideCreateSiteForm());
        }
    }
    const handleEdit = () => {
        if (!dicamTagError) {
            const EditedSiteDataFormOne = props.EditedSiteDataFormOne;
            const siteDataObject = {
                location: location,
                id: siteId,
                state: EditedSiteDataFormOne.state,
                country: EditedSiteDataFormOne.country,
                instituteName: instituteName,
                sitePhoneNumber: EditedSiteDataFormOne.sitePhoneNumber,
                sitePassword: sitePassword,
                mobileTag: DicamTag,
                address2: EditedSiteDataFormOne.addressString2,
                address1: EditedSiteDataFormOne.addressString1,
                aetName: EditedSiteDataFormOne.aet,
                zipcode: EditedSiteDataFormOne.zipCode,
                siteName: EditedSiteDataFormOne.siteName,
                city: EditedSiteDataFormOne.city,
                // siteStatus: siteStatus,
                holdHours: patientInfo,
                releaseHours: StudyReleaseTime,
            };
            fetch(
                `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/siteSupportManager?method=updateSite`,
                {
                    method: "POST",
                    header: {
                        Accept: "application/json",
                    },
                    body: JSON.stringify(siteDataObject),
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((responseData) => {

                    props.callGetSiteApi();

                })
                .catch((err) => {
                    console.log("fetch error" + err);
                });
            dispatch(hideCreateSiteForm());
            dispatch(setGridRenderingState(false))

            props.closeButtonClick()
        }
    }

    const toggleDrawer = () => (event) => {
        dispatch(hideCreateSiteForm());
        props.closeButtonClick()

    };

    return (
        <Fragment>
            <div className="editProfile_container">
                <div className="editProfile_title">
                    <span className="editProfile__title__text">
                        {localStorage.getItem("isCreateSite") === "true" ?
                            STRINGS.CREATE_AND_EDITSITE_DETAILS_2.CREATE_SITE : "Edit Site"}
                        <div onClick={toggleDrawer()}>
                            <Cancel />
                        </div>
                    </span>
                </div>
                <div className="provider__title">
                    <span className="site__server_congiText">
                        {STRINGS.CREATE_AND_EDITSITE_DETAILS_2.SITE_SERVER_CONFIG}
                    </span>
                </div>

                <div className="editProfileDetailFields">
                    <Grid container spacing={3} hidden>
                        <Grid item xs={7}>
                            <div>
                                {" "}
                                <label style={{ marginRight: "30px" }}>
                                    {" "}
                                    {STRINGS.CREATE_AND_EDITSITE_DETAILS_2.STUDY_HOLD_TIME}{" "}
                                </label>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div>
                                {" "}
                                <TextField
                                    id={STRINGS.CREATE_AND_EDITSITE_DETAILS_2.STUDY_HOLD_TIME}
                                    type="number"
                                    className={classes.textFiledPatient}
                                    name={STRINGS.CREATE_AND_EDITSITE_DETAILS_2.STUDY_HOLD_TIME}
                                    value={patientInfo}
                                    handleChange={(event) => handleChange(event, "patientInfo")}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <Tooltip
                                title="This is the time after which a study with no valid patient information will be automatically deleted."
                                placement="top"
                            >
                                <IconButton>
                                    <img alt="info" src={info} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {/* {firstName.length === 0 ? <div className="errorMsg"> {STRINGS.PROFILE_DETAILS.ERROR_FIRST_NAME}</div> : ""} */}
                    <Grid container spacing={3}>
                        <Grid item xs={7}>
                            <div>
                                {" "}
                                <label style={{ marginRight: "30px" }}>
                                    {" "}
                                    {STRINGS.CREATE_AND_EDITSITE_DETAILS_2.STUDY_AUTO_RELEASE}{" "}
                                </label>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div>
                                {" "}
                                <TextField
                                    id={STRINGS.CREATE_AND_EDITSITE_DETAILS_2.STUDY_AUTO_RELEASE}
                                    type="number"
                                    className={classes.textFiledPatient}
                                    name={STRINGS.CREATE_AND_EDITSITE_DETAILS_2.STUDY_AUTO_RELEASE}
                                    value={StudyReleaseTime}
                                    handleChange={(event) =>
                                        handleChange(event, "StudyReleaseTime")
                                    }
                                //errorBorder={isNameHighlighted}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <Tooltip
                                title="This is the time after which a study with valid patient information will be automatically released for the patient to view."
                                placement="top"
                            >
                                <IconButton>
                                    <img alt="info" src={info} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <div style={{ marginBottom: "25px", marginTop: "25px" }}>
                        <span className="site__server__config">
                            {STRINGS.CREATE_AND_EDITSITE_DETAILS_2.SITE_AGENT_CONFIG}
                        </span>
                    </div>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <div>
                                {" "}
                                <label> {STRINGS.CREATE_AND_EDITSITE_DETAILS_2.PATIENT_INFO} </label>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <FormControl>
                                    <MuiSelect
                                        className={classes.textFiledAgentConf1}
                                        // name="state"
                                        // label="state"
                                        options={patientInfoDicamTagData}
                                        value={patientInfoDicamTag}
                                        // error={stateError}
                                        onChange={(event) =>
                                            handleChange(event, "patientInfoDicamTag")
                                        }
                                    >
                                        {patientInfoDicamTagData.map((item, indx) => (
                                            <MenuItem key={indx + "Sitestwo"} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </MuiSelect>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <Tooltip
                                title="This is the designated DICOM field in the ultrasound machine in which the sonographer will enter patient mobile or email information. Bellybaloo will use the contact information in this field to send the study to the patient."
                                placement="top"
                            >
                                <IconButton>
                                    <img alt="info" src={info} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <div>
                                {" "}
                                <label style={{ marginRight: "30px" }}>
                                    {" "}
                                    {STRINGS.CREATE_AND_EDITSITE_DETAILS_2.DICOM_TAG}{" "}
                                </label>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                {" "}
                                <TextField
                                    id={STRINGS.CREATE_AND_EDITSITE_DETAILS_2.DICOM_TAG}
                                    type="text"
                                    className={classes.textFiledAgentConf}
                                    readOnly={isCustomTag}
                                    name={STRINGS.CREATE_AND_EDITSITE_DETAILS_2.DICOM_TAG}
                                    value={DicamTag}
                                    error={dicamTagError}
                                    helperText={dicamTagError ? dicamTagErrorMsg : ""}
                                    handleChange={(event) => handleChange(event, "deCamTag")}
                                //errorBorder={isNameHighlighted}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <Tooltip title="" placement="top">
                                <IconButton>
                                    <img alt="info" src={info} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <div>
                                {" "}
                                <label style={{ marginRight: "30px" }}>
                                    {" "}
                                    {STRINGS.CREATE_AND_EDITSITE_DETAILS_2.SITE_PASSWORD}{" "}
                                </label>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                {" "}
                                <TextField
                                    id={STRINGS.CREATE_AND_EDITSITE_DETAILS_2.SITE_PASSWORD}
                                    type="Password"
                                    className={classes.textFiledAgentConf}
                                    name={STRINGS.CREATE_AND_EDITSITE_DETAILS_2.SITE_PASSWORD}
                                    value={sitePassword}
                                    handleChange={(event) => handleChange(event, "sitePassword")}
                                // error={passwordError}
                                // helperText={passwordError ? passwordErrorMsg : ""}
                                // errorBorder={passwordError}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <Tooltip
                                title="This is an optional field and can have any value with no spaces. Entering a value allows Bellybaloo to enhance the security for your site. You will not need to remember this password or enter it anywhere else."
                                placement="top"
                            >
                                <IconButton>
                                    <img alt="info" src={info} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid> */}

                    {/* {emailAddress.length === 0 ? <div className="errorMsg"> {STRINGS.PROFILE_DETAILS.ERROR_EMAIL}</div> : ""} */}

                    <div className="errorMsg">
                        {" "}
                        {STRINGS.CREATE_AND_EDITSITE_DETAILS_2.ERROR_PHONE_NO}
                    </div>
                </div>
                <div className="Buttons">{
                    localStorage.getItem("isCreateSite") === "true" ?
                        <Button
                            className={"cerateButton__style"} onClick={
                                handleCreate()}>
                            {STRINGS.CREATE_AND_EDITSITE_DETAILS_2.CREATE_BUTTON}
                        </Button> :
                        <Button className={"cerateButton__style"} onClick={
                            handleEdit}>
                            UPDATE
                        </Button>
                }

                    <Button className={"back_Button"} onClick={handleBack()}>
                        {STRINGS.CREATE_SITE_DETAILS_2.BACK_BUTTON}
                    </Button>
                </div>
            </div>
            <div></div>
        </Fragment>
    );
}

export default CreateSiteComponentTwo;
