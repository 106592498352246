import EditProfileComponentThree from "../ProfileDetails/EditProfileComponentThree"
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import "./SiteDetailsDrawer.scss";
import { hidesiteDetailsForm } from "REDUX/Slices/SiteSlice";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import EditProfileComponentOne from "./EditProfileComponentOne";
import EditProfileComponentTwo from "./EditProfileComponentTwo";
import SetupMachineIntructionPop from "../AdvancedSettingComponent/SetupMachineIntructionPop"
import { hideInstruction } from "../../redux/Slices/SiteSlice";
import EditProfileCompoFour from "../ProfileDetails/EditProfileCompoFour"
import EditProfileCompoFive from "../ProfileDetails/EditProfileCompoFive";
const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        width: "100%",
    },
    list: {
        width: 665,
    },
    fullList: {
        width: "auto",
    },
    drawerCompo: {
        borderRadius: "30px",
    },
}));

function InstructionDrawer(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isInstructionOpen = useSelector(
        (state) => state.site.isInstructionOpen
    );
    const instructiontoggleDrawer = () => (event) => {

        dispatch(hideInstruction());

    };
    const nextButtonViewInstruction = () => {
        // dispatch(hidesiteDetailsForm());
        setSelectedComponent(<EditProfileCompoFour nextButtonViewInstructionFour={nextButtonViewInstructionFour} backToThirdPage_VI={backToThirdPage_VI} />);
    };
    const nextButtonViewInstructionFour = () => {
        // dispatch(hidesiteDetailsForm());
        setSelectedComponent(<EditProfileCompoFive nextButtonViewInstructionFive={nextButtonViewInstructionFive} backToFourthPage_VI={backToFourthPage_VI} />);
    };
    const nextButtonViewInstructionFive = () => {
        // dispatch(hidesiteDetailsForm());
        setSelectedComponent(<SetupMachineIntructionPop backToFifthPage_VI={backToFifthPage_VI} handleCloseWidget={props.handleCloseWidget} />);
    };

    const backToFifthPage_VI = () => {
        // dispatch(hidesiteDetailsForm());
        setSelectedComponent(<EditProfileCompoFive nextButtonViewInstructionFive={nextButtonViewInstructionFive} backToFourthPage_VI={backToFourthPage_VI} />);
    };
    const backToFourthPage_VI = () => {
        // dispatch(hidesiteDetailsForm());
        setSelectedComponent(<EditProfileCompoFour nextButtonViewInstructionFour={nextButtonViewInstructionFour} backToThirdPage_VI={backToThirdPage_VI} />);
    };
    const backToThirdPage_VI = () => {
        // dispatch(hidesiteDetailsForm());
        setSelectedComponent(<EditProfileComponentThree nextButtonViewInstruction={nextButtonViewInstruction} />);
    };
    const [selectedComponent, setSelectedComponent] = useState(
        <EditProfileComponentThree nextButtonViewInstruction={nextButtonViewInstruction} />
    );

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
        >
            {selectedComponent}
        </div>
    );

    return (
        <>
            {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Drawer
                        anchor={anchor}
                        PaperProps={{
                            style: {
                                borderTopLeftRadius: "15px",
                                borderBottomLeftRadius: "15px",
                            },
                        }}
                        open={isInstructionOpen}
                        onClose={instructiontoggleDrawer()}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </>
    );
}

export default InstructionDrawer;
