import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

//Components
import SiteDetails from "COMPONENTS/SiteDetails/SiteDetails";
import ToggleButton from "./ToggleButton/ToggleButton";
import UserDetails from "COMPONENTS/UserDetails/UserDetails";
import Cancel from "COMMON/Cancel/Cancel";
import { clearUserStateError } from "./../../redux/Slices/UserSlice";
import { clearSiteStateError } from "../../redux/Slices/SiteSlice";

//Constants
import STRINGS from "CONSTANTS/Strings";
import { toggleUserDetails, toggleSiteDetails } from "REDUX/Slices/AppSlice.js";

//Styles
import "./SignUp.scss";
import { hideProviderForm, showProviderForm, showSignupForm } from "../../redux/Slices/AppSlice";

function SignUp(props) {
  const { isUserDetailsVisible } = useSelector((state) => state.app);
  const { isSiteDetailsAvailable } = useSelector((state) => state.site);
  const dispatch = useDispatch();

  const toggle = () => {
    if (isSiteDetailsAvailable) {
      dispatch(toggleUserDetails());
      dispatch(clearUserStateError());
      dispatch(clearSiteStateError());
    } else {
      return;
    }
    /**Toggle Issue*/
    // if (isUserDetailsVisible && isSiteDetailsAvailable) {
    //   dispatch(toggleSiteDetails());
    // }
  };

  return (
    <Fragment>
      {/* <Cancel onClick={() => {
        
        dispatch(showProviderForm())
       }}/> */}
      <div className="signup__container">
        <div className="join__bellybaloo">
          {STRINGS.SIGNUP_LOGIN.JOIN_BELLYBALOO}
        </div>
        <div className="instruction">{STRINGS.SIGNUP_LOGIN.INSTRUCTION}</div>

        <ToggleButton selected={isUserDetailsVisible} toggleSelected={toggle} />
        {isUserDetailsVisible ? (
          <UserDetails />
        ) : (
          <div className="py-1">
            <SiteDetails />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default SignUp;
