import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";

import STRINGS from "CONSTANTS/Strings";

import { hidesiteDetailsForm } from "REDUX/Slices/SiteSlice";
import Cancel from "COMMON/Cancel/Cancel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Group from "../../resources/images/Group71.png";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
// import "./EditProfileComponentThree.scss";
import {
    showSetupMachineBtn,
    hideViewInstructionBtn,
    setupMachineOpen,
} from "REDUX/Slices/AppSlice";
import { API_ENDPOINT } from "../../api/ApiBaseUrl";
import { hideInstruction } from "../../redux/Slices/SiteSlice";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        // width: "100%",
        flexGrow: 1,
        height: "200px",
    },
    list: {
        width: 665,
    },
    fullList: {
        // width: "auto",
    },

    siteStatus: {
        marginTop: "3%",
        textAlign: "left",
        font: "normal normal bold 20px Comfortaa",
        letterSpacing: "0px",
        color: "#4B5264",
        opacity: 1,
    },
    aetText: {
        marginTop: "50px",
        left: "226px",
        width: "131px",
        height: "17px",
        textAlign: "left",
        font: "normal normal normal 15px/17px Comfortaa",
        letterSpacing: "0px",
        color: "#484848",
        opacity: 1,
    },
    button: {},
    // editProfilButton: {
    //   borderRadius: "40px",
    //   marginLeft: "70%",
    //   color: "#ffffff",
    //   marginTop: "-10%",
    //   width: "150px",
    //   height: "44px",
    //   background: "#484848 0% 0% no-repeat padding-box",
    //   boxShadow: "0px 3px 6px #00000029",
    //   opacity: 1,
    //   font: "normal normal bold  12px Comfortaa",
    //   textTransform: "none",
    //   "&:hover": {
    //     backgroundColor: "#484848",
    //     boxShadow: "none",
    //   },
    // },
    agentSettings: {
        marginTop: "15%",
        margin: "5%",
        width: "200px",
        height: "55px",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #2D2929",
        borderRadius: "51px",
        opacity: 1,
        textAlign: "center",
        font: "normal normal bold 15px Comfortaa",
        letterSpacing: "0px",
        color: "#484848",
        textTransform: "none",
    },
    restartAgentBtn: {
        marginTop: "15%",
        margin: "5%",
        marginLeft: "-1rem",
        width: "200px",
        height: "55px",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #2D2929",
        borderRadius: "51px",
        opacity: 1,
        textAlign: "center",
        font: "normal normal bold 15px Comfortaa",
        letterSpacing: "0px",
        color: "#484848",
        textTransform: "none",
    },
    siteName: {
        top: "541px",
        left: "226px",
        width: "161px",
        height: "33px",
        textAlign: "left",
        letterSpacing: "0px",
        color: "#484848",
        opacity: 1,
        font: "normal normal bold 23px Comfortaa",
    },
    location: {
        top: "626px",
        left: "226px",
        width: "131px",
        height: "17px",
        textAlign: "left",
        font: "normal normal normal 12px Comfortaa",
        letterSpacing: "0px",
        color: "#484848",
        opacity: 1,
    },
    institution: {
        top: "626px",
        marginLeft: "75px",
        width: "80px",
        height: "17px",
        textAlign: "center",
        font: "normal normal normal 12px Comfortaa",
        letterSpacing: "0px",
        color: "#484848",
        opacity: 1,
    },
    verficationPending: {
        width: "150px",
        height: "25px",
        background: "#EBEBEB 0% 0% no-repeat padding-box",
        borderRadius: "17px",
        opacity: 1,
        textAlign: "center",
        font: "normal normal bold 10px Roboto",
        letterSpacing: "0px",
        color: "#484848",
        padding: "5px",
    },
    address: {
        top: "675px",
        left: "226px",
        width: "180px",
        height: "53px",
        textAlign: "left",
        letterSpacing: "0px",
        color: "#484848",
        opacity: 1,
        wordWrap: "break-word",
        font: "normal normal  15px Roboto",
    },

    premierDiagnostics: {
        top: "675px",
        marginLeft: "60px",
        width: "210px",
        height: "23px",
        textAlign: "left",
        letterSpacing: "0px",
        color: "#484848",
        opacity: 1,
        font: "normal normal  15px Roboto",
    },
    SiteStatusCard1: {
        overflow: "hidden",
        marginBottom: "2%",
    },
    SiteStatusCard2: {},
    root1: {
        marginLeft: "20%",
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        borderRadius: "40px",
        marginTop: "2%",
        left: "1215px",
        width: "400px",
        background: "#DCE5F1 0% 0% no-repeat padding-box",
    },
    // enterProfilrButton: {
    //   borderRadius: "23px",
    //   display: "grid",
    //   alignItems: "auto",
    //   width: "30%",
    //   height: "45px",
    //   background: "#484848 0% 0% no-repeat padding-box",
    //   boxShadow: "0px 3px 6px #00000029",
    //   opacity: 1,
    //   color: "#ffffff",
    //   font: "normal normal bold  12px Comfortaa",
    //   textTransform: "none",
    // },
    // imageClass: {
    //   width: 350,
    //   marginLeft: 25,
    //   paddingTop: "2%",
    //   // 16:9
    // },
    // listIcon: {
    //   width: "20px",
    //   height: "20px",
    //   color: "red",
    // },
    // steponeText: {
    //   font: "normal normal bold 20px/23px Comfortaa",
    //   color: "#484848",
    // },
    downloadBellyBalloBtn: {
        marginTop: "10%",
        marginLeft: "14%",
        width: "70%",
        height: "55px",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #2D2929",
        borderRadius: "51px",
        opacity: 1,
        textAlign: "center",
        font: "normal normal bold 15px Comfortaa",
        letterSpacing: "0px",
        color: "#484848",
        textTransform: "none",
    },
    downloadBtnBellyBalloBtn: {
        marginTop: "5%",
        marginLeft: "5%",
        width: "100%",
        height: "55px",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #2D2929",
        borderRadius: "51px",
        opacity: 1,
        textAlign: "center",
        font: "normal normal bold 15px Comfortaa",
        letterSpacing: "0px",
        color: "#484848",
        textTransform: "none",
    },
    agentAetName: {
        marginLeft: "2%",
        width: "131px",
        height: "17px",
        textAlign: "left",
        font: "normal normal normal 12px Comfortaa",
        letterSpacing: "0px",
        color: "#484848",
        opacity: 1,
    },
    status: {
        marginLeft: "20%",
        width: "50px",
        height: "17px",
        textAlign: "center",
        font: "normal normal normal 12px Comfortaa",
        letterSpacing: "0px",
        color: "#484848",
        opacity: 1,
    },
    // button_Tsite: {
    //   width: "100px",
    //   height: "40px",
    //   boxShadow: "0px 3px 6px #00000029",
    //   borderRadius: "51px",
    //   opacity: 1,
    //   textAlign: "center",
    //   letterSpacing: "0px",
    //   color: "#FFFFFF",
    //   font: "normal normal normal 15px/17px Comfortaa",
    //   background: "#EF4036 0% 0% no-repeat padding-box",
    //   "&:hover": {
    //     backgroundColor: "#EF4036",
    //     boxShadow: "none",
    //   },
    // },
    // active: {
    //   marginLeft: "-30px",
    //   marginTop: "2%",
    //   width: "94px",
    //   height: "33px",
    //   textAlign: "center",
    //   letterSpacing: "0px",
    //   color: "#484848",
    //   opacity: 1,
    //   font: "normal normal Bold 23px Comfortaa",
    // },
    orange: {
        color: "white",
        backgroundColor: "#4B5264",
        marginLeft: "30px",
        marginRight: "30px",
        width: "30px",
        height: "30px",
        font: "normal normal normal 15px Comfortaa",
        marginTop: "8%",
    },
    para1: {
        font: "normal normal normal 13px/25px Comfortaa",
        color: "#000000",
        marginRight: "5%",
    },
    para2: {
        font: "normal normal normal 13px/25px Comfortaa",
        color: "#000000",
        marginRight: "5%",
    },
    para3: {
        font: " normal 13.5px/25px Comfortaa",
        color: "#000000",
        marginRight: "2%",
    },
    divider: {
        // Old Code
        // marginTop: "5%",
        // marginBottom: "5%",
        // New Code
        marginTop: "4%",
        marginBottom: "2%",
        backgroundColor: "#4B5264",
    },
    instruction: {
        width: "",
        marginTop: "4%",
        font: "normal normal bold 18px/30px Comfortaa",
        color: "#4B5264",
        opacity: 1,
    },
    yourSite: {
        font: "normal normal normal 13px/25px Comfortaa",
        color: "#000000",
        marginBottom: "2%",
    },
    viewInstructionButton: {
        borderRadius: "23px",
        width: "30%",
        maxWidth: "100%",
        height: "45px",
        marginLeft: "35%",
        background: "#484848 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        color: "#ffffff",
        font: "normal normal bold  12px Comfortaa",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#484848",
            boxShadow: "none",
        },
    },
    nextBtn: {
        borderRadius: "23px",
        width: "30%",
        maxWidth: "100%",
        height: "45px",
        marginLeft: "20%",
        marginRight: "2%",
        background: "#484848 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        color: "#ffffff",
        font: "normal normal bold  12px Comfortaa",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#484848",
            boxShadow: "none",
        },
    },
    okBtn: {
        borderRadius: "23px",
        width: "30%",
        maxWidth: "100%",
        height: "45px",
        marginLeft: "35%",
        marginBottom: "2%",
        background: "#484848 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        color: "#ffffff",
        font: "normal normal bold  12px Comfortaa",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#484848",
            boxShadow: "none",
        },
    },
    DownloadNextBtn: {
        marginTop: "5%",
        width: "95%",
        height: "55px",
        borderRadius: "51px",
        textAlign: "center",
        font: "normal normal bold 15px Comfortaa",
        letterSpacing: "0px",
        textTransform: "none",
        background: "#484848 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#484848",
            boxShadow: "none",
        },
    },
    backBtn: {
        borderRadius: "23px",
        width: "30%",
        maxWidth: "100%",
        height: "45px",
        border: "1px solid black",
        background: "#ffffff 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        color: "#484848",
        font: "normal normal bold  12px Comfortaa",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#ffffff",
            boxShadow: "none",
        },
    },
}));

function EditProfileCompoFour(props) {
    const SiteId = localStorage.getItem("SiteId");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogAgentRestart, setOpenDialogAgentRestart] =
        React.useState(false);
    const agentSettings = () => {
        handleCloseDialog();
        window.location = `${API_ENDPOINT.BaseUrl}/bb-siteadmin/site/getconfig?site=${SiteId}`;
    };

    const downloadBellyBalloAgent = () => {
        window.location =
            "https://s3.amazonaws.com/BB-INSTALLER/agent/latest/bellybaloo_agent_windows_x86.exe";
    };
    const DownloadrestartAgent = () => {
        handleCloseAgentRestartDialog();
        window.location =
            "https://s3.amazonaws.com/BB-INSTALLER/restartagent/BellybalooRestartAgentSetup.exe";
    };
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = () => (event) => {
        dispatch(hidesiteDetailsForm());
    };
    const handleClose = () => {
        dispatch(hideInstruction());
        dispatch(hideViewInstructionBtn());

        dispatch(showSetupMachineBtn());

        setOpen(false);
    };
    const nextBtnClick = () => {
        props.nextButtonViewInstructionFour();
        dispatch(setupMachineOpen());
        localStorage.setItem("ThirdStep", true);
    };

    const backBtnClick = () => {
        if (props.backToThirdPage_VI !== undefined) {
            props.backToThirdPage_VI()
        } else {
            props.backToThirdPage();
        }
    };
    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClickOpenAgentRestart = () => {
        setOpenDialogAgentRestart(true);
    };

    const handleCloseAgentRestartDialog = () => {
        setOpenDialogAgentRestart(false);
    };
    return (
        <Fragment>
            <div className="edit_prfile_Three__container">
                {/* New Code */}
                <Card
                    className={classes.SiteStatusCard1}

                // style={{ borderRadius: "40px" }}
                >
                    {/* <div onClick={toggleDrawer()} >
              <Cancel />
            </div> */}
                    <CardContent>
                        <div className={classes.instruction} id="alert-dialog-title">
                            <Cancel handleClose={handleClose} />
              Instructions to complete Site Configuration
            </div>

                        <div className={classes.yourSite}>
                            Your site configuration is complete. To complete your setup:
            </div>
                        <div>
                            <Grid container wrap="nowrap" spacing={2}>
                                <Grid>
                                    <Avatar size={"2"} className={classes.orange}>
                                        #2
                  </Avatar>
                                </Grid>
                                <Grid>
                                    <div className={classes.para2}>
                                        Click on "Download Bellybaloo Agent" and walk through the
                                        necessary steps to complete the setup.
                  </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={downloadBellyBalloAgent}
                                    className={classes.downloadBellyBalloBtn}
                                >
                                    Download Bellybaloo Agent
            </Button>
                            </Grid>
                        </div>
                        <Divider className={classes.divider} variant="middle" />

                        <div className={classes.para3}>
                            <b>IMPORTANT:</b> Make sure the computer you are using to download
              the Bellybaloo agent is on the same network as your ultrasound
              machine. Once installed, the ultrasound machines will send the
              images to the Bellybaloo Agent so that the patients can view their
              images.
            </div>
                        <div className={classes.para3}>
                            To setup your Ultrasound machine to communicate with Bellybaloo,
                            use the instructions on the next screen.
            </div>
                    </CardContent>
                </Card>

                <Grid container spacing={3}>


                    <Grid item xs={12}>
                        <div>
                            <Button onClick={backBtnClick} className={classes.nextBtn}>
                                BACK
            </Button>
                            <Button onClick={nextBtnClick} className={classes.backBtn}>
                                NEXT
            </Button>
                        </div>
                    </Grid>

                </Grid>
            </div>

        </Fragment>
    );
}

export default EditProfileCompoFour;
