import React, { useState, Fragment, useEffect } from "react";
import {
  makeStyles,
  MenuItem,
  FormControl,
  Select as MuiSelect,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import TextField from "CUSTOM_COMPONENTS/TextField/TextField";
import STRINGS from "CONSTANTS/Strings";
import "./EditProfileComponentTwo.scss";

import { hidesiteDetailsForm } from "REDUX/Slices/SiteSlice";
import Cancel from "COMMON/Cancel/Cancel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import info from "../../resources/images/info.png";

import {
  showViewInstructionBtn,
  hidesaveButtonProfileDetailsForm,
} from "REDUX/Slices/AppSlice";
import { API_ENDPOINT } from "../../api/ApiBaseUrl";
import { showViewInstructionDrawer } from "REDUX/Slices/SiteSlice";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { unwatchFile } from "fs";
import { setGridRenderingState } from "../../redux/Slices/PopupSlice";

var dicamTagRegex = /^([0-9]{4},[0-9]{4}|)$/;
var EditedSiteDataFormOne = {};
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "50%",
  },
  list: {
    width: "100%",
  },
  fullList: {
    width: "auto",
  },
  textFiledPatient: {
    width: "100%",
    textAlign: "left",
  },
  textFiledAgentConf: {
    width: "100%",
    marginTop: "2%",
  },
  textFiledAgentConf1: {
    width: "11.4rem",
    marginTop: "2%",
  },
}));
const patientInfoDicamTagData = [
  { id: 1, name: "None" },
  { id: 2, name: "Performing Physician" },
  { id: 3, name: "Referring Physician" },
  { id: 4, name: "Accession Number" },
  { id: 5, name: "Station Name" },
  { id: 6, name: "Study Description" },
  { id: 7, name: "Operators Name" },
  { id: 8, name: "Worklist Label" },
  { id: 9, name: "Alernate Tag" },
];
const DICOM_NAME_VALUE_MAP = {
  None: "",
  "Performing Physician": "0008,1050",
  "Referring Physician": "0008,0090",
  "Accession Number": "0008,0050",
  "Station Name": "0008,1010",
  "Study Description": "0008,1030",
  "Operators Name": "0008,1070",
  "Worklist Label": "0074,1202",
  "Alernate Tag": "",
};

function EditProfileComponentTwo(props) {
  const [dataEdited, setDataEdited] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [patientInfo, setpatientInfo] = useState("");
  const [StudyReleaseTime, setStudyReleaseTime] = useState("");
  const [patientInfoDicamTag, setpatientInfoDicamTag] = useState("");
  const [DicamTag, setDicamTag] = useState("");
  const [sitePassword, setsitePassword] = useState("");
  const [dicamTagErrorMsg, setDicamTagErrorMsg] = useState("");
  const [dicamTagError, setDicamTagError] = useState(false);
  const [instituteName, setInstituteName] = useState("");
  const [location, setLocation] = useState("");
  const [siteStatus, setSiteStatus] = useState("");
  const [siteId, setSiteId] = useState("");
  const [city, setCity] = useState("");
  const [isCustomTag, setIsCustomTag] = useState(false);
  useEffect(() => {
    const config = {
      method: "GET",
      headers: { "content-type": "application/json" },
      credentials: "include",
    };
    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/siteSupportManager?method=getSiteListByUser`,
      config
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
        if (responseData !== undefined && responseData.sites !== undefined)
          if (!dataEdited) {
            const siteData = responseData;
            const siteDataObj = JSON.parse(siteData.sites);
            const homeSiteName = localStorage.getItem("homeSiteName");
            var homeSelectedSiteName = siteDataObj.filter(function (entry) {
              return entry.siteName === homeSiteName;
            });
            setStudyReleaseTime(homeSelectedSiteName[0].releaseHours);
            setpatientInfo(homeSelectedSiteName[0].holdHours);
            setsitePassword(homeSelectedSiteName[0].sitePassword);
            setInstituteName(homeSelectedSiteName[0].instituteName);
            setLocation(homeSelectedSiteName[0].location);
            setSiteStatus(homeSelectedSiteName[0].siteStatus);
            setSiteId(homeSelectedSiteName[0].id);
            setCity(homeSelectedSiteName[0].city);

            if (homeSelectedSiteName[0].mobileTag !== null) {
              setDicamTag(homeSelectedSiteName[0].mobileTag);
            }
            const patientInfoTag = Object.keys(DICOM_NAME_VALUE_MAP).find(
              (key) =>
                DICOM_NAME_VALUE_MAP[key] === homeSelectedSiteName[0].mobileTag
            );
            if (patientInfoTag !== undefined) {
              setpatientInfoDicamTag(patientInfoTag);
            } else {
              setpatientInfoDicamTag("Alernate Tag");
            }
          }
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  }, []);

  const handleChange = (event, type) => {
    const value = event.target.value;
    let capitalized = value.toUpperCase();
    setDataEdited(true);
    switch (type) {
      case "patientInfo":
        setpatientInfo(value);
        // setSnameError(false);
        break;
      case "StudyReleaseTime":
        setStudyReleaseTime(value);
        // setInstitutionError(false);
        break;
      case "patientInfoDicamTag":
        var DICOM_NAME_VALUE_MAP = {
          None: "",
          "Performing Physician": "0008,1050",
          "Referring Physician": "0008,0090",
          "Accession Number": "0008,0050",
          "Station Name": "0008,1010",
          "Study Description": "0008,1030",
          "Operators Name": "0008,1070",
          "Worklist Label": "0074,1202",
        };
        var dicomTagValue = DICOM_NAME_VALUE_MAP[value];
        setpatientInfoDicamTag(value);
        if (dicomTagValue !== undefined) {
          setDicamTag(dicomTagValue);
          setIsCustomTag(false);
        } else {
          setDicamTag("");
          setIsCustomTag(true);
        }

        // setAetError(false);
        break;
      case "deCamTag":
        if (patientInfoDicamTag === "Alernate Tag") {
          if (!dicamTagRegex.test(value)) {
            setDicamTagErrorMsg("Invalid Dicam Tag Format (Ex:0000,0000)");
            setDicamTagError(true);
          } else {
            setDicamTagError(false);
            setDicamTagErrorMsg(" ");
          }
          setDicamTag(value);
        }
        break;
      case "sitePassword":
        setsitePassword(value);
        // setZipcodeError(false);
        break;
      default:
        break;
    }
  };

  const handleBack = () => (event) => {
    props.backButtonClick();
  };

  const handleUpdate = () => (event) => {
    if (!dicamTagError) {
      localStorage.setItem("SecondStep", true);
      dispatch(showViewInstructionDrawer());
      if (props.EditedSiteDataFormOne !== undefined) {
        EditedSiteDataFormOne = props.EditedSiteDataFormOne;
      }

      const siteDataObject = {
        // site data properties
      };

      fetch(`${API_ENDPOINT.BaseUrl}/bb-bbwebsite/siteSupportManager?method=updateSite`, {
        method: "POST",
        header: {
          Accept: "application/json",
        },
        body: JSON.stringify(siteDataObject),
      })
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          console.log(responseData);
        })
        .catch((err) => {
          console.log("fetch error" + err);
        });

      localStorage.setItem("FirstStep", true);

      dispatch(setGridRenderingState(false)); // Dispatch the action to update the state to false

      dispatch(hidesaveButtonProfileDetailsForm());
      dispatch(showViewInstructionBtn());
      props.createButtonClicked();
    }
  };


  const toggleDrawer = () => (event) => {
    dispatch(hidesiteDetailsForm());
  };

  return (
    <Fragment>
      <div className="editProfile_container">
        <div className="editProfile_title">
          <span className="editProfile__title__text">
            {STRINGS.CREATE_SITE_DETAILS_2.CREATE_SITE}
            <div onClick={toggleDrawer()}>
              <Cancel />
            </div>
          </span>
        </div>
        <div className="provider__title">
          <span className="site__server_congiText">
            {STRINGS.CREATE_SITE_DETAILS_2.SITE_SERVER_CONFIG}
          </span>
        </div>

        <div className="editProfileDetailFields">
          <Grid container spacing={3} hidden>
            <Grid item xs={7}>
              <div>
                {" "}
                <label style={{ marginRight: "30px" }}>
                  {" "}
                  {STRINGS.CREATE_SITE_DETAILS_2.STUDY_HOLD_TIME}{" "}
                </label>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                {" "}
                <TextField
                  id={STRINGS.CREATE_SITE_DETAILS_2.STUDY_HOLD_TIME}
                  type="number"
                  className={classes.textFiledPatient}
                  name={STRINGS.CREATE_SITE_DETAILS_2.STUDY_HOLD_TIME}
                  value={patientInfo}
                  handleChange={(event) => handleChange(event, "patientInfo")}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <Tooltip
                title="This is the time after which a study with no valid patient information will be automatically deleted."
                placement="top"
              >
                <IconButton>
                  <img alt="info" src={info} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          {/* {firstName.length === 0 ? <div className="errorMsg"> {STRINGS.PROFILE_DETAILS.ERROR_FIRST_NAME}</div> : ""} */}
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <div>
                {" "}
                <label style={{ marginRight: "30px" }}>
                  {" "}
                  {STRINGS.CREATE_SITE_DETAILS_2.STUDY_AUTO_RELEASE}{" "}
                </label>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                {" "}
                <TextField
                  id={STRINGS.CREATE_SITE_DETAILS_2.STUDY_AUTO_RELEASE}
                  type="number"
                  className={classes.textFiledPatient}
                  name={STRINGS.CREATE_SITE_DETAILS_2.STUDY_AUTO_RELEASE}
                  value={StudyReleaseTime}
                  handleChange={(event) =>
                    handleChange(event, "StudyReleaseTime")
                  }
                //errorBorder={isNameHighlighted}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <Tooltip
                title="This is the time after which a study with valid patient information will be automatically released for the patient to view."
                placement="top"
              >
                <IconButton>
                  <img alt="info" src={info} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <div style={{ marginBottom: "25px", marginTop: "25px" }}>
            <span className="site__server__config">
              {STRINGS.CREATE_SITE_DETAILS_2.SITE_AGENT_CONFIG}
            </span>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div>
                {" "}
                <label> {STRINGS.CREATE_SITE_DETAILS_2.PATIENT_INFO} </label>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <FormControl>
                  <MuiSelect
                    className={classes.textFiledAgentConf1}
                    // name="state"
                    // label="state"
                    options={patientInfoDicamTagData}
                    value={patientInfoDicamTag}
                    // error={stateError}
                    onChange={(event) =>
                      handleChange(event, "patientInfoDicamTag")
                    }
                  >
                    {patientInfoDicamTagData.map((item) => (
                      <MenuItem key={item.abbr + "comptwo"} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={2}>
              <Tooltip
                title="This is the designated DICOM field in the ultrasound machine in which the sonographer will enter patient mobile or email information. Bellybaloo will use the contact information in this field to send the study to the patient."
                placement="top"
              >
                <IconButton>
                  <img alt="info" src={info} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div>
                {" "}
                <label style={{ marginRight: "30px" }}>
                  {" "}
                  {STRINGS.CREATE_SITE_DETAILS_2.DICOM_TAG}{" "}
                </label>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                {" "}
                <TextField
                  id={STRINGS.CREATE_SITE_DETAILS_2.DICOM_TAG}
                  type="text"
                  placeolder="f"
                  className={classes.textFiledAgentConf}
                  name={STRINGS.CREATE_SITE_DETAILS_2.DICOM_TAG}
                  readOnly={isCustomTag}
                  value={DicamTag}
                  error={dicamTagError}
                  helperText={dicamTagError ? dicamTagErrorMsg : ""}
                  handleChange={(event) => handleChange(event, "deCamTag")}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="" placement="top">
                <IconButton>
                  <img alt="info" src={info} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          {/* <Grid container spacing={3}>
            <Grid item xs={6}>
              <div>
                {" "}
                <label style={{ marginRight: "30px" }}>
                  {" "}
                  {STRINGS.CREATE_SITE_DETAILS_2.SITE_PASSWORD}{" "}
                </label>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                {" "}
                <TextField
                  id={STRINGS.CREATE_SITE_DETAILS_2.SITE_PASSWORD}
                  type="Password"
                  className={classes.textFiledAgentConf}
                  name={STRINGS.CREATE_SITE_DETAILS_2.SITE_PASSWORD}
                  value={sitePassword}
                  handleChange={(event) => handleChange(event, "sitePassword")}
                  //errorBorder={isNameHighlighted}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <Tooltip
                title="This is an optional field and can have any value with no spaces. Entering a value allows Bellybaloo to enhance the security for your site. You will not need to remember this password or enter it anywhere else."
                placement="top"
              >
                <IconButton>
                  <img alt="info" src={info} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid> */}

          {/* {emailAddress.length === 0 ? <div className="errorMsg"> {STRINGS.PROFILE_DETAILS.ERROR_EMAIL}</div> : ""} */}

          <div className="errorMsg">
            {" "}
            {STRINGS.CREATE_SITE_DETAILS_2.ERROR_PHONE_NO}
          </div>
        </div>
        <div className="Buttons">
          <Button className={"cerateButton__style"} onClick={handleUpdate()}>
            {STRINGS.CREATE_SITE_DETAILS_2.CREATE_BUTTON}
          </Button>
          <Button className={"back_Button"} onClick={handleBack()}>
            {STRINGS.CREATE_SITE_DETAILS_2.BACK_BUTTON}
          </Button>
        </div>
      </div>
      <div></div>
    </Fragment>
  );
}

export default EditProfileComponentTwo;
