import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Bellybaloo_Logo from "../../../resources/images/bellybaloo.png";
import BellybalooLogo2 from "../../../resources/images/BellybalooWhite21.png";
import CollapseIcon from "../../../resources/images/CollapseIcon.png";
import HomeRoundedIcon from "../../../resources/images/homeIcon.png";
import StudiesIcon from "../../../resources/images/studyIcon.png";
import SiteIcon from "../../../resources/images/siteIcon.png";
import UserIcon from "../../../resources/images/userManagementIcon.png";
import STRINGS from "../../../constants/Strings";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router";
import { ROUTES } from "CONSTANTS/RouteConstants";
import { API_ENDPOINT } from "../../../api/ApiBaseUrl";
import Cancel from "COMMON/Cancel/Cancel";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@material-ui/core/Button";
import { FaUserFriends } from "react-icons/fa/";

const drawerWidth = "275px";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    color: "#C9D9EF",

    top: "15px",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    overflowX: "hidden",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    color: "white",
  },

  paper: {
    background: "#484848",
  },
  zIndexSidebar: {
    zIndex: 0,
  },
  sideBarName: {
    color: "#C9D9EF",
    font: "normal normal bold 5px/28px Comfortaa",
    padding: theme.spacing(2, 1),
  },
  navCornarStyle: {
    borderRadius: "0px 0px 80px 0px",
    background: "#484848 0% 0%",
    border: "1px solid #707070",
  },

  collapseButton: {
    fontSize: "12px",
    marginRight: "25px",
  },
  bellybalooLogo: {
    width: "40px",

    marginTop: "25px",
    marginLeft: "15px",
  },
  bellybalooLogoOpen: {
    width: "17rem",
    background: "transparent url('img/Bellybaloo White 2.png') ",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: "#E4E4E4",
  },
  dividerOpen: {
    backgroundColor: "#C9D9EF",
    width: "40%",
    height: "2.5px",
    marginLeft: "10%",
  },
  dividerClose: {
    backgroundColor: "#C9D9EF",
    height: "2.5px",
    width: "100%",
  },
}));

export default function NavBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [selectedCompName, setselectedCompName] = React.useState(
    localStorage.getItem("selectedPage")
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const selectMenuItem = (menuItem) => (event) => {
    let urlPath = history.location.pathname;
    localStorage.setItem("selectedPage", menuItem);
    if (menuItem === STRINGS.SIDEBAR_CONSTANT.HOME) {
      setselectedCompName(menuItem);

      history.push({
        pathname: "/dashboard/home",
      });
    }
    if (menuItem === STRINGS.SIDEBAR_CONSTANT.STUDIES) {
      setselectedCompName(menuItem);
      history.push({
        pathname: "/dashboard/studies",
      });
    }
    if (menuItem === STRINGS.SIDEBAR_CONSTANT.SITES) {
      setselectedCompName(menuItem);
      history.push({
        pathname: "/dashboard/sites",
      });
    }
    if (menuItem === STRINGS.SIDEBAR_CONSTANT.USERManagement) {
      setselectedCompName(menuItem);
      history.push({
        pathname: "/dashboard/userManagement",
      });
    }
    if (menuItem === STRINGS.SIDEBAR_CONSTANT.CONTACT_US) {
      setselectedCompName(menuItem);
      history.push({
        pathname: "/dashboard/contactUs",
      });
    }
  };

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.paper]: true,
            [classes.navCornarStyle]: open,
            [classes.zIndexSidebar]: !open,
          }),
        }}
      >
        {open ? (
          <div style={{ color: "white", fontSize: "20px" }}>
            <img
              src={BellybalooLogo2}
              alt="bellybaloo logo"
              className={clsx(classes.bellybalooLogoOpen, {
                [classes.bellybalooLogoOpen]: open,
              })}
            />{" "}
          </div>
        ) : (
          <img
            src={Bellybaloo_Logo}
            alt="bellybaloo logo"
            className={clsx(classes.bellybalooLogo, {
              [classes.bellybalooLogo]: open,
            })}
          />
        )}
        <div className={classes.toolbar}>
          {open ? (
            <div className={classes.collapseButton} onClick={handleDrawerClose}>
              <IconButton>
                {theme.direction === "rtl" ? (
                  ""
                ) : (
                  <img src={CollapseIcon} alt="Collaps Icon"></img>
                )}
              </IconButton>{" "}
              {STRINGS.SIDEBAR_CONSTANT.COLLAPSE}
            </div>
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
          )}
        </div>

        <List style={{ color: "white" }}>
          {/* {['Home', 'Studies', 'Site', 'User Management', 'Advance Settings'].map((text, index) => (
                        <ListItem button key={text}>
                            <ListItemIcon className={classes.iconColor}>{<RoomRoundedIcon />}</ListItemIcon>
                            <ListItemText primary={text} />

                        </ListItem>
                    ))} */}
          <ListItem
            button
            key={STRINGS.SIDEBAR_CONSTANT.HOME}
            onClick={selectMenuItem(STRINGS.SIDEBAR_CONSTANT.HOME)}
          >
            <ListItemIcon className={classes.sideBarName}>
              <img src={HomeRoundedIcon} alt="Home Icon"></img>
            </ListItemIcon>
            <ListItemText primary={STRINGS.SIDEBAR_CONSTANT.HOME} />
          </ListItem>
          {selectedCompName === STRINGS.SIDEBAR_CONSTANT.HOME ? (
            <Divider
              className={clsx(classes.menuItem, {
                [classes.dividerOpen]: open,
                [classes.dividerClose]: !open,
              })}
            />
          ) : (
            ""
          )}
          <ListItem
            button
            key={STRINGS.SIDEBAR_CONSTANT.STUDIES}
            onClick={selectMenuItem(STRINGS.SIDEBAR_CONSTANT.STUDIES)}
          >
            <ListItemIcon className={classes.sideBarName}>
              <img src={StudiesIcon} alt="Studies Icon"></img>
            </ListItemIcon>
            <ListItemText primary={STRINGS.SIDEBAR_CONSTANT.STUDIES} />
          </ListItem>
          {selectedCompName === STRINGS.SIDEBAR_CONSTANT.STUDIES ? (
            <Divider
              className={clsx(classes.menuItem, {
                [classes.dividerOpen]: open,
                [classes.dividerClose]: !open,
              })}
            />
          ) : (
            ""
          )}
          <ListItem
            button
            key={STRINGS.SIDEBAR_CONSTANT.SITES}
            onClick={selectMenuItem(STRINGS.SIDEBAR_CONSTANT.SITES)}
          >
            <ListItemIcon className={classes.sideBarName}>
              <img src={SiteIcon} alt="Site Icon"></img>
            </ListItemIcon>
            <ListItemText primary={STRINGS.SIDEBAR_CONSTANT.SITES} />
          </ListItem>
          {selectedCompName === STRINGS.SIDEBAR_CONSTANT.SITES ? (
            <Divider
              className={clsx(classes.menuItem, {
                [classes.dividerOpen]: open,
                [classes.dividerClose]: !open,
              })}
            />
          ) : (
            ""
          )}
          <ListItem
            button
            key={STRINGS.SIDEBAR_CONSTANT.USERManagement}
            onClick={selectMenuItem(STRINGS.SIDEBAR_CONSTANT.USERManagement)}
          >
            <ListItemIcon className={classes.sideBarName}>
              <img src={UserIcon} alt="User Icon"></img>
            </ListItemIcon>
            <ListItemText primary={STRINGS.SIDEBAR_CONSTANT.USERManagement} />
          </ListItem>
          {selectedCompName === STRINGS.SIDEBAR_CONSTANT.USERManagement ? (
            <Divider
              className={clsx(classes.menuItem, {
                [classes.dividerOpen]: open,
                [classes.dividerClose]: !open,
              })}
            />
          ) : (
            ""
          )}
          <ListItem
            button
            key={STRINGS.SIDEBAR_CONSTANT.CONTACT_US}
            onClick={selectMenuItem(STRINGS.SIDEBAR_CONSTANT.CONTACT_US)}
          >
            <ListItemIcon className={classes.sideBarName}>
              <FaUserFriends style={{ width: "1.4rem", height: "1.4rem" }} />
            </ListItemIcon>
            <ListItemText primary={STRINGS.SIDEBAR_CONSTANT.CONTACT_US} />
          </ListItem>
          {selectedCompName === STRINGS.SIDEBAR_CONSTANT.CONTACT_US ? (
            <Divider
              className={clsx(classes.menuItem, {
                [classes.dividerOpen]: open,
                [classes.dividerClose]: !open,
              })}
            />
          ) : (
            ""
          )}
        </List>
      </Drawer>
    </div>
  );
}
