import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "./AgentStatusComponent.css";
import Grid from "@material-ui/core/Grid";
import "../../resources/styles/Fonts.scss";
import { useDispatch } from "react-redux";
import { showProfileDetailsForm } from "REDUX/Slices/AppSlice";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    width: "auto",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  agentStatus: {
    marginTop: "5%",
    textAlign: "left",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
    left: "225px",
    width: "169px",
    height: "28px",
    font: "normal normal bold 20px Comfortaa",
  },
  aetText: {
    marginTop: "50px",
    left: "226px",
    width: "131px",
    height: "17px",
    textAlign: "left",
    font: "normal normal normal 15px/17px Comfortaa",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
  },
  button_Tsite: {
    marginLeft: "-60%",
    width: "40%",
    // height: "3rem",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "51px",
    opacity: 1,
    justifyContent: "center",
    letterSpacing: "0px",
    color: "#FFFFFF",
    font: "normal normal normal 15px/17px Comfortaa",
    background: "#484848 0% 0% no-repeat padding-box",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  viewProfilButton: {
    borderRadius: "40px",
    marginTop: "4%",
    marginLeft: "20%",
    width: "70%",
    height: "6vh",
    background: "#484848 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: 1,
    color: "#ffffff",
    font: "normal normal bold  12px Comfortaa",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  LastPing: {
    top: "233px",
    left: "794px",
    width: "93px",
    height: "17px",
    textAlign: "left",
    font: "normal normal normal 15px/17px Comfortaa",
    letterSpacing: "0px",
    color: "#ff0000",
    opacity: 1,
  },
  agentAetName: {
    marginTop: "4%",
    marginLeft: "2%",
    width: "131px",
    height: "17px",
    textAlign: "left",
    font: "normal normal normal 12px Comfortaa",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
  },
  status: {
    marginTop: "2.5%",
    marginLeft: "20%",
    width: "50px",
    height: "17px",
    textAlign: "center",
    font: "normal normal normal 12px Comfortaa",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
  },
  lastPing: {
    marginTop: "4%",
    marginLeft: "-80%",
    width: "90px",
    height: "17px",
    textAlign: "center",
    font: "normal normal normal 12px Comfortaa",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
  },
  time: {
    marginLeft: "10%",
    marginTop: "5%",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
    font: "normal normal normal 17px Comfortaa",
  },
  active: {
    marginLeft: "10%",
    marginTop: "2%",
    width: "94px",
    height: "33px",
    textAlign: "right",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
    font: "normal normal Bold 17px Comfortaa",
  },
  loggedIn: {
    color: "#2DFFA4",
    marginRight: "5%",
  },
}));

export default function AgentStatusComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [agentAETName, setAgentAETName] = useState("");
  const [agentStatus, setAgentStatus] = useState("Active");
  const [agentLastPing, setAgentLastPing] = useState("");
  const openProfileDetailsForm = () => {
    dispatch(showProfileDetailsForm());
  };
  useEffect(() => {
    const responseData = props.responseData;
    if (responseData !== undefined && responseData.sites !== undefined) {
      const siteData = props.responseData;
      const siteDataObj = JSON.parse(siteData.sites);
      const sortedSiteDataObj = siteDataObj.sort(function (a, b) {
        return b.id - a.id;
      });
      setAgentAETName(sortedSiteDataObj[0].aetName);
      setAgentStatus("Active");
      setAgentLastPing(sortedSiteDataObj[0].address1);
    }
  });

  return (
    <Card className="AgentStatusCard" style={{ borderRadius: "40px" }}>
      <CardContent>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs>
              <div className={classes.agentStatus}>Agent Status</div>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs>
              <Button
                onClick={openProfileDetailsForm}
                className={classes.viewProfilButton}
              >
                Edit User Profile
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs>
              <div className={classes.agentAetName}> Agent AET Name</div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.status}> Status</div>
            </Grid>
            {/*<Grid item xs>
              <div className={classes.lastPing}>Last Ping at</div>
            </Grid>*/}
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div>
                <Button className={classes.button_Tsite}>{agentAETName}</Button>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.active}>
                <span className={classes.loggedIn}>●</span>
                {agentStatus}
              </div>
            </Grid>
            {/*<Grid item xs={4}>
              <div className={classes.time}>{agentLastPing}</div>
            </Grid>
            */}
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
}
