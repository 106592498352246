import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import store from 'REDUX/store.js';
import Routes from './Routes';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import theme from './theme';
 
function App(props) {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Routes />
        <CssBaseline />
      </ThemeProvider>
    </Provider>
  );
}

App.prototype = {
  displayForm: PropTypes.string,
};

App.defaultProps = {
  displayForm: '',
};
export default App;
