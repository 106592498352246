import React from "react";
import { Button } from "@material-ui/core";
import { useStyles } from "./Styles.js";

function ButtonComponent(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        className={`${classes.button} ${props.buttonStyle}`}
        variant="contained"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
      </Button>
    </div>
  );
}

export default ButtonComponent;
