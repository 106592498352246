import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

// import "./SiteDetailsDrawer.scss";
import { hideCreateSiteForm } from "REDUX/Slices/SiteSlice";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import CreateSiteComponentOne from "../SitesComponent/CreateSiteComponentOne"
import CreateSiteComponentTwo from "../SitesComponent/CreateSiteComponentTwo";
// import EditProfileComponentThree from "./EditProfileComponentThree";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  list: {
    width: 665,
  },
  fullList: {
    width: "auto",
  },
  drawerCompo: {
    borderRadius: "30px",
  },
}));

function CreateSiteDrawerComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const issiteCreateFormOpen = useSelector(
    (state) => state.site.issiteCreateFormOpen
  );

  const toggleDrawer = () => (event) => {
    dispatch(hideCreateSiteForm());
  };

  const nextButtonClicked = (SiteDetailsData) => {
    setSelectedComponent(
      <CreateSiteComponentTwo
        backButtonClick={backButtonClick}
        closeButtonClick={closeButtonClick}
        CreateSiteComponentTwo
        // createButtonClicked={createButtonClicked}
        EditedSiteDataFormOne={SiteDetailsData}
        callGetSiteApi={props.callGetSiteApi}
      />
    );
  };

  // const createButtonClicked = () => {
  //   dispatch(hidesiteDetailsForm());
  //   setSelectedComponent(<EditProfileComponentThree />);
  // };

  const backButtonClick = () => {
    setSelectedComponent(
      <CreateSiteComponentOne nextButtonClicked={nextButtonClicked} />
    );
  };
  const closeButtonClick = () => {
    setSelectedComponent(
      <CreateSiteComponentOne nextButtonClicked={nextButtonClicked} />
    );
  };


  const [selectedComponent, setSelectedComponent] = useState(
    <CreateSiteComponentOne nextButtonClicked={nextButtonClicked} />
  );

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      {selectedComponent}
    </div>
  );

  return (
    <>
      {["right"].map((anchor, index) => (
        <React.Fragment key={anchor + index}>
          <Drawer
            anchor={anchor}
            PaperProps={{
              style: {
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
              },
            }}
            open={issiteCreateFormOpen}
            onClose={toggleDrawer()}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
}

export default CreateSiteDrawerComponent;
