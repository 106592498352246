import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import "./SiteDetailsDrawer.scss";
import { hidesiteDetailsForm } from "REDUX/Slices/SiteSlice";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import EditProfileComponentOne from "./EditProfileComponentOne";
import EditProfileComponentTwo from "./EditProfileComponentTwo";
import EditProfileComponentThree from "../ProfileDetails/EditProfileComponentThree"
import SetupMachineIntructionPop from "../AdvancedSettingComponent/SetupMachineIntructionPop"
import EditProfileCompoFour from "../ProfileDetails/EditProfileCompoFour"
import EditProfileCompoFive from "../ProfileDetails/EditProfileCompoFive";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  list: {
    width: 665,
  },
  fullList: {
    width: "auto",
  },
  drawerCompo: {
    borderRadius: "30px",
  },
}));

function SiteDrawerDetailComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const issiteDetailsFormOpen = useSelector(
    (state) => state.site.issiteDetailsFormOpen
  );
  const toggleDrawer = () => (event) => {

    dispatch(hidesiteDetailsForm());


  };
  useEffect(() => {

    if (issiteDetailsFormOpen === false && localStorage.getItem("isThirdStepClosed") === "true") {
      setSelectedComponent(<EditProfileComponentOne nextButtonClicked={nextButtonClicked} />)
      localStorage.setItem("isThirdStepClosed", false)
    }
  })

  const nextButtonClicked = (EditedSiteDataFormOne) => {
    setSelectedComponent(
      <EditProfileComponentTwo
        backButtonClick={backButtonClick}
        EditProfileComponentTwo
        createButtonClicked={createButtonClicked}
        EditedSiteDataFormOne={EditedSiteDataFormOne}
      />
    );
  };

  const createButtonClicked = () => {
    dispatch(hidesiteDetailsForm());
    // setSelectedComponent(<EditProfileComponentThree nextButtonViewInstruction={nextButtonViewInstruction} backToSecoundPage={backToSecoundPage} />);
  };

  // const nextButtonViewInstruction = () => {
  //   // dispatch(hidesiteDetailsForm());
  //   setSelectedComponent(<EditProfileCompoFour nextButtonViewInstructionFour={nextButtonViewInstructionFour} backToThirdPage={backToThirdPage} />);
  // };
  // const nextButtonViewInstructionFour = () => {
  //   // dispatch(hidesiteDetailsForm());
  //   setSelectedComponent(<EditProfileCompoFive backToSecoundPage={backToSecoundPage} nextButtonViewInstructionFive={nextButtonViewInstructionFive} backToFourthPage={backToFourthPage} />);
  // };
  // const nextButtonViewInstructionFive = () => {
  //   // dispatch(hidesiteDetailsForm());
  //   setSelectedComponent(<SetupMachineIntructionPop backButtonClickInstruction={backButtonClickInstruction} handleCloseWidget={props.handleCloseWidget} />);
  // };
  // const backToFourthPage = (EditedSiteDataFormOne) => {
  //   setSelectedComponent(<EditProfileCompoFour nextButtonViewInstructionFour={nextButtonViewInstructionFour} backToThirdPage={backToThirdPage} />);
  // }
  // const backToThirdPage = (EditedSiteDataFormOne) => {
  //   setSelectedComponent(<EditProfileComponentThree nextButtonViewInstruction={nextButtonViewInstruction} backToSecoundPage={backToSecoundPage} />);
  // }
  // const backButtonClickInstruction = (EditedSiteDataFormOne) => {
  //   setSelectedComponent(<EditProfileCompoFive nextButtonViewInstructionFive={nextButtonViewInstructionFive} backToFourthPage={backToFourthPage} />)
  // }
  const backToSecoundPage = (EditedSiteDataFormOne) => {
    setSelectedComponent(<EditProfileComponentTwo
      backButtonClick={backButtonClick}
      EditProfileComponentTwo
      createButtonClicked={createButtonClicked}
      EditedSiteDataFormOne={EditedSiteDataFormOne}
    />)
  }
  const backButtonClick = () => {
    setSelectedComponent(
      <EditProfileComponentOne nextButtonClicked={nextButtonClicked} />
    );
  };

  const [selectedComponent, setSelectedComponent] = useState(
    <EditProfileComponentOne nextButtonClicked={nextButtonClicked} />
  );

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      {selectedComponent}
    </div>
  );

  return (
    <>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            PaperProps={{
              style: {
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
              },
            }}
            open={issiteDetailsFormOpen}
          // onClose={toggleDrawer()}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
}

export default SiteDrawerDetailComponent;
