import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

//Components
import { ROUTES } from "../../../constants/RouteConstants";

function PrivateRoute(props) {
  const { path, component, exact, ...rest } = props;
  const authtoken = useSelector((state) => state.login.authtoken);
  console.log(authtoken);

  return !window.location.href.endsWith(ROUTES.DASHBOARD) ? (
    authtoken !== "" && authtoken !== undefined ? (
      <Redirect push to={{ pathname: ROUTES.DASHBOARD }} />
    ) : (
      <Route path={path} component={component} exact={exact} {...rest} />
    )
  ) : (
    <Route path={path} component={component} exact={exact} {...rest} />
  );
}

export default PrivateRoute;
