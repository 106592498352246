import React from "react";
import cross_Image from "ASSETS/cancel.svg";
import style from "./Cancel.module.scss";
import { useDispatch } from "react-redux";
import { clearState, showCreateUser } from "REDUX/Slices/AppSlice";
import { clearSiteDetailsData } from "./../../../redux/Slices/SiteSlice";
import { clearApiResponseState } from "REDUX/Slices/ProviderLoginSlice";

function Cancel(props) {
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(clearState());
    dispatch(showCreateUser());
    dispatch(clearSiteDetailsData(false));
    dispatch(clearApiResponseState());
    if(props.handleClose !== undefined){
      props.handleClose()
    }
  };
  return (
    <div className={style.cancel} onClick={handleCancel}>
      <img
        src={cross_Image}
        className="img-fluid"
        alt="cancel"
        onClick={handleCancel}
      />
    </div>
  );
}

export default Cancel;
