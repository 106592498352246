import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

import "./SiteStatusComponent.css";

import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { showSiteDetailsForm } from "REDUX/Slices/SiteSlice";
import { API_ENDPOINT } from '../../api/ApiBaseUrl'
import { showInstruction } from "../../redux/Slices/SiteSlice";
import InstructionDrawer from "../SiteDetails/InstructionDrawer"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  siteStatus: {
    marginTop: "3%",
    textAlign: "left",
    font: "normal normal bold 20px Comfortaa",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
  },
  aetText: {
    marginTop: "50px",
    left: "226px",
    width: "131px",
    height: "17px",
    textAlign: "left",
    font: "normal normal normal 15px/17px Comfortaa",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
  },
  button: {
    color: "#ffffff",
    width: "100px",
    height: "40px",
    background: "#EF4036 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "51px",
    opacity: 1,
  },
  editProfilButton: {
    borderRadius: "40px",
    marginLeft: "20%",
    color: "#ffffff",
    marginTop: "4%",
    width: "70%",
    height: "6vh",
    background: "#484848 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: 1,
    font: "normal normal bold  12px Comfortaa",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  agentSettings: {
    marginTop: "10%",
    width: "50%",
    height: "4rem",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #2D2929",
    borderRadius: "51px",
    opacity: 1,
    textAlign: "center",
    font: "normal normal bold 15px Comfortaa",
    letterSpacing: "0px",
    color: "#484848",
    textTransform: "none",
  },
  bellybalooAgent: {
    marginTop: "15%",
    width: "100%",
    height: "70%",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #2D2929",
    borderRadius: "51px",
    opacity: 1,
    textAlign: "center",
    font: "normal normal bold 15px Comfortaa",
    letterSpacing: "0px",
    color: "#ffffff",
    textTransform: "none",
    background: "#484848 0% 0% no-repeat padding-box",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  siteName: {
    top: "541px",
    left: "226px",
    width: "161px",
    height: "33px",
    textAlign: "left",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
    font: "normal normal bold 20px Comfortaa",
  },
  location: {
    top: "626px",
    left: "226px",
    width: "131px",
    height: "17px",
    textAlign: "left",
    font: "normal normal normal 12px Comfortaa",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
  },
  institution: {
    top: "626px",

    width: "80px",
    height: "17px",
    textAlign: "center",
    font: "normal normal normal 12px Comfortaa",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
  },
  verficationPending: {
    width: "150px",
    height: "25px",
    background: "#EBEBEB 0% 0% no-repeat padding-box",
    borderRadius: "17px",
    opacity: 1,
    textAlign: "center",
    font: "normal normal bold 12px Roboto",
    letterSpacing: "0px",
    color: "#484848",
    padding: "5px",
  },
  address: {
    top: "675px",
    left: "226px",
    width: "180px",
    height: "53px",
    textAlign: "left",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
    wordWrap: "break-word",
    font: "normal normal  15px Roboto",
  },

  premierDiagnostics: {
    top: "675px",
    // width: "210px",
    // height: "23px",
    marginLeft: "2%",
    textAlign: "left",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
    font: "normal normal  15px Roboto",
    borderBox: "1px solid black",
  },
}));

export default function SiteStatusComponent(props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [siteName, setSiteName] = useState("");
  const [siteStatus, setSiteStatus] = useState("");
  const [siteLocation, setSiteLocation] = useState("");
  const [SiteId, setSiteId] = useState("");
  const [institutionName, setInstitutionName] = useState("");
  const handleClick = () => {
    // window.location = `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/site/getconfig?site=${SiteId}`;
    localStorage.setItem("BackBtnClick", true)
    dispatch(showInstruction())
  };
  const downloadBellyBalloAgent = () => {
    window.location =
      "https://s3.amazonaws.com/BB-INSTALLER/agent/latest/bellybaloo_agent_windows_x86.exe";
  };

  useEffect(() => {
    if (localStorage.getItem("isFromSignUP") === "true") {
      localStorage.setItem("isCreateSite", false);
      // dispatch(showSiteDetailsForm());
    }

  }, [])
  useEffect(() => {
    let responseData = props.responseData;
    if (responseData !== undefined && responseData.sites !== undefined) {
      const siteData = props.responseData;
      const siteDataObj = JSON.parse(siteData.sites);
      const sortedSiteDataObj = siteDataObj.sort(function (a, b) {
        return b.id - a.id;
      });
      localStorage.setItem('homeSiteName', sortedSiteDataObj[0].siteName);
      setSiteName(sortedSiteDataObj[0].siteName);
      setSiteStatus(sortedSiteDataObj[0].siteStatus);
      setInstitutionName(sortedSiteDataObj[0].instituteName);
      setSiteLocation(
        sortedSiteDataObj[0].address1 +
        " " +
        sortedSiteDataObj[0].state +
        " " +
        sortedSiteDataObj[0].country
      );
      setSiteId(sortedSiteDataObj[0].id);
      localStorage.setItem("SiteId", sortedSiteDataObj[0].id);
    }
  });

  const openProfileDetailsForm = () => (event) => {
    localStorage.setItem("BackBtnClick", false);
    localStorage.setItem("isCreateSite", false);
    dispatch(showSiteDetailsForm());
  };

  return (
    <Card className="SiteStatusCard" style={{ borderRadius: "40px" }}>
      <CardContent>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs>
              <div className={classes.siteStatus}>Site Status</div>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs>
              <Button
                onClick={openProfileDetailsForm()}
                className={classes.editProfilButton}
              >
                Edit Site Profile
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs>
              <div className={classes.siteName}> {siteName}</div>
            </Grid>
            <Grid item xs={6}>
              {/* <div className={classes.verficationPending}>
                {siteStatus}Approved
              </div> */}
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs>
              <div className={classes.location}>Location</div>
            </Grid>
            <Grid item xs={6}>
              {/* <div className={classes.institution}>Institution</div> */}
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs>
              <div className={classes.address}> {siteLocation}</div>
            </Grid>
            <Grid item xs={6}>
              {/* <div className={classes.premierDiagnostics}>
                {institutionName}
              </div> */}
            </Grid>
          </Grid>

          <Button onClick={handleClick} className={classes.agentSettings}>
            Download Bellybaloo
              </Button>

          {/* <Grid item xs={6}>
              <Button
                onClick={downloadBellyBalloAgent}
                className={classes.bellybalooAgent}
              >
                Download Bellybaloo Agent
              </Button>
            </Grid> */}

        </div>
      </CardContent>
      <InstructionDrawer />
    </Card>
  );
}
