import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

function BasicTextFields(props) {
  const classes = useStyles();
  const defineProps = () => {
    let componentProps = {
      id: props.id,
      label: props.label,
      value: props.value,
      className: props.className ? `${props.className}` : `${classes.root}`,
      onChange: props.handleChange,
      type: props.type,
      error: props.error,
      helperText: props.helperText,
      onBlur: props.onBlur,
      disabled: props.disabled,
      autoComplete: "off",
    };
    return componentProps;
  };
  const componentProps = defineProps();
  return <TextField {...componentProps} />;
}

export default BasicTextFields;
