import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_ENDPOINT } from '../../api/ApiBaseUrl'
export const CheckUsername = createAsyncThunk(
  "get/checkUsername",
  async (payload, thunkAPI) => {
    try {
      const response = await fetch(
        `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/masterManager?method=checkUserLoginPresent&userLogin=${payload}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const CheckExistingUser = createAsyncThunk(
  "get/existingUser",
  async (payload, thunkAPI) => {
    try {
      const response = await fetch(
        `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/masterManager?method=checkExistingUserLogin&userLogin=${payload.userName}&userPassword=${payload.password}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const CreateNewUser = createAsyncThunk(
  "post/createNewUser",
  async (payload, thunkAPI) => {
    try {
      const response = await fetch(
        `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/userManager?method=createNewUser`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const addEditUserSite = createAsyncThunk(
  "post/addEditUserSite",
  async (payload, thunkAPI) => {
    try {
      const response = await fetch(
        `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/userManager?method=addEditUserSite`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const addEditUserPermission = createAsyncThunk(
  "post/addEditUserPermission",
  async (payload, thunkAPI) => {
    try {
      const response = await fetch(
        `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/userManager?method=addEditUserPermission`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const UserSlice = createSlice({
  name: "user",
  initialState: {
    createUserNameError: false,
    errorMessage: "",
    error: false,
    isPasswordError: false,
    createButton: true,
    // disableExistingUserButton: true,
    createNewUserAPIResponse: [],
    isUserExists: false,
  },
  reducers: {
    //Existing screen clearing error state
    clearError: (state) => {
      state.errorMessage = "";
      state.error = false;
      state.isPasswordError = false;
    },

    clearUserStateError: (state) => {
      state.createUserNameError = false;
      return state;
    },
    enableCreateNewUserButton: (state, action) => {
      state.createButton = action.payload;
    },
    disableCreateNewUserButton: (state, action) => {
      state.createButton = action.payload;
    },
    // existingUserCreateButton: (state, action) => {
    //   state.disableExistingUserButton = action.payload;
    // },
  },
  extraReducers: {
    [CheckUsername.fulfilled]: (state, { payload }) => {
      state.createUserNameError = payload.isUserLoginPresent;
      if (payload.isUserLoginPresent) {
        state.error = false;
        state.errorMessage = "";
      } else {
        state.error = true;
        state.errorMessage = "user does not exist";
      }
    },
    [CheckUsername.rejected]: (state) => { },
    [CheckUsername.pending]: (state) => { },
    [CheckExistingUser.fulfilled]: (state, { payload }) => {
      if (payload.isUserLoginPresent === false) {
        state.isPasswordError = true;
      } else {
        state.isUserExists = payload.isUserLoginPresent;
        state.isPasswordError = false;
      }
    },
    [CreateNewUser.fulfilled]: (state, { payload }) => {
      state.createNewUserAPIResponse = payload;
    },
    [CreateNewUser.pending]: (state) => { },
    [CreateNewUser.rejected]: (state) => { },
    [addEditUserPermission.fulfilled]: (state, { payload }) => { },
    [addEditUserPermission.pending]: (state) => { },
    [addEditUserPermission.rejected]: (state) => { },
    [addEditUserSite.fulfilled]: (state) => { },
    [addEditUserSite.pending]: (state) => { },
    [addEditUserSite.rejected]: (state) => { },
  },
});

export default UserSlice.reducer;

export const {
  clearError,
  clearUserStateError,
  enableCreateNewUserButton,
  disableCreateNewUserButton,
  existingUserCreateButton,
} = UserSlice.actions;

export const userSelector = (state) => state.user;
