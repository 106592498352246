export const StateList = [
  {
    abbr: "AL",
    label: "Alabama",
  },
  {
    abbr: "AK",
    label: "Alaska",
  },
  {
    abbr: "AZ",
    label: "Arizona",
  },
  {
    abbr: "AR",
    label: "Arkansas",
  },
  {
    abbr: "CA",
    label: "California",
  },
  {
    abbr: "CO",
    label: "Colorado",
  },
  {
    abbr: "CT",
    label: "Connecticut",
  },
  {
    abbr: "DE",
    label: "Delaware",
  },
  {
    abbr: "FL",
    label: "Florida",
  },
  {
    abbr: "GA",
    label: "Georgia",
  },
  {
    abbr: "HI",
    label: "Hawaii",
  },
  {
    abbr: "ID",
    label: "Idaho",
  },
  {
    abbr: "IL",
    label: "Illinois",
  },
  {
    abbr: "IN",
    label: "Indiana",
  },
  {
    abbr: "IA",
    label: "Iowa",
  },
  {
    abbr: "KS",
    label: "Kansas",
  },
  {
    abbr: "KY",
    label: "Kentucky",
  },
  {
    abbr: "LA",
    label: "Louisiana",
  },
  {
    abbr: "ME",
    label: "Maine",
  },
  {
    abbr: "MD",
    label: "Maryland",
  },
  {
    abbr: "MA",
    label: "Massachusetts",
  },
  {
    abbr: "MI",
    label: "Michigan",
  },
  {
    abbr: "MN",
    label: "Minnesota",
  },
  {
    abbr: "MS",
    label: "Mississippi",
  },
  {
    abbr: "MO",
    label: "Missouri",
  },
  {
    abbr: "MT",
    label: "Montana",
  },
  {
    abbr: "NE",
    label: "Nebraska",
  },
  {
    abbr: "NV",
    label: "Nevada",
  },
  {
    abbr: "NH",
    label: "New Hampshire",
  },
  {
    abbr: "NJ",
    label: "New Jersey",
  },
  {
    abbr: "NM",
    label: "New Mexico",
  },
  {
    abbr: "NY",
    label: "New York",
  },
  {
    abbr: "NC",
    label: "North Dakota",
  },
  {
    abbr: "ND",
    label: "North Dakota",
  },
  {
    abbr: "OH",
    label: "Ohio",
  },
  {
    abbr: "OK",
    label: "Oklahoma",
  },
  {
    abbr: "OR",
    label: "Oregon",
  },
  {
    abbr: "PA",
    label: "Pennsylvania",
  },
  {
    abbr: "RI",
    label: " Rhode Island",
  },
  {
    abbr: "SC",
    label: "South Carolina",
  },
  {
    abbr: "SD",
    label: "South Dakota",
  },
  {
    abbr: "TN",
    label: "Tennessee",
  },
  {
    abbr: "TX",
    label: "Texas",
  },
  {
    abbr: "UT",
    label: "Utah",
  },
  {
    abbr: "VT",
    label: "Vermont",
  },
  {
    abbr: "VA",
    label: "Virginia",
  },
  {
    abbr: "WA",
    label: "Washington",
  },
  {
    abbr: "WV",
    label: "West Virginia",
  },
  {
    abbr: "WI",
    label: "Wisconsin",
  },
  {
    abbr: "WY",
    label: "Wyoming",
  },
];

export const CountryList = [
  {
    abbr: "1",
    name: "USA",
  },
];
