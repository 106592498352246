import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
// import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from "@mui/material/IconButton";
import { FaUserCircle } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        // color: "white",
    },
    okBtn: {
        borderRadius: "23px",
        width: "30%",
        maxWidth: "100%",
        height: "45px",
        marginLeft: "35%",
        marginBottom: "2%",
        background: "#484848 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        color: "#ffffff",
        font: "normal normal bold  12px Comfortaa",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#484848",
            boxShadow: "none",
        },
    },
}));
export default function ProviderLogout() {
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const logOut = () => {
        localStorage.clear();
        deleteAllCookies();
        history.push("/");
    };

    function deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div>
            <div className={classes.toolbar}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="inherit"
                >
                    <FaUserCircle />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={logOut}>Logout</MenuItem>
                </Menu>
            </div>
            {/* <div aria-describedby={id} variant="contained" className={classes.toolbar} >


                <img onClick={handleClick} style={{ width: "1rem", height: "1rem", marginLeft: ".5rem" }} src={Circle_down} alt="Circle_down.png"></img>
            </div> */}
            {/* <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div onClick={logOut} className={classes.typography}>Logout</div>
            </Popover> */}
        </div >
    );
}
