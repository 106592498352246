import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  signinButton: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  button: {
    width: "257px",
  },
}));
// .forgot__password {
//   font-size: 20px;
//   @extend .Comfortaa_Regular;
//   color: $textColor3;
// }

// .signin__button {
//   display: flex;
//   justify-content: center;
//   cursor: pointer;

//   .button__style {
//     // width: 35%;
//     font-size: 20px;
//     @extend .Comfortaa_Bold;
//     border-radius: 51px;
//   }
// }

// .signup__text {
//   @extend .Comfortaa_Bold;
//   font-size: 23px;
//   cursor: pointer;
//   color: $textColor3;
//   .signup {
//     color: $textColor5;
//   }
// }
