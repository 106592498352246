import { createSlice } from "@reduxjs/toolkit";

export const AppSlice = createSlice({
  name: "app",
  initialState: {
    areFormsVisible: false,
    isLoading: false,
    isUserDetailsVisible: false,
    isRadioButtonSelected: true,
    isSignupForm: false,
    isProviderForm: true,
    isProfileDetailsFormOpen: false,
    selectedRadioButtonValue: "",
    isSaveBtnProfileDetailsForm: false,
    iscreateUserFormOpen: false,
    isCreateSiteBtnClicked: false,
    isViewInstructionBtnClicked: false,
    iscreateSiteFormOpen: false,
    issetupMachineOpen: false
  },
  reducers: {
    showLoader: (state) => {
      state.isLoading = true;
    },

    hideLoader: (state) => {
      state.isLoading = false;
    },

    showUserDetails: (state) => {
      state.isUserDetailsVisible = true;
    },

    hideUserDetails: (state) => {
      state.isUserDetailsVisible = false;
    },

    hideCreateUser: (state) => {
      state.isRadioButtonSelected = !state.isRadioButtonSelected;
    },

    showCreateUser: (state) => {
      state.isRadioButtonSelected = true;
    },

    selectedRadioButton: (state, action) => {
      state.selectedRadioButtonValue = action.payload;
    },

    toggleUserDetails: (state) => {
      //state.isUserDetailsVisible = !state.isUserDetailsVisible;
      state.isUserDetailsVisible = true;
      // state.isRadioButtonSelected = true;
    },
    /**Toggle Issue*/
    toggleSiteDetails: (state) => {
      //state.isUserDetailsVisible = !state.isUserDetailsVisible;
      state.isUserDetailsVisible = false;
      state.isRadioButtonSelected = false;
    },

    showProviderForm: (state) => {
      
      state.isProviderForm = true;
    },

    hideProviderForm: (state) => {
      state.isProviderForm = false;
    },

    showSignupForm: (state) => {
      
      state.isSignupForm = !state.isSignupForm;
    },
    clearState: (state) => {
      state.isUserDetailsVisible = false;
      // state.isRadioButtonSelected = true;
      state.isSignupForm = false;
      state.isProviderForm = true;
    },
    showProfileDetailsForm: (state) => {
      state.isProfileDetailsFormOpen = true;
    },
    hideProfileDetailsForm: (state) => {
      state.isProfileDetailsFormOpen = false;
    },
    saveButtonProfileDetailsForm: (state) => {
      state.isSaveBtnProfileDetailsForm = true;
    },
    hidesaveButtonProfileDetailsForm: (state) => {
      state.isSaveBtnProfileDetailsForm = false;
    },
    showcreateUserForm: (state) => {
      state.iscreateUserFormOpen = true;
    },
    hidecreateUserForm: (state) => {
      state.iscreateUserFormOpen = false;
    },
    showViewInstructionBtn: (state) => {
      state.isCreateSiteBtnClicked = true;
    },
    hideViewInstructionBtn: (state) => {
      state.isCreateSiteBtnClicked = false;
    },
    showSetupMachineBtn: (state) => {
      state.isViewInstructionBtnClicked = true;
    },
    hideSetupMachineBtn: (state) => {
      state.isViewInstructionBtnClicked = false;
    },
    showcreateSiteForm: (state) => {
      state.iscreateSiteFormOpen = true;
    },
    hidecreateSiteForm: (state) => {
      state.iscreateSiteFormOpen = false;
    },

    setupMachineOpen: (state) => {
      state.issetupMachineOpen = true;
    },
    setupMachineClose: (state) => {
      state.issetupMachineOpen = false;
    },


  },
});

export const {
  showLoader,
  hideLoader,
  displayForm,
  showUserDetails,
  hideUserDetails,
  toggleUserDetails,
  toggleSiteDetails,
  hideCreateUser,
  showProviderForm,
  showSignupForm,
  hideProviderForm,
  clearState,
  showCreateUser,
  showProfileDetailsForm,
  hideProfileDetailsForm,
  saveButtonProfileDetailsForm,
  hidesaveButtonProfileDetailsForm,
  selectedRadioButton,
  showcreateUserForm,
  hidecreateUserForm,
  showViewInstructionBtn,
  hideViewInstructionBtn,
  showSetupMachineBtn,
  hideSetupMachineBtn,
  showcreateSiteForm,
  hidecreateSiteForm,
  setupMachineOpen,
  setupMachineClose

} = AppSlice.actions;

export default AppSlice.reducer;
