import React, { Component } from "react";

//Assets
import cross_Image from "ASSETS/cancel.png";

//Styles
import "./CustomError.scss";

export class CustomError extends Component {
  render() {
    return (
      <div className="custom-error">
        <div className="error-container">
          {this.props.isClose ? (
            <div className="server-side-error">
              <div className="error-message">
                {this.props.serverErrorMessage}
              </div>
              <div className="close-icon" onClick={this.props.onClose}>
                {this.props.isClose && (
                  <img
                    src={cross_Image}
                    alt="cancel"
                    className="cancel__image"
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="client-side-error">
              <span className="error-message">{this.props.errorMessage}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CustomError;
