import React, { useEffect, useState } from 'react';
import {

  Button,
  makeStyles, TextField,

} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { hidecreateUserForm } from "REDUX/Slices/AppSlice";
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import CreateUserFormComponent from './CreateUserFormComponent';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { hideEditProfileForm } from '../../redux/Slices/SiteSlice';
// import { hidecreateUserForm } from "REDUX/Slices/AppSlice";
import STRINGS from "CONSTANTS/Strings";
import Cancel from "COMMON/Cancel/Cancel";
import { API_ENDPOINT } from '../../api/ApiBaseUrl';
import { addEditUserPermission } from '../../redux/Slices/UserSlice';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
  },
  list: {
    width: 665,
  },
  fullList: {
    width: 'auto',
  },
  drawerCompo: {
    borderRadius: "30px"
  },
  okBtn: {
    borderRadius: "23px",
    width: "30%",
    maxWidth: "100%",
    height: "45px",
    marginLeft: "35%",
    marginBottom: "2%",
    background: "#484848 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: 1,
    color: "#ffffff",
    font: "normal normal bold  12px Comfortaa",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },

}));

function CrateUserFormDrawer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const iseditProfileFormOpen = useSelector((state) => state.site.iseditProfileFormOpen);
  const [openDialogChangePassword, setOpenDialogChangePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [userData, setUserData] = useState({});
  const handleChange = (event, type) => {

    const value = event.target.value;

    switch (type) {
      case "userPassword":
        if (confirmPassword !== "") {
          if (confirmPassword === value) {
            setConfirmPasswordError(false);

          } else {
            setConfirmPasswordError(true);
          }
        }
        setPassword(value)
        setPasswordError(false)
        break;
      case "userConfirmPassword":
        if (password === value) {
          setConfirmPasswordError(false);

        } else {
          setConfirmPasswordError(true);
        }
        setConfirmPassword(value)

        break;

      default:
        break;
    }
  };
  const toggleDrawer = () => (event) => {
    dispatch(hidecreateUserForm());
  };
  const handleClickOpenDialog = (userData) => {

    dispatch(hideEditProfileForm());
    // dispatch(hidecreateUserForm());

    setOpenDialogChangePassword(true);
    setUserData(userData);
    setPassword(userData.userPassword);
    setConfirmPassword(userData.userPassword);
  };

  const handleCloseDialog = () => {
    setOpenDialogChangePassword(false);
  };
  const handleUpdatePassword = () => {

    let isPasswordChanged = false;
    if (userData.userPassword !== password) {
      isPasswordChanged = true
    }
    const userDataObject = {
      id: userData.id,
      userFirstName: userData.userFirstName,
      userLastName: userData.userLastName,
      userLogin: userData.userLogin,
      userPassword: password,
      email: userData.email,
      phoneNo: userData.phoneNo,
      active: true,
      passwordChanged: isPasswordChanged
    };

    if (!password) {
      setPasswordError(true);
    }
    else if (!confirmPassword) {
      setConfirmPasswordError(true);
    }
    else if (confirmPasswordError) {
      return
    }
    else {
      fetch(
        `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/userManager?method=updateUserInfo`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userDataObject),
        }
      ).then((response) => {
        return response.json();
      })
        .then((responseData) => {

          handleCloseDialog()
        }
        );

    }
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',

      })}
      role="presentation"


    >
      <CreateUserFormComponent
        selectedUserDataForEdit={props.selectedUserDataForEdit}
        isCreateBtn={props.isCreateBtn}
        callGetUserApi={props.callGetUserApi}
        handleClickOpenDialog={handleClickOpenDialog}
        password={password}
        confirmPassword={confirmPassword}
      />
    </div>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            PaperProps={{
              style: {
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px"
              }
            }}
            open={iseditProfileFormOpen}
            onClose={toggleDrawer()}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      <Dialog open={openDialogChangePassword} >
        <DialogTitle>
          Change Password
        </DialogTitle>
        <div style={{ marginTop: "-2.5rem" }} onClick={handleCloseDialog}>
          <Cancel />
        </div>
        <DialogContent>
          <DialogContentText >
            <div className='row'>
              <div className='col-md-12' style={{ marginTop: "1rem" }}>
                <TextField
                  style={{ width: "33rem" }}
                  id={STRINGS.CREATE_USER_FORM.PASSWORD}
                  label={STRINGS.CREATE_USER_FORM.PASSWORD}
                  type="password"
                  value={password}
                  name={STRINGS.CREATE_USER_FORM.PASSWORD}
                  onChange={(event) => handleChange(event, "userPassword")}
                  error={passwordError}
                  helperText={
                    passwordError
                      ? `${STRINGS.CREAT_USER_ERROR_MSG.PASSWORD_ERROR}`
                      : ""
                  } />
              </div>
              <div className='col-md-12' >
                <TextField
                  style={{ width: "33rem" }}
                  id={STRINGS.CREATE_USER_FORM.CONFIRM_PASSWORD}
                  label={STRINGS.CREATE_USER_FORM.CONFIRM_PASSWORD}
                  type="password"
                  value={confirmPassword}
                  name={STRINGS.CREATE_USER_FORM.CONFIRM_PASSWORD}
                  onChange={(event) => handleChange(event, "userConfirmPassword")}
                  error={confirmPasswordError}
                  helperText={
                    confirmPasswordError
                      ? `${STRINGS.CREAT_USER_ERROR_MSG.CONFIRM_PASSWORD_ERROR}`
                      : ""
                  } />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <Button className={classes.okBtn} onClick={handleUpdatePassword}>
          Update Password
              </Button>
      </Dialog>

    </div>
  );
}

export default CrateUserFormDrawer;
