import { createMuiTheme } from "@material-ui/core";
import palette from "./palette";
import typography from "./typography";

const theme = createMuiTheme({
  palette,
  typography,
  overrides: {
    // MuiButton: {
    //   contained: {
    //     backgroundColor: "pink",
    //   },
    // },
  },
});

export default theme;
