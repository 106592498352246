import React from 'react';

//Components
import Welcome from './Welcome.jsx';

function WelcomeContainer() {
  return <Welcome />;
}

export default WelcomeContainer;
