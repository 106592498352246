import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Components
import TextField from "CUSTOM_COMPONENTS/TextField/TextField";

import {
  userSelector,
  clearError,
  CheckUsername,
} from "./../../redux/Slices/UserSlice";

//Constants
import STRINGS from "CONSTANTS/Strings";

//styles
import "./ExistingUser.scss";
var strongRegex = new RegExp(
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,})$/
);

var dataObject = {
  firstName: "firstName",
  lastName: "lastName",
  email: "first@gmail.com",
  phoneNumber: "20103010301030",
  userName: "",
  password: "",
  confirmPassword: "confirmPassword",
};

function ExistingUser(props) {
  const { errorMessage, error, isPasswordError } = useSelector(userSelector);
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [userNameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  useEffect(() => {
    dataObject = {
      firstName: "firstName",
      lastName: "lastName",
      email: "first@gmail.com",
      phoneNumber: "20103010301030",
      userName: "",
      password: "",
      confirmPassword: "confirmPassword",
    };
  }, []);
  const handleChange = (event, type) => {
    const value = event.target.value;
    if (value.length === 0) {
      dispatch(clearError());
    }
    switch (type) {
      case "userName":
        setUserName(value);
        if (value !== "") {
          dataObject.userName = value;
          props.checkUserFieldValidation(dataObject);
          setUsernameError(false);
          localStorage.setItem("logInUserName", value);
          localStorage.setItem("userName", value);
          dispatch(CheckUsername(value));
        } else {
          dataObject.userName = "";
          props.checkUserFieldValidation(dataObject);
          setUsernameError(true);
        }

        break;
      case "password":
        setPassword(value);
        if (value !== "") {
          if (!strongRegex.test(value)) {
            dataObject.password = "";
            props.checkUserFieldValidation(dataObject);
            setPasswordError(true);
            setPasswordErrorMsg("Invalid Password");
          } else {
            setPasswordErrorMsg("");
            setPasswordError(false);
            dataObject.password = value;
            props.checkUserFieldValidation(dataObject);
          }
        } else {
          setPasswordErrorMsg("Password Required");
          setPasswordError(true);
          dataObject.password = value;
          props.checkUserFieldValidation(dataObject);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="existing__user">
      <TextField
        id={STRINGS.SIGNUP_LOGIN.USER_DETAILS.EXISTING_USER.USER_NAME}
        type="text"
        value={userName}
        name="userName"
        error={error ? error : userNameError}
        helperText={
          `${errorMessage ? errorMessage : ""}` ||
          `${userNameError ? "Username required" : ""}`
        }
        label={STRINGS.SIGNUP_LOGIN.USER_DETAILS.EXISTING_USER.USER_NAME}
        handleChange={(event) => handleChange(event, "userName")}
      />
      <TextField
        id={STRINGS.SIGNUP_LOGIN.USER_DETAILS.EXISTING_USER.PASSWORD}
        type="password"
        value={password}
        name="password"
        error={passwordError}
        helperText={passwordError ? passwordErrorMsg : ""}
        label={STRINGS.SIGNUP_LOGIN.USER_DETAILS.EXISTING_USER.PASSWORD}
        handleChange={(event) => handleChange(event, "password")}
      />
    </div>
  );
}

export default ExistingUser;
