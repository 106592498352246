import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//import { callAPI } from "../../api/FetchTemplate";
import { API_ENDPOINT } from '../../api/ApiBaseUrl'
export const GetSiteName = createAsyncThunk(
  "get/siteName",
  async (payload, thunkAPI) => {

    try {
      const response = await fetch(
        `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/masterManager?method=checkSiteName&siteName=${payload}`,
        {
          mathod: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetInstitutionName = createAsyncThunk(
  "get/institutionName",
  async (thunkAPI) => {
    try {
      const response = await fetch(
        `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/masterManager?method=instituteMaster`,
        {
          mathod: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetAgentAETitle = createAsyncThunk(
  "get/agentAETitle",
  async (payload, thunkAPI) => {
    try {
      const response = await fetch(
        `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/masterManager?method=checkSiteAET&siteAET=${payload}`,
        {
          method: "GET",
          header: {
            Accept: "application/json",
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createSite = createAsyncThunk(
  "post/createSite",
  async (payload, thunkAPI) => {
    try {
      const response = await fetch(
        `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/siteManager`,
        {
          method: "POST",
          header: {
            Accept: "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const SiteSlice = createSlice({
  name: "site",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    siteNameError: false,
    institutionNames: [],
    agentAETitleError: false,
    siteDetailsObject: [],
    isSiteDetailsAvailable: false,
    siteCreateAPIResponse: "",
    issiteDetailsFormOpen: false,
    iseditProfileFormOpen: false,
    issiteCreateFormOpen: false,
    selectedSiteDataForEdit: {},
    isviewInstructionDrawerOpen: false,
    buttonClick: true,
    isInstructionOpen: false,
    openWidget: false,

  },
  reducers: {
    clearApiResponseState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.siteNameError = false;
      return state;
    },
    clearSiteStateError: (state) => {
      state.siteNameError = false;
      state.agentAETitleError = false;
    },
    storeSiteDetailsObject: (state, action) => {
      state.siteDetailsObject = action.payload;
    },
    enableUserScreenToggle: (state, action) => {
      state.isSiteDetailsAvailable = action.payload;
    },
    clearSiteDetailsData: (state, action) => {
      state.isSiteDetailsAvailable = action.payload;
    },
    showSiteDetailsForm: (state) => {

      state.issiteDetailsFormOpen = true;
      localStorage.setItem("isFromSignUP", false);

    },
    hidesiteDetailsForm: (state) => {
      state.issiteDetailsFormOpen = false;
    },

    showEditProfileForm: (state) => {
      state.iseditProfileFormOpen = true;
    },
    hideEditProfileForm: (state) => {
      state.iseditProfileFormOpen = false;
    },

    showCreateSiteForm: (state) => {
      state.issiteCreateFormOpen = true;
    },
    hideCreateSiteForm: (state) => {
      state.issiteCreateFormOpen = false;
    },
    setEditSiteDataToStore: (state, data) => {
      state.selectedSiteDataForEdit = data.payload;
    },
    showViewInstructionDrawer: (state) => {

      state.isviewInstructionDrawerOpen = true;
    },
    hideViewInstructionDrawer: (state) => {
      state.isviewInstructionDrawerOpen = false;
    },
    enableButton: (state) => {
      state.buttonClick = true;
    },
    disableButton: (state) => {
      state.buttonClick = false;
    },
    showInstruction: (state) => {
      state.isInstructionOpen = true;
    },
    hideInstruction: (state) => {
      state.isInstructionOpen = false;
    },
    handleClickOpenWidget: (state) => {

      state.openWidget = true
    },
    handleClickCloseWidget: (state) => {
      state.openWidget = false
    }
  },
  extraReducers: {
    [GetSiteName.pending]: (state) => {
      state.isFetching = true;
    },
    [GetSiteName.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
    },
    [GetSiteName.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isFetching = false;
      state.siteNameError = payload.isSiteNamePresent;
      return state;
    },
    [GetInstitutionName.pending]: (state) => {
      return state;
    },
    [GetInstitutionName.rejected]: (state) => {
      return state;
    },
    [GetInstitutionName.fulfilled]: (state, { payload }) => {
      state.institutionNames = JSON.parse(payload.institutions);
      return state;
    },
    [GetAgentAETitle.pending]: (state) => {
      return state;
    },
    [GetAgentAETitle.rejected]: (state) => {
      return state;
    },
    [GetAgentAETitle.fulfilled]: (state, { payload }) => {
      state.agentAETitleError = payload.isSiteAETPresent;
      return state;
    },
    [createSite.pending]: (state) => { },
    [createSite.rejected]: (state) => { },
    [createSite.fulfilled]: (state, { payload }) => {
      state.siteCreateAPIResponse = payload;
    },
  },
});

export default SiteSlice.reducer;

export const {
  setGridRenderingState,
  clearApiResponseState,
  clearSiteStateError,
  storeSiteDetailsObject,
  enableUserScreenToggle,
  clearSiteDetailsData,
  showSiteDetailsForm,
  hidesiteDetailsForm,
  showEditProfileForm,
  hideEditProfileForm,
  showCreateSiteForm,
  hideCreateSiteForm,
  setEditSiteDataToStore,
  showViewInstructionDrawer,
  hideViewInstructionDrawer,
  enableButton,
  disableButton,
  showInstruction,
  hideInstruction,
  handleClickOpenWidget,
  handleClickcloseWidget

} = SiteSlice.actions;

export const siteSelector = (state) => state.site;
