import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "COMPONENTS/Common/Header/Header.jsx";
import ProviderLogin from "COMPONENTS/ProviderLogin/ProviderLoginContainer.jsx";
import SignUpContainer from "COMPONENTS/SignUp/SignUp.jsx";

import ButtonComponent from "CUSTOM_COMPONENTS/ButtonComponent/ButtonComponent.jsx";

import STRINGS from "CONSTANTS/Strings.js";

import { showProviderForm, showSignupForm } from "REDUX/Slices/AppSlice";

import "./LandingPage.scss";

function LandingPage() {
  const { isSignupForm, isProviderForm } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const handleProviderScreen = () => {
    dispatch(showProviderForm());
  };

  const handleSignupScreen = () => {
    dispatch(showSignupForm());
  };

  return (
    <Fragment>
      <div className="landing__container">
        <Header onClick={handleProviderScreen} />

        <div className={`form ${isProviderForm ? "active" : ""} `}>
            <div>
              { isSignupForm ? (
                <SignUpContainer />
              ) : (
                <ProviderLogin handleSignupScreen={handleSignupScreen} />
              )}
            </div>
        </div>
      </div>
    </Fragment>
  );
}

export default LandingPage;
