import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Grid, Tabs, Tab } from "@material-ui/core";
import { useStyles } from "./Styles";

//Constants
import STRINGS from "CONSTANTS/Strings";

//Assets
import Bellybaloo_Logo from "ASSETS/Neuton-Logo.png";

//Slice
import { showProviderForm } from "REDUX/Slices/AppSlice";

const Header = (props) => {
  const cssClasses = useStyles();

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const handleProviderScreen = () => {
    dispatch(showProviderForm());
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Fragment>
      <AppBar position="static" elevation={0} className={cssClasses.root}>
        <Toolbar>
          <Grid container className={cssClasses.headerContainer}>
            <Grid item style={{cursor:"pointer"}} onClick={() => window.open("https://www.bellybaloo.com/", "_blank")}>
              <img
                style={{ width: "20rem" }}
                src={Bellybaloo_Logo}
                alt="Logo"
                className={cssClasses.logo}
              />
            </Grid>
            <Grid item sm></Grid>

            <Grid item>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
              >
                <Tab
                  label={STRINGS.LANDINGSCREEN.SIGN_UP_LOG_IN}
                  className={cssClasses.loginSignIn}
                  onClick={handleProviderScreen}
                  {...a11yProps(0)}
                />
              </Tabs>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  //index: PropTypes.any.isRequired,
  // value: PropTypes.any.isRequired,
};

export default Header;
