import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import * as log from "loglevel";
import { ROUTES } from "CONSTANTS/RouteConstants";

//Components
import ProviderLogin from "./ProviderLogin.jsx";

//Slices
import {
  setToken,
  LoginPost,
  userSelector,
  clearApiResponseState,
} from "REDUX/Slices/ProviderLoginSlice";

import { showLoader } from "REDUX/Slices/AppSlice.js";

function ProviderLoginContainer(props) {
  const { isSuccess, errorMessage, isError } = useSelector(userSelector);
  const [errorMsg, setErrorMsg] = useState("");

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.app);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearApiResponseState());
      props.history.push(ROUTES.DASHBOARD);
    }
    if (isError) {
      setErrorMsg("Incorrect Password ");
    }
    // if (isFetching) {
    //   setErrorMsg("Incorrect Password");
    // }
  }, [isSuccess, isError, dispatch, errorMessage, props]);

  const loginClick = (username, pasword) => {
    log.debug("username, pasword: ", username, pasword);
    let requestData = { j_username: username, j_password: pasword };

    dispatch(setToken("123"));
    dispatch(LoginPost(requestData));
    dispatch(showLoader());
  };

  const resetError = () => {
    setErrorMsg("");
    dispatch(clearApiResponseState());
  };

  return (
    <ProviderLogin
      loginClick={loginClick}
      isLoading={isLoading}
      handleSignupScreen={props.handleSignupScreen}
      errorMessage={errorMsg}
      resetError={resetError}
    />
  );
}

ProviderLoginContainer.propTypes = {
  loginClick: PropTypes.func,
};

ProviderLoginContainer.defaultProp = {
  loginClick: () => {},
};

export default withRouter(ProviderLoginContainer);
