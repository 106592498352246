import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

// import "./SetupMachineIntructionPop.css";

import Cancel from "COMMON/Cancel/Cancel";
import Grid from "@material-ui/core/Grid";

import Avatar from "@material-ui/core/Avatar";

import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {
  setupMachineOpen, setupMachineClose
} from "REDUX/Slices/AppSlice";
import { useDispatch } from "react-redux";
import FindStaticIPInstructionPopPup from "./FindStaticIPInstructionPopPup";

import { hidesiteDetailsForm } from "REDUX/Slices/SiteSlice";
import { hideInstruction } from "../../redux/Slices/SiteSlice";

const useStyles = makeStyles((theme) => ({
  orange: {
    color: "white",
    backgroundColor: "#4B5264",
    marginLeft: "30px",
    marginRight: "30px",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "8%",
  },
  para1: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: '2%',
    width: "100%"
  },
  para2: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: '4%'

  },
  orange2: {
    color: "white",
    backgroundColor: "#4B5264",
    marginLeft: "30px",
    marginRight: "30px",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "20%",
  },
  para3: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: '8%',
    width: "300px"

  },
  orange3: {
    color: "white",
    backgroundColor: "#4B5264",
    marginLeft: "30px",
    marginRight: "30px",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "20%",
  },
  para4: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: '5%'

  },
  orange4: {
    color: "white",
    backgroundColor: "#4B5264",
    marginLeft: "30px",
    marginRight: "30px",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "25%",
  },
  para5: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: '5%',
    width: "100%"

  },
  orange5: {
    color: "white",
    backgroundColor: "#4B5264",
    marginLeft: "30px",
    marginRight: "30px",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "15%",
  },
  paraNew: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: '6%',
    width: "100%"

  },
  orangeNew: {
    color: "white",
    backgroundColor: "#4B5264",
    marginLeft: "30px",
    marginRight: "30px",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "18%",
  },
  para6: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: '4%'

  },
  orangeVideoLink: {
    color: "white",
    backgroundColor: "#4B5264",
    marginLeft: "30px",
    marginRight: "30px",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "20%",
  },
  paraVideoLinkLable: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: '6%',
    width: "100%"
  },
  paraVideoLink: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
    marginTop: '2%',
    width: "100%"
  },
  instruction: {

    marginLeft: "2.7rem",
    marginTop: "2%",
    font: "normal normal bold 18px/30px Comfortaa",
    color: "#4B5264",
    opacity: 1,
  },
  yourSite: {
    font: "normal normal normal 15px/25px Comfortaa",
    color: "#000000",
    marginBottom: "2%",
    marginLeft: "1.4rem",
  },
  SetupMachineBtn: {
    borderRadius: "23px",
    width: "50%",
    height: "45px",
    marginLeft: "35%",
    background: "#484848 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: 1,
    color: "#ffffff",
    font: "normal normal bold  12px Comfortaa",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
}));
export default function SetupmachinInstructionpop(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    localStorage.setItem("ThirdStep", true);
    dispatch(setupMachineOpen());
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(hidesiteDetailsForm());
    dispatch(hideInstruction());
    localStorage.setItem("ThirdStep", true);
    localStorage.setItem("isThirdStepClosed", true);
    setOpen(false);
  };
  const backButton = () => {
    if (props.backButtonClickInstruction != undefined) {
      props.backButtonClickInstruction()
    } else {
      props.backToFifthPage_VI()
    }
  }
  const classes = useStyles();

  return (
    <div>
      {/* <Button
        onClick={handleClickOpen}
        className={classes.SetupMachineBtn}
      >
        Setup Machine
      </Button> */}
      <div>
        {/* <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              borderRadius: "40px",
            },
          }}
        > */}
        <div>
          <div className={classes.instruction} id="alert-dialog-title">
            <Cancel handleClose={handleClose} />
              Ultrasound Machine Setup
            </div>
        </div>
        <DialogContent>
          <div className={classes.yourSite}>
            Your AE Title for the Bellybaloo agent is {localStorage.getItem("aetTitle")}
          </div>
          <div>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid>
                <Avatar className={classes.orange}>1</Avatar>
              </Grid>
              <Grid>
                <div className={classes.para1}>
                  Open up your ultrasound machine network settings
                    </div>
              </Grid>
            </Grid>
            <br />
            <Grid container wrap="nowrap" spacing={2}>
              <Grid>
                <Avatar size={"2"} className={classes.orange2}>
                  2
                    </Avatar>
              </Grid>
              <Grid>
                <div className={classes.para2}>
                  Type in your AE title as shown above and the static IP address of the computer where the Bellybaloo agent is installed.*
                    </div>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid>
                <Avatar size={"2"} className={classes.orange3}>
                  3
                    </Avatar>
              </Grid>
              <Grid>
                <div className={classes.para3}>
                  Set the profile to Port 2100
                    </div>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid>
                <Avatar size={"2"} className={classes.orange4}>
                  4
                    </Avatar>
              </Grid>
              <Grid>
                <div className={classes.para4}>
                  Ensure that the ultrasound machine is setup to send DICOM images to the Bellybaloo agent
                    </div>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid>
                <Avatar size={"2"} className={classes.orange5}>
                  5
                    </Avatar>
              </Grid>
              <Grid>
                <div className={classes.para5}>
                  Setup a printer button to send the study to Bellybaloo
                    </div>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid>
                <Avatar size={"2"} className={classes.orangeNew}>
                  6
                    </Avatar>
              </Grid>
              <Grid>
                <div className={classes.paraNew}>
                  Multiple machines can be setup to send to Bellybaloo
                    </div>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid>
                <Avatar size={"2"} className={classes.orangeVideoLink}>
                  7
                    </Avatar>
              </Grid>
              <Grid>
                <div className={classes.paraVideoLinkLable}>
                  Video: Installing Bellybaloo on GE Voluson.
                    </div>
                <div className={classes.paraVideoLink}>
                  <iframe width="520" height="240" allowfullscreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen" src="https://www.youtube.com/embed/m0ti3xm6GI4">
                  </iframe>
                </div>
              </Grid>
            </Grid>
            <div className={classes.para6}>
            </div>
          </div>
          <Divider />
          <FindStaticIPInstructionPopPup handleCloseWidget={props.handleCloseWidget} handleClose={handleClose} backButton={backButton} />
        </DialogContent>
        {/* </Dialog> */}

      </div>
    </div >

  );
}
