export const ROUTES = {
  ROOT_PATH: "/",
  LANDING: "/landing",
  DASHBOARD: "/dashboard",
  WELCOME: "/welcome",
  HOME: "/dashboard/home",
  STUDIES: "/dashboard/studies",
  SITES: "/dashboard/sites",
  USERMANAGEMENT: "/dashboard/userManagement",
  CONTACT_US: "/dashboard/contactUs",
  SITECONFIGFORM: "/dashboard/advancedSetting/siteConfiguration",
  ULTRASOUNDCONFIGFORM: "/dashboard/advancedSetting/ultrasoundStatus",
  CREATEUSERFORM: "/dashboard/userManagement/CreateUserForm"
};
