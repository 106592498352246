import React, { useState, useEffect, Fragment } from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import TextField from "CUSTOM_COMPONENTS/TextField/TextField";

import STRINGS from "CONSTANTS/Strings";
import {
  GetSiteName,
  GetInstitutionName,
  GetAgentAETitle,
} from "REDUX/Slices/SiteSlice";

import "./SiteDetailsDrawer.scss";

import NewCountryList from "../../constants/New_Country_State.json";
import { siteSelector } from "../../redux/Slices/SiteSlice";
import { hidesiteDetailsForm } from "REDUX/Slices/SiteSlice";
import Cancel from "COMMON/Cancel/Cancel";
import Button from "@material-ui/core/Button";
import { API_ENDPOINT } from "../../api/ApiBaseUrl";
import Autocomplete from "@mui/material/Autocomplete";
import TextFieldAutoComplete from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import info from "../../resources/images/info.png";
import Grid from "@material-ui/core/Grid";
import IconButton from "@mui/material/IconButton";
import { Satellite } from "@material-ui/icons";
var phoneno = /^(\+\d{1,3}[- ]?)?\d{10}$/;
var phoneNoBracket = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
var SiteDetailsData = {};
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  list: {
    width: "100%",
  },
  fullList: {
    width: "100%",
  },
}));

function EditProfileComponentOne(props) {
  const [dataEdited, setDataEdited] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { siteNameError, agentAETitleError } = useSelector(siteSelector);
  const [siteName, setSitename] = useState("");
  const [agentAETitle, setagentAETitle] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [institution, setInstitution] = useState("");
  const [stateName, setStateName] = useState("");
  const [country, setCountry] = useState("");
  const [snameError, setSnameError] = useState(false);
  const [institutionError, setInstitutionError] = useState(false);
  const [aetError, setAetError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [addressTwoError, setAddressTwoError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [state, setState] = useState("");
  const [StateList, setStateList] = useState([]);
  const [sitePhoneNumber, setSitePhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [addressTwo, setAddressTwo] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);

  useEffect(() => {
    dispatch(GetSiteName(""));
    dispatch(GetAgentAETitle(""));
    const config = {
      method: "GET",
      headers: { "content-type": "application/json" },
      credentials: "include",
    };
    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/siteSupportManager?method=getSiteListByUser`,
      config
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
        if (responseData !== undefined && responseData.sites !== undefined)
          if (!dataEdited) {
            const siteData = responseData;
            const siteDataObj = JSON.parse(siteData.sites);
            const homeSiteName = localStorage.getItem("homeSiteName");
            var homeSelectedSiteName = siteDataObj.filter(function (entry) {
              return entry.siteName === homeSiteName;
            });

            var byCountryName = NewCountryList.filter(function (entry) {
              return entry.label === siteDataObj[0].country;
            });
            if (byCountryName.length !== 0) {
              setStateList(byCountryName[0].states);
            }
            const SiteId = homeSelectedSiteName[0].id;
            setagentAETitle(homeSelectedSiteName[0].aetName);
            localStorage.setItem("aetTitle", homeSelectedSiteName[0].aetName);
            setSitePhoneNumber(homeSelectedSiteName[0].sitePhoneNumber);
            setSitename(homeSelectedSiteName[0].siteName);
            setZipCode(homeSelectedSiteName[0].zipcode);
            setAddress(homeSelectedSiteName[0].address1);
            setAddressTwo(homeSelectedSiteName[0].addressTwo);
            setStateName(homeSelectedSiteName[0].state);
            setCountry(homeSelectedSiteName[0].country);
            setCity(homeSelectedSiteName[0].city);
          }
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  }, []);
  useEffect(() => {
    if (
      siteName.length > 0 &&
      localStorage.getItem("isCreateSite") === "true"
    ) {
      dispatch(GetSiteName(siteName));
    }
    if (
      agentAETitle.length > 0 &&
      localStorage.getItem("isCreateSite") === "true"
    ) {
      dispatch(GetAgentAETitle(agentAETitle));
    }
    const homeSiteName = localStorage.getItem("homeSiteName");
    if (
      siteName !== homeSiteName &&
      localStorage.getItem("isCreateSite") === "false"
    ) {
      dispatch(GetSiteName(siteName));
    }
  }, [siteName, dispatch, agentAETitle]);
  const isLetter = (str) => {
    return str.match(/[a-z]/i);
  };
  const isNoLimitAboveTen = (str) => {
    return str.length >= 10 && str.length <= 18;
  };
  const isLetterZipCode = (str) => {
    return str.match(/[a-z]/i);
  };
  const isNoLimitAboveFive = (str) => {
    return str.length >= 1 && str.length <= 6;
  };
  const handleChange = (event, obj, type) => {
    let value = event.target.value;

    let capitalized = "";
    if (type !== undefined && obj !== null) {
      if (type === "state") {
        value = obj.abbr;
      } else {
        value = obj.label;
      }
    } else {
      type = obj;
      if (value !== undefined) {
        capitalized = value.toUpperCase();
      }
    }

    setDataEdited(true);
    switch (type) {
      case "siteName":
        event.target.value = capitalized;
        setSitename(capitalized);
        setSnameError(false);
        break;
      case "institution":
        setInstitution(value);
        // setInstitutionError(false);
        break;
      case "agentAETitle":
        event.target.value = capitalized;
        setagentAETitle(capitalized);
        // setAetError(false);
        break;
      case "sitePhoneNumber":
        event.target.value = capitalized;
        if (value !== "") {
          if (!isLetter(value) && isNoLimitAboveTen(value)) {
            setPhoneNumberError(false);
          } else {
            setPhoneNumberError(true);
          }
          setSitePhoneNumber(capitalized);
        } else {
          setPhoneNumberError(false);
          setSitePhoneNumber(capitalized);
        }

        break;
      case "address":
        event.target.value = capitalized;
        setAddress(capitalized);
        // setAddressError(false);
        break;
      case "addressTwo":
        event.target.value = capitalized;
        setAddressTwo(capitalized);
        setAddressTwoError(false);
        break;
      case "zipCode":
        setZipCode(value);
        if (value !== "") {
          if (!isLetterZipCode(value) && isNoLimitAboveFive(value)) {
            setZipcodeError(false);
          } else {
            setZipcodeError(true);
          }
        }
        break;
      case "state":
        setStateName(value);
        // setStateError(false);
        break;
      case "country":
        var byCountryName = NewCountryList.filter(function (entry) {
          return entry.label === value;
        });
        setStateList(byCountryName[0].states);
        setCountry(value);
        // setCountryError(false);
        break;
      case "city":
        event.target.value = capitalized;
        setCity(capitalized);
        // setCityError(false);
        break;
      default:
        break;
    }
  };

  const handleNext = () => (event) => {
    SiteDetailsData = {
      siteName: siteName,
      aet: agentAETitle,
      sitePhoneNumber: sitePhoneNumber,
      addressString1: address,
      addressString2: addressTwo,
      zipCode: zipCode,
      state: stateName,
      country: country,
      city: city,
    };

    if (siteNameError) {
      return;
    } else if (agentAETitleError) {
      return;
    } else if (!siteName) {
      setSnameError(true);
    } else if (!agentAETitle) {
      setAetError(true);
    } else if (phoneNumberError) {
      setPhoneNumberError(true);
    } else if (!address) {
      setAddressError(true);
    }

    // else if (!addressTwo) {
    //   setAddressTwoError(true);
    // }
    else if (!zipCode) {
      setZipcodeError(true);
    } else if (!stateName) {
      setStateError(true);
    } else if (!country) {
      setCountryError(true);
    } else {
      props.nextButtonClicked(SiteDetailsData);
    }
  };

  const toggleDrawer = () => (event) => {
    dispatch(hidesiteDetailsForm());
  };
  const getStateNameByAbbr = () => {
    var selectedStateLable = "";
    for (var key in StateList) {
      if (StateList.hasOwnProperty(key)) {
        var value = StateList[key];
        if (value.abbr === stateName) {
          selectedStateLable = value.label;
        }
      }
    }
    return selectedStateLable;
  };

  return (
    <Fragment>
      <div className="provider__container">
        <div className="provider__title">
          <span className="provider__title__text">
            {STRINGS.CREATE_SITE_DETAILS.CREATE_SITE}
            <div onClick={toggleDrawer()}>
              <Cancel />
            </div>
          </span>
        </div>
        <div className="provider__title">
          <span className="site__details__text">
            {STRINGS.CREATE_SITE_DETAILS.SITE_DETAILS}
          </span>
        </div>

        <div className="profileDetailFields">
          <TextField
            id={STRINGS.CREATE_SITE_DETAILS.SITE_NAME}
            label={STRINGS.CREATE_SITE_DETAILS.SITE_NAME}
            type="text"
            value={siteName}
            name={STRINGS.CREATE_SITE_DETAILS.SITE_NAME}
            handleChange={(event) => handleChange(event, "siteName")}
            error={siteNameError ? siteNameError : snameError}
            helperText={
              `${
                siteNameError
                  ? `${STRINGS.SIGNUP_LOGIN.ERRORS.SITE_NAME_API_ERROR}`
                  : ""
              }` ||
              `${
                snameError
                  ? `${STRINGS.SIGNUP_LOGIN.ERRORS.SITE_NAME_REQUIRED}`
                  : ""
              }`
            }
          />

          <div>
            {/* <FormControl className={classes.root}>
            <InputLabel>Institution Names</InputLabel>
            <MuiSelect
              name='Institution Names'
              label='Institution Names'
              options={institutionNames}
              value={institution}
              error={institutionError}
              // helperText="error"
              onChange={(event) => handleChange(event, 'institution')}
            >
              {institutionNames.map((item) => (
                <MenuItem key={item.id} value={item.institutionName}>
                  {item.institutionName}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl> */}
          </div>
          <Grid container>
            <Grid item xs={10}>
              <TextField
                disabled
                id={STRINGS.CREATE_SITE_DETAILS.AGENT_AE_TITLE}
                label={STRINGS.CREATE_SITE_DETAILS.AGENT_AE_TITLE}
                type="text"
                readOnly
                value={agentAETitle}
                error={agentAETitleError ? agentAETitleError : aetError}
                helperText={
                  `${
                    agentAETitleError
                      ? `${STRINGS.SIGNUP_LOGIN.ERRORS.AGENT_AE_API_ERROR}`
                      : ""
                  }` ||
                  `${
                    aetError
                      ? `${STRINGS.SIGNUP_LOGIN.ERRORS.AGENT_AE_REQUIRED}`
                      : ""
                  }`
                }
                name="agentAETitle"
                handleChange={(event) => handleChange(event, "agentAETitle")}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{ marginTop: "1.5rem", marginLeft: "2.9rem" }}
            >
              <Tooltip
                title="This is the unique ID of your site that is used to associate the studies with your site.It must be 5-12 characters with no spaces. This will be used in the network settings in your ultrasound machine."
                placement="top"
              >
                <IconButton>
                  <img alt="info" src={info} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <div className="row d-flex">
            <div className="address col-md-6">
              <TextField
                id={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.PHONENUMBER}
                label={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.PHONENUMBER}
                type="text"
                value={sitePhoneNumber}
                name="sitePhoneNumber"
                error={phoneNumberError}
                helperText={
                  phoneNumberError
                    ? `${STRINGS.SIGNUP_LOGIN.ERRORS.PHONENOVALIDATIONERROR}`
                    : ""
                }
                handleChange={(event) => handleChange(event, "sitePhoneNumber")}
              />
            </div>

            <div className="address col-md-6">
              <TextField
                id={STRINGS.CREATE_SITE_DETAILS.ADDRESS}
                label={STRINGS.CREATE_SITE_DETAILS.ADDRESS}
                type="text"
                value={address}
                name="ADDRESS"
                error={addressError}
                helperText={
                  addressError
                    ? `${STRINGS.SIGNUP_LOGIN.ERRORS.ADDRESS_REQUIRED}`
                    : ""
                }
                handleChange={(event) => handleChange(event, "address")}
              />
            </div>
          </div>
          <div className="row d-flex">
            <div className="address col-md-12">
              <TextField
                id={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.ADDRESS}
                label={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.ADDRESS}
                type="text"
                value={addressTwo}
                name="ADDRESS"
                // error={addressTwoError}
                // helperText={
                //   addressTwoError
                //     ? `${STRINGS.SIGNUP_LOGIN.ERRORS.ADDRESS_REQUIRED}`
                //     : ""
                // }
                handleChange={(event) => handleChange(event, "addressTwo")}
              />
            </div>
          </div>
          <div className="row d-flex">
            <div className="country col-md-6">
              <FormControl className={classes.root}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo1"
                  options={NewCountryList}
                  onChange={(event, value) =>
                    handleChange(event, value, "country")
                  }
                  value={country}
                  error={countryError}
                  sx={{ width: 273 }}
                  renderInput={(params) => (
                    <TextFieldAutoComplete
                      variant="standard"
                      {...params}
                      label="country"
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="state col-md-6">
              <FormControl className={classes.root}>
                {" "}
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={StateList}
                  onChange={(event, value) =>
                    handleChange(event, value, "state")
                  }
                  value={getStateNameByAbbr()}
                  error={stateError}
                  sx={{ width: 275 }}
                  renderInput={(params) => (
                    <TextFieldAutoComplete
                      variant="standard"
                      {...params}
                      label="State"
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className="row d-flex">
            <div className="address col-md-6">
              <TextField
                id={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.CITY}
                label={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.CITY}
                type="text"
                value={city}
                name="city"
                error={cityError}
                helperText={
                  addressError ? `${STRINGS.SIGNUP_LOGIN.ERRORS.CITYERROR}` : ""
                }
                handleChange={(event) => handleChange(event, "city")}
              />
            </div>
            <div className="zip code col-md-6">
              <TextField
                id={STRINGS.CREATE_SITE_DETAILS.ZIP_CODE}
                label={STRINGS.CREATE_SITE_DETAILS.ZIP_CODE}
                type="text"
                value={zipCode}
                name="zipCode"
                handleChange={(event) => handleChange(event, "zipCode")}
                error={zipcodeError}
                helperText={
                  zipcodeError
                    ? `${STRINGS.SIGNUP_LOGIN.ERRORS.ZIPCODE_REQUIRED}`
                    : ""
                }
              />
            </div>
          </div>
          {/* {emailAddress.length === 0 ? <div className="errorMsg"> {STRINGS.PROFILE_DETAILS.ERROR_EMAIL}</div> : ""} */}

          <div className="errorMsg">
            {" "}
            {STRINGS.CREATE_SITE_DETAILS.ERROR_PHONE_NO}
          </div>
        </div>
        <div className="profileButtons">
          <Button className={"button__style"} onClick={handleNext()}>
            {STRINGS.CREATE_SITE_DETAILS.NEXT_BUTTON}
          </Button>
          <Button onClick={toggleDrawer()} className={"cancel_Button"}>
            {STRINGS.CREATE_SITE_DETAILS.CANCEL_BUTTON}
          </Button>
        </div>
      </div>
      <div></div>
    </Fragment>
  );
}

export default EditProfileComponentOne;
