import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router";
import STRINGS from "../../constants/Strings";
import { API_ENDPOINT } from "../../api/ApiBaseUrl";
import Button from "@material-ui/core/Button";
import { Snackbar } from "@material-ui/core";
import MuiAlert from '@mui/material/Alert';
import SweetAlert from 'react-bootstrap-sweetalert';

const useStyles = makeStyles((theme) => ({
    root1: {
        // width: "20%",
        marginTop: "15%",
        top: "256px",
        left: "464px",
        width: "250px",
        height: "200px",
        background: "#FFFFFF 0% 0 % no - repeat padding- box",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "50px",
    },

    paper: {
        width: "100%",
        height: "90%",
        borderRadius: "40px",
        padding: "3%",
    },

    container: {
        maxHeight: 450,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 2),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    title: {
        flex: "1 1 100%",
        color: "#484848",
        font: "normal normal bold 20px Comfortaa",
        marginTop: "1%",
    },
    Typography: {
        marginTop: "20px",
        width: "180px",
        height: "23px",
        font: "normal normal bold 15px Comfortaa",
        letterSpacing: "0px",
        color: "#707070",
        opacity: 1,
        marginLeft: "34px",
        textAlign: "left",
    },

    okBtn: {
        borderRadius: "23px",
        width: "15%",
        maxWidth: "100%",
        height: "45px",
        marginLeft: "40%",
        marginTop: "2%",
        background: "#484848 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        color: "#ffffff",
        font: "normal normal bold  12px Comfortaa",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#484848",
            boxShadow: "none",
        },
    },
}));
const initialState = {
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    practiceName: '',
    practiceLocation: '',
    message: '',
    address: ''
}
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function AdvancedSettingComponent() {
    const classes = useStyles();
    const history = useHistory();
    const [isContactUsBtnHide, setIsContactUsBtnHide] = React.useState(true);
    const [{ name, lastName, email, phoneNumber, practiceName, practiceLocation, message }, setState] = useState(initialState)
    const [snakState, setSnakState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const handleChange = (e) => {
        const { name, value } = e.target
        setState((prevState) => ({ ...prevState, [name]: value }));

        if (name !== "" && lastName !== "" && message !== "" && email !== "") {
            setIsContactUsBtnHide(false);
        } else {
            setIsContactUsBtnHide(true);
        }
    }
    const [show, setShow] = useState(false);
    const clearState = () => {
        setState({ ...initialState });
        setShow(false)
    }
    const contactUs = () => {
        const contactDetails = {
            name: name + " " + lastName,
            practice: practiceName,
            mobileNo: phoneNumber,
            emailId: email,
            reason: "N/A",
            message: message,
            practiceLocation: practiceLocation,
        }
        fetch(
            `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/mailManager?method=contactUsEnquiry`,
            {
                method: "POST",
                header: {
                    Accept: "application/json",
                },
                body: JSON.stringify(contactDetails),
            }
        )
            .then((response) => {
                return response.json();
            })
            .then((responseData) => {
                setShow(true);
                // clearState();
                console.log(responseData);
            })
            .catch((err) => {
                console.log("fetch error" + err);
            });
    };


    // const { vertical, horizontal, open } = snakState;

    // const handleClick = (newState) => () => {

    //     setSnakState({ open: true, ...newState });
    // };

    // const handleClose = () => {
    //     setSnakState({ ...snakState, open: false });
    // };

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <div
                        className={classes.title}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {STRINGS.CONTACTUS.CONTACT_US}
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <div>
                    <SweetAlert
                        success
                        show={show}
                        title="Thank you!"
                        onConfirm={clearState}
                        onCancel={clearState}
                        timeout={2000}
                    >
                        Form submitted successfully
          </SweetAlert>
                </div>
                <Grid item xs={12}>
                    <div className='row' style={{ overflow: "hidden", marginTop: "3%" }}>
                        <form name='sentMessage' validate
                        // onSubmit={handleSubmit}

                        >
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            id='name'
                                            name='name'
                                            value={name}
                                            className='form-control'
                                            placeholder='First Name'
                                            required
                                            onChange={handleChange}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='lastName'
                                            id='lastName'
                                            name='lastName'
                                            value={lastName}
                                            className='form-control'
                                            placeholder='Last Name'
                                            required
                                            onChange={handleChange}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>
                                <div className='col-md-6' style={{ marginTop: "2%" }}>
                                    <div className='form-group'>
                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            value={email}
                                            className='form-control'
                                            placeholder='Email'
                                            required
                                            onChange={handleChange}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>
                                <div className='col-md-6' style={{ marginTop: "2%" }}>
                                    <div className='form-group'>
                                        <input
                                            type='phoneNumber'
                                            id='phoneNumber'
                                            name='phoneNumber'
                                            value={phoneNumber}
                                            className='form-control'
                                            placeholder='Phone No.'
                                            required
                                            onChange={handleChange}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>

                                <div className='col-md-6' style={{ marginTop: "2%" }}>
                                    <div className='form-group'>
                                        <input
                                            type='practiceName'
                                            id='practiceName'
                                            name='practiceName'
                                            value={practiceName}
                                            className='form-control'
                                            placeholder='Practice Name'
                                            required
                                            onChange={handleChange}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>
                                <div className='col-md-6' style={{ marginTop: "2%" }}>
                                    <div className='form-group'>
                                        <input
                                            type='practiceLocation'
                                            id='practiceLocation'
                                            name='practiceLocation'
                                            value={practiceLocation}
                                            className='form-control'
                                            placeholder='Practice Location '
                                            required
                                            onChange={handleChange}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group' style={{ marginTop: "2%" }}>
                                <textarea
                                    name='message'
                                    id='message'
                                    value={message}
                                    className='form-control'
                                    rows='4'
                                    placeholder='Message'
                                    required
                                    onChange={handleChange}
                                ></textarea>
                                <p className='help-block text-danger'></p>
                            </div>
                            <div id='success'></div>
                            <Button disabled={isContactUsBtnHide} onClick={contactUs} className={classes.okBtn}>
                                Send
            </Button>
                        </form>
                    </div>

                </Grid>
            </Grid>
        </Paper >
    );
}
