import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import { useDispatch } from "react-redux";
import {
  showCreateSiteForm,
  setEditSiteDataToStore,
} from "REDUX/Slices/SiteSlice";

import CreateSiteDrawerComponent from "../SitesComponent/CreateSiteDrawerComponent";
import { API_ENDPOINT } from '../../api/ApiBaseUrl'
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from '@mui/material/CircularProgress';
import Cancel from "COMMON/Cancel/Cancel";
import RefreshIcon from '@material-ui/icons/Refresh';

const drawerWidth = "275px";
var dataFetched = false;
function createData(
  SiteId,
  SiteName,
  AgentAETName,
  Institution,
  sitePhoneNumber,
  Address1,
  Location,
  ZipCode
) {
  const density = AgentAETName / Institution;
  return {
    SiteId,
    SiteName,
    AgentAETName,
    Institution,
    sitePhoneNumber,
    Address1,
    Location,
    ZipCode,
  };
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "SiteId", label: "Site Id", },
  { id: "SiteName", label: "Site Name", },
  {
    id: "AgentAETName",
    label: "Agent AET Name",
    // minWidth: 170,
    // align: "right",
    // format: (value) => value.toLocaleString("en-US"),
  },
  // {
  //   id: "Institution",
  //   label: "Institution",
  //   // minWidth: 170,
  //   // align: "right",
  //   // format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "PhoneNumber",
    label: "Phone Number",
    // minWidth: 170,
    // align: "right",
    // format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Address1",
    label: "Address 1",
    // minWidth: 170,
    // align: "right",
    // format: (value) => value.toFixed(2),
  },
  {
    id: "Location",
    label: "Location",
    // minWidth: 170,
    // align: "right",
    // format: (value) => value.toFixed(2),
  },
  {
    id: "ZipCode",
    label: "Zip Code",
    // minWidth: 170,
    // align: "right",
    // format: (value) => value.toFixed(2),
  },

  // {
  //   id: "Icon1",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toFixed(2),
  // },
  {
    id: "Icon2",
    // minWidth: 170,
    // align: "right",
    // format: (value) => value.toFixed(2),
  },
  {
    id: "Icon3",
    // minWidth: 170,
    // align: "right",
    // format: (value) => value.toFixed(2),
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell
            padding="checkbox"
            style={{ backgroundColor: "#C9D9EF" }}
          ></TableCell>
          {headCells.map((headCell) => (
            <TableCell
              style={{ backgroundColor: "#C9D9EF" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: "100%",
  },

  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
    color: "#484848",
    font: "normal normal bold 20px Comfortaa",
    marginTop: "1%",
  },
  AddFilterBtn: {
    flex: "1 1 100%",
    width: "130%",
    height: "42px",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #2D2929",
    borderRadius: "51px",
    opacity: 1,
    textTransform: "none",
    marginLeft: "-60px",
    font: "normal normal bold 13px Comfortaa",
  },
  DownlaodAgentSettingBtn: {
    flex: "1 1 100%",
    width: "100%%",
    height: "42px",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #2D2929",
    borderRadius: "51px",
    opacity: 1,
    textTransform: "none",

    font: "normal normal bold 13px Comfortaa",
  },
  refreshBtn: {
    flex: "1 1 100%",
    width: "100%%",
    height: "42px",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #2D2929",
    background: "#484848 0% 0% no-repeat padding-box",
    color: "#fff",
    borderRadius: "51px",
    opacity: 1,
    textTransform: "none",
    font: "normal normal bold 13px Comfortaa",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  CreateSiteBtn: {
    flex: "1 1 100%",
    width: "100%",
    height: "42px",
    background: "#484848 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "51px",
    opacity: 1,
    color: "white",
    textTransform: "none",

    font: "normal normal bold 13px Comfortaa",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  TextField: {
    flex: "1 1 100%",
    marginTop: "2%",
    width: "auto",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    color: "white",
  },
  inputBase: {
    width: "450px",
    height: "17px",
    textAlign: "left",
    font: "normal normal bold 15px / 17px Comfortaa",
    letterSpacing: "0px",
    color: "#242424",
    opacity: 0.5,
  },
  okBtn: {
    borderRadius: "23px",
    width: "30%",
    maxWidth: "100%",
    height: "45px",
    marginLeft: "35%",
    marginBottom: "2%",
    background: "#484848 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: 1,
    color: "#ffffff",
    font: "normal normal bold  12px Comfortaa",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  restartAgentBtn: {
    marginLeft: "-1rem",
    width: "200px",
    height: "42px",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #2D2929",
    borderRadius: "51px",
    opacity: 1,
    textAlign: "center",
    font: "normal normal bold 13px Comfortaa",
    letterSpacing: "0px",
    color: "#484848",
    textTransform: "none",
  },
}));

const EnhancedTableToolbar = (props) => {
  const dispatch = useDispatch();
  var isCreateBtn = false;
  const openCreateSiteForm = () => (event) => {
    isCreateBtn = true;
    localStorage.setItem("isCreateSite", true);
    dispatch(showCreateSiteForm());
  };
  const classes = useToolbarStyles();
  const [openDialogAgentRestart, setOpenDialogAgentRestart] =
    React.useState(false);
  const [SiteId, setSiteId] = useState("");
  const handleSearch = (e) => {
    props.handleSearch(e.target.value);
  };
  const downloadAgentSetting = (e, SiteId) => {
    if (SiteId != "") {
      window.location = `${API_ENDPOINT.BaseUrl}/bb-siteadmin/site/getconfig?site=${SiteId}`;
    }
  };
  const DownloadrestartAgent = () => {
    handleCloseAgentRestartDialog();
    window.location =
      "https://s3.amazonaws.com/BB-INSTALLER/restartagent/BellybalooRestartAgentSetup.exe";
  };
  const handleClickOpenAgentRestart = () => {
    setOpenDialogAgentRestart(true);
  };

  const handleCloseAgentRestartDialog = () => {
    setOpenDialogAgentRestart(false);
  };
  const refreshBTn = () => {
    props.callGetSiteApi()
  }
  return (
    <div className="toolbar">
      <CardContent>
        <div>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={11}>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Sites
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={5}>
                <div>
                  <TextField
                    className={classes.inputBase}
                    placeholder="Search for Site Name,Address,Institution,Agent Name"
                    inputProps={{ "aria-label": "search google maps" }}
                    onChange={handleSearch}
                  />
                  <IconButton type="submit" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </div>
              </Grid>

              <Grid item xs={2}>
                <Button
                  onClick={(e) => downloadAgentSetting(e, props.id)}
                  className={classes.DownlaodAgentSettingBtn}
                >
                  Download Agent Settings
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={handleClickOpenAgentRestart}
                  className={classes.restartAgentBtn}
                >
                  Download Bellybaloo Help Service
                </Button>
                <Dialog open={openDialogAgentRestart}>
                  <DialogTitle>
                    Instruction:Bellybaloo Restart Agent Setup
                  </DialogTitle>
                  <div style={{ marginTop: "-2.7rem" }} onClick={handleCloseAgentRestartDialog}>
                    <Cancel />
                  </div>
                  <DialogContent>
                    <DialogContentText
                      style={{
                        fontSize: "1.1rem",
                        color: "black",
                        marginTop: "2rem"
                      }}
                      id="alert-dialog-description"
                    >
                      <ol>
                        <li> Download the exe file.</li>
                        {/* <li>
                          Unzip it to get the executable.file (it's inside a
                          folder).
                    </li> */}
                        <li>
                          Right click on the executable file. and select "Run as
                          Administrator".
                        </li>
                        <li>
                          {" "}
                          If it complains about security and certificate, select
                          "Run Anyway".
                        </li>
                        <li>Let the install complete.</li>
                      </ol>{" "}
                    </DialogContentText>
                  </DialogContent>
                  <Button onClick={DownloadrestartAgent} className={classes.okBtn}>
                    Download  </Button>
                </Dialog>
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={openCreateSiteForm()}
                  className={classes.CreateSiteBtn}
                >
                  Create Site
                </Button>
              </Grid>
              <Grid item xs>
                <Button className={classes.refreshBtn}>
                  <RefreshIcon onClick={refreshBTn} style={{ height: "2rem", width: "2rem" }} />
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </CardContent>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    borderRadius: "5%",
    flexGrow: 1,
  },
  paper: {
    width: "auto",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
    borderBottomLeftRadius: "30px",
    borderBottomRightRadius: "30px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: 450,
    height: 450,
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    paddingLeft: theme.spacing(2),
  },
  tableRow: {
    backgroundColor: "#DADADA",
  },
  loaderStyle: {
    position: "absolute",
    marginLeft: "45%",
    marginTop: "10%"
  }
}));

export default function SitesComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [siteResData, setSiteResData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [temprows, seTemprows] = React.useState([]);
  const [selectedSitDataForEdit, setSelectedSiteDataForEdit] =
    React.useState(null);
  const [siteId, setSiteId] = React.useState("");
  const [isRowPerPageSelect, setIsRowPerPageSelect] = React.useState(true);
  const [showLoader, setShowLoader] = React.useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setIsRowPerPageSelect(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  useEffect(() => {
    callGetSiteApi();
  }, []);

  const callGetSiteApi = () => {
    const config = {
      method: "GET",
      headers: { "content-type": "application/json" },
      credentials: "include",
    };
    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/siteSupportManager?method=getSiteListByUser`,
      config
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
        if (responseData != undefined) {

          let siteData = [];
          dataFetched = true;

          const siteDataObj = JSON.parse(responseData.sites);
          setSiteResData(siteDataObj);
          siteDataObj.map((item) => {
            let data = createData(
              item.id,
              item.siteName,
              item.aetName,
              item.instituteName,
              item.sitePhoneNumber,
              item.address1,
              item.location,
              item.zipcode

              // <div>
              //     <IconButton>
              //         <EditIcon />
              //     </IconButton>
              // </div>
            );
            siteData.push(data);
          });
          setShowLoader(false);
          setRows(siteData);
          seTemprows(siteData);
        }
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  };
  const handleSearch = (searchKeyword) => {
    setIsRowPerPageSelect(false);
    let val = searchKeyword.toLowerCase();
    if (val === "") {
      setIsRowPerPageSelect(true);
    }
    let matches = temprows.filter((v) => {
      return v.SiteName?.toLowerCase().includes(val) ||
        v.AgentAETName?.toLowerCase().includes(val) ||
        v.Address1?.toLowerCase().includes(val) ||
        v.Institution?.toLowerCase().includes(val)
    }
    );

    console.log(matches);
    setRows(matches);
  };
  const editSite = (e, id) => {

    localStorage.setItem("isCreateSite", false);
    const siteData = siteResData;
    var siteObject = siteData.filter(function (entry) {
      return entry.id === id;
    });
    dispatch(setEditSiteDataToStore(siteObject));
    setSelectedSiteDataForEdit(siteObject);
    dispatch(showCreateSiteForm());
  };
  const deleteSite = (e, id) => {

    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/siteSupportManager?method=deleteSite&siteId=${id}`,
      {
        method: "GET",
        header: {
          Accept: "application/json",
        },
        body: JSON.stringify(),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        callGetSiteApi();
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  }

  const selectCheckBox = (e, id) => {
    if (siteId === id) {
      setSiteId("");
    } else {
      setSiteId(id);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleSearch={handleSearch}
          id={siteId}
          callGetSiteApi={callGetSiteApi}

        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              className={classes.tableHeader}
            />
            {
              showLoader === true ? <CircularProgress className={classes.loaderStyle} /> :
                <TableBody>
                  {
                    isRowPerPageSelect ?
                      stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          let isItemSelected = false;
                          row.SiteId === siteId
                            ? (isItemSelected = true)
                            : (isItemSelected = false);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.label)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.SiteId + "sites"}
                              selected={isItemSelected}
                              className={index % 2 !== 0 ? classes.tableRow : ""}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ "aria-labelledby": labelId }}
                                  onChange={(e) => selectCheckBox(e, row.SiteId)}
                                />
                              </TableCell>
                              <TableCell
                                style={{ fontSize: "12px" }}
                                component="th"
                                id={labelId}
                                scope="row"
                                align="left"
                              >
                                {row.SiteId}
                              </TableCell>
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.SiteName}</TableCell>
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.AgentAETName}</TableCell>
                              {/* <TableCell style={{ fontSize: "12px" }} align="left">{row.Institution}</TableCell> */}
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.sitePhoneNumber}</TableCell>
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.Address1}</TableCell>
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.Location}</TableCell>
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.ZipCode}</TableCell>
                              {/* <TableCell align="left">{<SaveAltIcon />}</TableCell> */}
                              <TableCell align="left">
                                {<EditIcon onClick={(e) => editSite(e, row.SiteId)} />}
                              </TableCell>
                              <TableCell align="left">
                                {<DeleteIcon style={{ color: "red" }}
                                  onClick={(e) => deleteSite(e, row.SiteId)} />}
                              </TableCell>
                            </TableRow>
                          );
                        }) :
                      stableSort(rows, getComparator(order, orderBy))
                        .map((row, index) => {
                          let isItemSelected = false;
                          row.SiteId === siteId
                            ? (isItemSelected = true)
                            : (isItemSelected = false);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.label)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.SiteId + "sites"}
                              selected={isItemSelected}
                              className={index % 2 !== 0 ? classes.tableRow : ""}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ "aria-labelledby": labelId }}
                                  onChange={(e) => selectCheckBox(e, row.SiteId)}
                                />
                              </TableCell>
                              <TableCell
                                style={{ fontSize: "12px" }}
                                component="th"
                                id={labelId}
                                scope="row"
                                align="left"
                              >
                                {row.SiteId}
                              </TableCell>
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.SiteName}</TableCell>
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.AgentAETName}</TableCell>
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.Institution}</TableCell>
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.sitePhoneNumber}</TableCell>
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.Address1}</TableCell>
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.Location}</TableCell>
                              <TableCell style={{ fontSize: "12px" }} align="left">{row.ZipCode}</TableCell>
                              {/* <TableCell align="left">{<SaveAltIcon />}</TableCell> */}
                              <TableCell align="left">
                                {<EditIcon onClick={(e) => editSite(e, row.SiteId)} />}
                              </TableCell>
                              <TableCell align="left">
                                {<DeleteIcon style={{ color: "red" }}
                                  onClick={(e) => deleteSite(e, row.SiteId)} />}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                </TableBody>}
          </Table>
        </TableContainer>
        <TablePagination
          style={{ marginRight: "40%" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <CreateSiteDrawerComponent callGetSiteApi={callGetSiteApi} />
    </div>
  );
}
