import React, { useEffect, useState } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  //   LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

import { useDispatch } from "react-redux";

import { enableCreateNewUserButton } from "./../../../redux/Slices/UserSlice";

//Components
import TextField from "CUSTOM_COMPONENTS/TextField/TextField";
import ButtonComponent from "CUSTOM_COMPONENTS/ButtonComponent/ButtonComponent.jsx";

//Constants
import STRINGS from "CONSTANTS/Strings";

//Styles
import "./CaptchaComponent.scss";

function CaptchaComponent(props) {
  //const { selectedRadioButtonValue } = useSelector((state) => state.app);
  //const { isUserExists } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [captcha, setCaptcha] = useState("");
  //const [validCaptcha, setValidCaptcha] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);

  useEffect(() => {
    loadCaptchaEnginge(6, '', '', 'upper');
  }, []);

  const handleChange = (event) => {
    setCaptcha(event.target.value);
    setCaptchaError(false);
  };

  const checkCaptcha = () => {
    let user_captcha = captcha;
    // if (selectedRadioButtonValue === "Create New User") {
    //   const response = props.createUserValidation();

    //   if (response) {
    if (validateCaptcha(user_captcha) === true) {
      setCaptchaError(false);
      dispatch(enableCreateNewUserButton(false));
    } else if (!captcha) {
      setCaptchaError(true);
    } else {
      setCaptchaError(true);
    }
    //  }
    //}
    //  else {
    //   const res = props.existingUserValidation().then((res) => {
    //     console.log(res);
    //   });
    //   if (res) {
    //     console.log("user exists");
    //   } else {
    //     console.log("user not exists");
    //   }
    // }
  };

  return (
    <div className="row captcha__container">
      <div className="col-md-4 load__canvas">
        <LoadCanvasTemplate />
      </div>
      <div className="col-md-4">
        <TextField
          id={STRINGS.SIGNUP_LOGIN.USER_DETAILS.EXISTING_USER.TYPE_CODE}
          name="captcha"
          type="captcha"
          value={captcha}
          label="Type captcha"
          className="textfield "
          error={captchaError}
          helperText={captchaError ? "Invalid captcha" : ""}
          handleChange={(event) => handleChange(event, "captcha")}
        />
      </div>
      <div className="col-md-4">
        <ButtonComponent onClick={checkCaptcha} buttonStyle={`confirm__button`}>
          {STRINGS.SIGNUP_LOGIN.BUTTONS.CONFIRM}
        </ButtonComponent>
      </div>
    </div>
  );
}

export default CaptchaComponent;
