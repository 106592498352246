import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { ROUTES } from "./constants/RouteConstants";
import routes from "./utils/Routes";
import PrivateRoute from "COMMON/PrivateRoute/PrivateRoute.jsx";
import LandingPage from "COMPONENTS/LandingPage/LandingPage.jsx";
import Layout from "COMPONENTS/Layout/Layout";

export default function Routes() {
  return (
    <Router>
      <Switch>
      <Route exact path={ROUTES.ROOT_PATH} component={LandingPage} />
        <Layout>
          {routes.map((route, index) => (
            <PrivateRoute key={index} {...route} />
          ))}
          </Layout>
      </Switch>
    </Router>
  );
}
 