import React, { useState, useEffect } from "react";
import AgentStatusComponent from "../HomeComponents/AgentStatusComponent";
import SiteStatusComponent from "../HomeComponents/SiteStatusComponent";
import HomeProfileDetailsComponent from "../HomeComponents/HomeProfileDetailsComponent";
import ProfileDetails from "../ProfileDetails/ProfileDetails";
import Grid from "@material-ui/core/Grid";
import SiteDrawerDetailComponent from "../SiteDetails/SiteDrawerDetailComponent";
import { API_ENDPOINT } from '../../api/ApiBaseUrl';
import DefaultComponent from './DefaultComponent';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import HomeProfileImageComponet from "./HomeProfileImageComponent"
import { useSelector } from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HomeComponent = ({ handleClose }) => {
  const [responseData, setResponseData] = useState([]);
  const [siteResponsedata, setSiteResponseData] = useState([]);
  const [showIncognitoError, setShowIncognitoError] = useState(false);
  const [open, setOpen] = useState(false);
  const isGridRendering = useSelector(state => state.popUp.isGridRendering);

  useEffect(() => {
    if (localStorage.getItem("isNewUser") === "true") {
      handleClickOpen();
    }

    const config = {
      method: "GET",
      headers: { "content-type": "application/json" },
      credentials: "include",
    };

    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/userManager?method=getUserList`,
      config
    )
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);
        setResponseData(responseData);
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });

    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/siteSupportManager?method=getSiteListByUser`,
      config
    )
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);
        setSiteResponseData(responseData);
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseWidget = () => {
    setOpen(false);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowIncognitoError(false);
  };

  return (
    <div>
      {siteResponsedata != undefined &&
        siteResponsedata != null &&
        siteResponsedata.length != 0 &&
        siteResponsedata.sites != undefined &&
        siteResponsedata.sites != "[]" ? (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <AgentStatusComponent responseData={siteResponsedata} />
            <SiteStatusComponent responseData={siteResponsedata} />
          </Grid>
          <Grid item xs={6}>
            <HomeProfileImageComponet />
          </Grid>
          {isGridRendering && (
            <Grid item xs={6}>
              <ProfileDetails responseData={responseData} />
              <SiteDrawerDetailComponent
                responseData={responseData}
                handleCloseWidget={handleCloseWidget}
              />
              <Dialog onClose={handleClose} open={open}>
                <ProfileDetails responseData={responseData} />
                <SiteDrawerDetailComponent
                  responseData={responseData}
                  handleCloseWidget={handleCloseWidget}
                />
                <DialogTitle>Machine Setup</DialogTitle>
                <HomeProfileDetailsComponent />
              </Dialog>
            </Grid>)}
        </Grid>
      ) : (
        <DefaultComponent />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showIncognitoError}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="error"
          sx={{ width: "100%" }}
        >
          You are in incognito mode
        </Alert>
      </Snackbar>
    </div>
  );
};

export default HomeComponent;
