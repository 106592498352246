import { debounce } from "@material-ui/core";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callAPI } from "../../api/FetchTemplate";

export const LoginPost = createAsyncThunk(
  "post/loginPost",
  async (payload, thunkAPI) => {


    //{method: 'put'}
    try {

      const response = await callAPI({ body: payload });
      console.log(response);
    } catch (error) {

      console.log(error);
      return thunkAPI.rejectWithValue({ ...error });
    }
  }
);
export const ProviderLoginSlice = createSlice({
  name: "login",
  initialState: {
    authtoken: "",
    loginResponseState: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    setToken: (state, action) => {
      state.authtoken = action.payload;
    },
    clearApiResponseState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      localStorage.setItem("isSuccess", false);
      return state;
    },
  },
  extraReducers: {
    [LoginPost.fulfilled]: (state) => {

      state.isFetching = false;
      state.isSuccess = true;
      localStorage.setItem("isSuccess", true);
      return state;
    },
    [LoginPost.rejected]: (state, { payload }) => {

      console.log(payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
      localStorage.setItem("isSuccess", false);
    },
    [LoginPost.pending]: (state) => {

      state.isFetching = true;
    },
  },
});

export const { setToken, loginSuccess, clearApiResponseState } =
  ProviderLoginSlice.actions;

export default ProviderLoginSlice.reducer;

export const userSelector = (state) => state.login;
