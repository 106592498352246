import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Group from "../../resources/images/Group71.png";
import Checkmark from "../../resources/images/Checkmark.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import { showProfileDetailsForm } from "REDUX/Slices/AppSlice";
import ViewInstructionComponent from "../ProfileDetails/ViewInstructionComponent";
import Button from "@material-ui/core/Button";
import { showSiteDetailsForm } from "REDUX/Slices/SiteSlice";
import SetupMachineIntructionPop from "../AdvancedSettingComponent/SetupMachineIntructionPop";
import FindStaticIPInstructionPopPup from "../AdvancedSettingComponent/FindStaticIPInstructionPopPup";
import Grid from "@material-ui/core/Grid";
import check from "../../resources/images/check.png";
const useStyles = makeStyles({
  root: {
    marginLeft: "2%",
    marginRight: "2%",
    marginBottom: "2%",
    background: "#C9D9EF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: 1,
    borderRadius: "40px",
  },
  enterProfilrButton: {
    borderRadius: "23px",
    width: "30%",
    height: "45px",
    marginLeft: "35%",
    background: "#484848 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: 1,
    color: "#ffffff",
    font: "normal normal bold  12px Comfortaa",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  // imageClass: {
  //   width: 460,
  //   marginLeft: 25,
  //   paddingTop: "2%",
  //   // 16:9
  //   paddingLeft: "4%",
  // },
  listIcon: {
    width: "20px",
    height: "20px",
    color: "red",
  },
  steponeText: {
    font: "normal normal bold 20px/23px Comfortaa",
    color: "#484848",
  },
});

export default function ImgMediaCard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSaveBtnProfileDetailsForm = useSelector(
    (state) => state.app.isSaveBtnProfileDetailsForm
  );
  const issetupMachineOpen = useSelector(
    (state) => state.app.issetupMachineOpen
  );
  const isCreateSiteBtnClicked = useSelector(
    (state) => state.app.isCreateSiteBtnClicked
  );
  const isViewInstructionBtnClicked = useSelector(
    (state) => state.app.isViewInstructionBtnClicked
  );

  const openProfileDetailsForm = () => {
    dispatch(showProfileDetailsForm());
  };
  const openEditSiteConfigForm = () => (event) => {
    dispatch(showSiteDetailsForm());
  };

  return (
    <Card className={classes.root}>
      <div>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          image={Group}
          title="Contemplative Reptile"
          className={classes.imageClass}
        />

        <CardContent>
          <div>
            <h5>Complete the following Steps</h5>
          </div>
          <div style={{ marginLeft: "20%" }}>
            <List>
              {/* <ListItem>
                <ListItemIcon className={classes.listIcon}>
                  <img src={Checkmark} alt="Checkmark"></img>
                </ListItemIcon>
                <ListItemText
                  primary="Edit User Profile"
                  className={classes.steponeText}
                />
              </ListItem> */}

              <ListItem>
                {localStorage.getItem("FirstStep") === "true" ? (
                  <ListItemIcon className={classes.listIcon}>
                    <img src={check} alt="Checkmark"></img>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon className={classes.listIcon}>
                    <img src={Checkmark} alt="Checkmark"></img>
                  </ListItemIcon>
                )}
                <ListItemText primary="Edit Site Profile" />
              </ListItem>
              <ListItem>
                {localStorage.getItem("SecondStep") === "true" ? (
                  <ListItemIcon className={classes.listIcon}>
                    <img src={check} alt="Checkmark"></img>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon className={classes.listIcon}>
                    <img src={Checkmark} alt="Checkmark"></img>
                  </ListItemIcon>
                )}
                <ListItemText primary="Agent Settings" />
              </ListItem>

              <ListItem>
                {localStorage.getItem("ThirdStep") === "true" ? (
                  <ListItemIcon className={classes.listIcon}>
                    <img src={check} alt="Checkmark"></img>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon className={classes.listIcon}>
                    <img src={Checkmark} alt="Checkmark"></img>
                  </ListItemIcon>
                )}

                <ListItemText primary="Ultrasound Machine Setup" />
              </ListItem>
            </List>
          </div>
          <div>
            {isViewInstructionBtnClicked ? (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <SetupMachineIntructionPop />
                </Grid>
                <Grid item xs={6}>
                  <FindStaticIPInstructionPopPup />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {isCreateSiteBtnClicked ? <ViewInstructionComponent /> : ""}

            {/* {!isCreateSiteBtnClicked && !isViewInstructionBtnClicked ? ( */}
            <Button
              onClick={openEditSiteConfigForm()}
              className={classes.enterProfilrButton}
            >
              Complete
              Account Setup Here
              </Button>
            {/* ) : (
              ""
            )} */}
          </div>
        </CardContent>
      </div>
    </Card>
  );
}
