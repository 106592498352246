import React from "react";
import PropTypes from "prop-types";

//Styles
import "./Loader.scss";

export default function Loader() {
  return (
    <React.Fragment>
      <div className="backdrop">
        <div className="loading-spinner-container">
          <div className="spinner-border spinner-style">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

Loader.prototype = {
  isLoading: PropTypes.bool,
};

Loader.prototype = {
  isLoading: false,
};
