import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  button: {
    backgroundColor: "#484848",
    color: "#ffffff",
    fontsize: "20px",
    fontFamily: "Comfortaa",
    borderRadius: "51px",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
}));
