//Components
// import Dashboard from 'COMPONENTS/Dashboard/DashboardContainer.jsx';
import Welcome from "COMPONENTS/Welcome/WelcomeContainer.jsx";
import LandingPage from "COMPONENTS/LandingPage/LandingPage.jsx";
import HomeComponent from "../components/HomeComponents/HomeComponent";
import StudiesComponent from "../components/StudiesComponent/StudiesComponent";
import SitesComponent from "../components/SitesComponent/SitesComponent";
import UserManagementComponent from "../components/UserManagementComponent/UserManagementComponent";
import AdvancedSettingComponent from "../components/AdvancedSettingComponent/AdvancedSettingComponent";
import SiteConfigFormComponent from "../components/AdvancedSettingComponent/SiteConfigFormComponent";
import UltrasoundConfigFormComponent from "../components/AdvancedSettingComponent/UltrasoundConfigFormComponent";
//Constants
import { ROUTES } from "CONSTANTS/RouteConstants";
import { Component } from "react";
import CreateUserFormComponent from "../components/UserManagementComponent/CreateUserFormComponent";

const routes = [
  { path: ROUTES.ROOT_PATH, component: LandingPage, exact: true },
  {
    path: ROUTES.LANDING,
    component: LandingPage,
    exact: true,
  },
  {
    path: ROUTES.DASHBOARD,
    component: HomeComponent,
    exact: true,
  },
  {
    path: ROUTES.WELCOME,
    component: Welcome,
    exact: true,
  },
  {
    path: ROUTES.HOME,
    component: HomeComponent,
    exact: true,
  },
  {
    path: ROUTES.STUDIES,
    component: StudiesComponent,
    exact: true,
  },
  {
    path: ROUTES.SITES,
    component: SitesComponent,
    exact: true,
  },
  {
    path: ROUTES.USERMANAGEMENT,
    component: UserManagementComponent,
    exact: true,
  },
  {
    path: ROUTES.CONTACT_US,
    component: AdvancedSettingComponent,
    exact: true,
  },
  {
    path: ROUTES.SITECONFIGFORM,
    component: SiteConfigFormComponent,
  },
  {
    path: ROUTES.ULTRASOUNDCONFIGFORM,
    component: UltrasoundConfigFormComponent
  },
  {
    path: ROUTES.CREATEUSERFORM,
    component: CreateUserFormComponent
  },
];

export default routes;
