const COLORS = {
  borderColor: { borderColor1: "#ff2700" },
  backgroundColor: {
    backgroundColor1: "#484848",
    backgroundColor2: "#ececec",
    backgroundColor3: "#ffffff",
    backgroundColor4: "#ebe4e3",
    backgroundColor5: "#ef4036",
  },
  textColor: {
    textColor1: "#3c1a35",
    textColor2: "#ffffff",
    textColor3: "#707070",
    textColor4: "#484848",
    textColor5: "#fc5b6b",
    textColor6: "#fef6f5",
  },
};

export default COLORS;
