import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import STRINGS from "CONSTANTS/Strings.js";
import Cancel from "COMMON/Cancel/Cancel";
import React, { Fragment, useState, useEffect } from "react";
import TextField from "CUSTOM_COMPONENTS/TextField/TextField";
import "./ProfileDetails.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  hideProfileDetailsForm,
  saveButtonProfileDetailsForm,
} from "REDUX/Slices/AppSlice";
//Slices
import { } from "REDUX/Slices/AppSlice";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { API_ENDPOINT } from '../../api/ApiBaseUrl'
import { Hidden } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
const useStyles = makeStyles({
  list: {
    width: 664,
  },
  fullList: {
    width: "auto",
  },
  okBtn: {
    borderRadius: "23px",
    width: "30%",
    maxWidth: "100%",
    height: "45px",
    marginLeft: "35%",
    marginBottom: "2%",
    background: "#484848 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: 1,
    color: "#ffffff",
    font: "normal normal bold  12px Comfortaa",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  crossBtn: {
    marginTop: "-2rem"
  }
});

export default function ProfileDetailsComponent(props) {
  const classes = useStyles();
  const [dataEdited, setDataEdited] = useState(false);
  useEffect(() => {

    const responseData = props.responseData;
    if (
      responseData !== undefined &&
      responseData.length !== 0 &&
      responseData.users !== undefined &&
      responseData.users.length !== 0
    ) {
      if (firstName === "") {


        const loginUserName = localStorage.getItem("logInUserName")
        var userDataByUName = responseData.users.filter(function (entry) {
          return entry.userLogin === loginUserName;
        });
        const firstName = userDataByUName[0].firstName;
        const lastName = userDataByUName[0].lastName;
        const emailAddress = userDataByUName[0].email;
        const PhoneNumber = userDataByUName[0].phone;
        const UserId = userDataByUName[0].id;
        const userLogin = userDataByUName[0].userLogin;
        const password = userDataByUName[0].userPassword;
        const encryptedPassword = userDataByUName[0].userPassword
        const confirmPassword = userDataByUName[0].userPassword
        if (!dataEdited) {
          setFirstName(firstName);
          setLastName(lastName);
          setEmailAddress(emailAddress);
          setPhoneNumber(PhoneNumber);
          setUserId(UserId);
          setUserLogin(userLogin);
          setPassword(password)
          setEncryptedPassword(encryptedPassword)
          setConfirmPassword(confirmPassword)
        }
      }
    }
  });

  const isProfileDetailsFormOpen = useSelector(
    (state) => state.app.isProfileDetailsFormOpen
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userId, setUserId] = useState("");
  const [userLogin, setUserLogin] = useState("");
  const dispatch = useDispatch();
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [password, setPassword] = useState("");

  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [encryptedPassword, setEncryptedPassword] = useState("");
  const [openDialogChangePassword, setOpenDialogChangePassword] =
    React.useState(false);
  const toggleDrawer = () => (event) => {
    dispatch(hideProfileDetailsForm());
  };

  const save = () => (event) => {

    let isPasswordChanged = false;
    if (encryptedPassword !== password) {
      isPasswordChanged = true
    }
    const userDataObject = {
      id: userId,
      userFirstName: firstName,
      userLastName: lastName,
      userLogin: userLogin,
      userPassword: password,
      // userPassword: obj.password,
      email: emailAddress,
      phoneNo: phoneNumber,
      active: true,
      passwordChanged: isPasswordChanged

    };
    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/userManager?method=updateUserInfo`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
        },
        body: JSON.stringify(userDataObject),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
    handleCloseDialog()
    dispatch(saveButtonProfileDetailsForm());
    dispatch(hideProfileDetailsForm());
  };

  const handleChangeFirstName = (event) => {
    setDataEdited(true);
    setFirstName(event.target.value);
  };
  const handleChangeLastName = (event, lastName) => {
    setDataEdited(true);
    setLastName(event.target.value);
  };
  const handleChangeEmailAddress = (event, emailAddress) => {
    setDataEdited(true);
    setEmailAddress(event.target.value);
  };
  const handleChangePassword = (event) => {
    if (confirmPassword !== "") {
      if (confirmPassword === event.target.value) {
        setConfirmPasswordError(false);

      } else {
        setConfirmPasswordError(true);
      }
    }
    setPassword(event.target.value)
    setPasswordError(false)
  };
  const handleChangeConfirmPassword = (event) => {

    if (password === event.target.value) {
      setConfirmPasswordError(false);

    } else {
      setConfirmPasswordError(true);
    }
    setConfirmPassword(event.target.value)
  };
  const isLetter = (str) => {
    return str.match(/[a-z]/i);
  };
  const isNoLimitAboveTen = (str) => {
    return str.length >= 10 && str.length <= 18;
  };
  const handleChangePhoneNumber = (event, phoneNumber) => {
    setDataEdited(true);
    setPhoneNumber(event.target.value);
    if (!isLetter(event.target.value) && isNoLimitAboveTen(event.target.value)) {
      setPhoneNumberError(false);
    } else {
      setPhoneNumberError(true);
    }
  };

  const handleClickOpenDialog = () => {
    dispatch(hideProfileDetailsForm());
    setOpenDialogChangePassword(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogChangePassword(false);
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <Fragment>
        <div className="provider__container">
          <div className="provider__title">
            <span className="provider__title__text">
              {STRINGS.PROFILE_DETAILS.TITLE}
              <div onClick={toggleDrawer()}>
                <Cancel />
              </div>
            </span>
          </div>

          <div className="profileDetailFields">
            <TextField
              id={STRINGS.PROFILE_DETAILS.FIRST_NAME}
              label={STRINGS.PROFILE_DETAILS.FIRST_NAME}
              type="text"
              value={firstName}
              name={STRINGS.PROFILE_DETAILS.FIRST_NAME}
              handleChange={(event) => handleChangeFirstName(event)}
            //errorBorder={isNameHighlighted}
            />
            {firstName.length === 0 ? (
              <div className="errorMsg">
                {" "}
                {STRINGS.PROFILE_DETAILS.ERROR_FIRST_NAME}
              </div>
            ) : (
              ""
            )}

            <TextField
              id={STRINGS.PROFILE_DETAILS.LAST_NAME}
              label={STRINGS.PROFILE_DETAILS.LAST_NAME}
              type="text"
              value={lastName}
              name={STRINGS.PROFILE_DETAILS.LAST_NAME}
              handleChange={(event) => handleChangeLastName(event)}
            //errorBorder={isNameHighlighted}
            />
            {lastName.length === 0 ? (
              <div className="errorMsg">
                {" "}
                {STRINGS.PROFILE_DETAILS.ERROR_LAST_NAME}
              </div>
            ) : (
              ""
            )}

            <TextField
              id={STRINGS.PROFILE_DETAILS.EMAIL}
              label={STRINGS.PROFILE_DETAILS.EMAIL}
              type="text"
              value={emailAddress}
              name={STRINGS.PROFILE_DETAILS.EMAIL}
              handleChange={(event) => handleChangeEmailAddress(event)}
            //errorBorder={isNameHighlighted}
            />
            {emailAddress.length === 0 ? (
              <div className="errorMsg">
                {" "}
                {STRINGS.PROFILE_DETAILS.ERROR_EMAIL}
              </div>
            ) : (
              ""
            )}

            <TextField
              id={STRINGS.PROFILE_DETAILS.PHONE_NO}
              label={STRINGS.PROFILE_DETAILS.PHONE_NO}
              type="text"
              value={phoneNumber}
              name={STRINGS.PROFILE_DETAILS.PHONE_NO}
              handleChange={(event) => handleChangePhoneNumber(event)}
              error={phoneNumberError}
              helperText={
                phoneNumberError
                  ? `${STRINGS.SIGNUP_LOGIN.ERRORS.PHONENOVALIDATIONERROR}`
                  : ''
              }
            />

          </div>
          <div className="profileButtons">
            <Button disabled={firstName === "" || lastName === "" || emailAddress === "" || phoneNumber === "" || phoneNumberError || password === "" || confirmPassword === "" || passwordError || confirmPasswordError} className={"button__style"} onClick={save()}>
              {STRINGS.PROFILE_DETAILS.SAVE_BUTTON}
            </Button>
            <Button className={"chnagePassword_btn"} onClick={handleClickOpenDialog}>
              {STRINGS.PROFILE_DETAILS.CHANGEPASSWORDBTN}
            </Button>

            <Button className={"cancel_Button"} onClick={toggleDrawer()}>
              {STRINGS.PROFILE_DETAILS.CANCEL_BUTTON}
            </Button>
          </div>
        </div>
        <div></div>
      </Fragment>
    </div>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            PaperProps={{
              style: {
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
              },
            }}
            open={isProfileDetailsFormOpen}
            onClose={toggleDrawer()}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

      <Dialog open={openDialogChangePassword} >
        <DialogTitle>
          Change Password
        </DialogTitle>
        <div style={{ marginTop: "-2.5rem" }} onClick={handleCloseDialog}>
          <Cancel />
        </div>
        <DialogContent>
          <DialogContentText >
            <div className='row'>
              <div className='col-md-12' style={{ marginTop: "1rem" }}>
                <TextField
                  id={STRINGS.CREATE_USER_FORM.PASSWORD}
                  label={STRINGS.CREATE_USER_FORM.PASSWORD}
                  type="password"
                  value={password}
                  name={STRINGS.CREATE_USER_FORM.PASSWORD}
                  handleChange={(event) => handleChangePassword(event, "userPassword")}
                  error={passwordError}
                  helperText={
                    passwordError
                      ? `${STRINGS.CREAT_USER_ERROR_MSG.PASSWORD_ERROR}`
                      : ""
                  } />
              </div>
              <div className='col-md-12'>
                <TextField
                  id={STRINGS.CREATE_USER_FORM.CONFIRM_PASSWORD}
                  label={STRINGS.CREATE_USER_FORM.CONFIRM_PASSWORD}
                  type="password"
                  value={confirmPassword}
                  name={STRINGS.CREATE_USER_FORM.CONFIRM_PASSWORD}
                  handleChange={(event) => handleChangeConfirmPassword(event, "userConfirmPassword")}
                  error={confirmPasswordError}
                  helperText={
                    confirmPasswordError
                      ? `${STRINGS.CREAT_USER_ERROR_MSG.CONFIRM_PASSWORD_ERROR}`
                      : ""
                  } />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <Button onClick={save()} className={classes.okBtn}>
          Update Password
              </Button>
      </Dialog>
    </div>
  );
}
