import React, { useState, useEffect, Fragment } from 'react';
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select as MuiSelect,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import TextField from 'CUSTOM_COMPONENTS/TextField/TextField';

import STRINGS from 'CONSTANTS/Strings';
import {
  GetSiteName,
  GetInstitutionName,
  GetAgentAETitle,

} from 'REDUX/Slices/SiteSlice';


import CountryList from "../../constants/New_Country_State.json";

import { siteSelector } from '../../redux/Slices/SiteSlice';
import { hideEditProfileForm } from "REDUX/Slices/SiteSlice";
import Cancel from "COMMON/Cancel/Cancel";
import Button from '@material-ui/core/Button';
import { hideCreateSiteForm } from "REDUX/Slices/SiteSlice";
import Autocomplete from "@mui/material/Autocomplete";
import TextFieldAutoComplete from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import info from "../../resources/images/info.png";
import Grid from "@material-ui/core/Grid";
import IconButton from "@mui/material/IconButton";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
  },
  list: {
    width: 665,
  },
  fullList: {
    width: 'auto',
  },
}));

function EditProfileComponentOne(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { siteNameError, agentAETitleError, selectedSiteDataForEdit } =
    useSelector(siteSelector);

  const [siteName, setSitename] = useState('');
  const [sitePhoneNumber, setSitePhoneNumber] = useState('');
  const [agentAETitle, setagentAETitle] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [institution, setInstitution] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [institutionError, setInstitutionError] = useState(false);
  const [aetError, setAetError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [snameError, setSnameError] = useState(false);
  const [aetCharLimitError, setAetCharLimitError] = useState(false);
  const [StateList, setStateList] = useState([]);
  const [addressTwo, setAddressTwo] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  useEffect(() => {
    dispatch(GetSiteName(""));
    dispatch(GetAgentAETitle(""));
    if (localStorage.getItem("isCreateSite") === "false" && selectedSiteDataForEdit != undefined) {
      const siteData = selectedSiteDataForEdit;
      var byCountryName = CountryList.filter(function (entry) {
        return entry.label === siteData[0].country;
      });
      if (byCountryName.length !== 0) {
        setStateList(byCountryName[0].states);
      }
      setSitename(siteData[0].siteName)
      setagentAETitle(siteData[0].aetName)
      setSitePhoneNumber(siteData[0].sitePhoneNumber)
      setAddress(siteData[0].address1)
      setAddressTwo(siteData[0].addressTwo)
      setZipCode(siteData[0].zipcode)
      setState(siteData[0].state)
      setCountry(siteData[0].country)
      setCity(siteData[0].city)
    }
  }, []);
  useEffect(() => {
    if (siteName.length > 0 && localStorage.getItem("isCreateSite") === "true") {
      dispatch(GetSiteName(siteName));
    }
    if (agentAETitle.length > 0 && localStorage.getItem("isCreateSite") === "true") {
      dispatch(GetAgentAETitle(agentAETitle));
    }
    const homeSiteName = localStorage.getItem("homeSiteName");
    if (
      siteName !== homeSiteName &&
      localStorage.getItem("isCreateSite") === "false"
    ) {
      dispatch(GetSiteName(siteName));
    }
  }, [siteName, dispatch, agentAETitle]);
  const isLetter = (str) => {
    return str.match(/[a-z]/i);
  };
  const isNoLimitAboveTen = (str) => {
    return str.length >= 10 && str.length <= 18;
  };

  const isLetterZipCode = (str) => {
    return str.match(/[a-z]/i);
  };
  const isNoLimitAboveFive = (str) => {
    return str.length >= 1 && str.length <= 6;
  };
  const handleChange = (event, obj, type) => {
    let value = event.target.value;

    let capitalized = "";
    if (type !== undefined && obj !== null) {
      if (type === "state") {
        value = obj.abbr;
      } else {
        value = obj.label;
      }
    } else {
      type = obj;
      if (value !== undefined) {
        capitalized = value.toUpperCase();
      }
    }
    switch (type) {
      case 'siteName':
        event.target.value = capitalized;
        setSitename(capitalized);
        setSnameError(false);
        break;
      case 'institution':
        setInstitution(value);
        setInstitutionError(false);
        break;
      case 'agentAETitle':
        event.target.value = capitalized;
        if (event.target.value.length < 5 || event.target.value.length >= 12) {
          setAetCharLimitError(true);
        } else {
          setAetCharLimitError(false);
        }
        setagentAETitle(capitalized);
        // setAetError(false);
        break;
      case 'phoneNumber':
        event.target.value = capitalized;
        if (value !== "") {
          if (!isLetter(value) && isNoLimitAboveTen(value)) {
            setPhoneNumberError(false);
          } else {
            setPhoneNumberError(true);
          }
          setSitePhoneNumber(capitalized);
        } else {
          setPhoneNumberError(false);
          setSitePhoneNumber(capitalized);
        }

        break;
      case 'address':
        event.target.value = capitalized;
        setAddress(capitalized);
        // setAddressError(false);
        break;
      case 'addressTwo':
        event.target.value = capitalized;
        setAddressTwo(capitalized);
        // setAddressError(false);
        break;

      case 'state':
        setState(value);
        // setStateError(false);
        break;
      case 'country':
        var byCountryName = CountryList.filter(function (entry) {
          return entry.label === value;
        });
        setStateList(byCountryName[0].states);
        setCountry(value);
        // setCountryError(false);
        break;
      case "city":
        event.target.value = capitalized;
        setCity(capitalized);
        // setCityError(false);
        break;
      case 'zipCode':
        setZipCode(value);
        if (value !== "") {
          if (!isLetterZipCode(value) && isNoLimitAboveFive(value)) {
            setZipcodeError(false);
          } else {
            setZipcodeError(true);
          }
        }
        break;
      default:
        break;
    }
  };



  const handleNext = () => (event) => {

    let SiteDetailsData = {
      siteName: siteName,
      institution: institution,
      sitePhoneNumber: sitePhoneNumber,
      aet: agentAETitle,
      addressString1: address,
      addressString2: addressTwo,
      zipCode: zipCode,
      state: state,
      country: country,
      city: city
    };



    if (siteNameError) {
      return;
    } else if (agentAETitleError) {
      return;
    } else if (!siteName) {
      setSnameError(true);
    } else if (!agentAETitle) {
      setAetError(true);
    }

    else if (phoneNumberError) {
      setPhoneNumberError(true);
    }
    else if (!address) {
      setAddressError(true);
    }
    // else if (!addressTwo) {
    //   setAddressError(true);
    // }
    else if (!state) {
      setStateError(true);
    } else if (!country) {
      setCountryError(true);
    } else if (!city) {
      setCityError(true);
    }
    else if (!zipCode) {
      setZipcodeError(true);
    } else {
      if (aetCharLimitError === false && zipcodeError === false) {
        props.nextButtonClicked(SiteDetailsData);
      }

    }
  };

  const toggleDrawer = () => (event) => {

    dispatch(hideCreateSiteForm());
  };
  const getStateNameByAbbr = () => {
    var selectedStateLable = "";
    for (var key in StateList) {
      if (StateList.hasOwnProperty(key)) {
        var value = StateList[key];
        if (value.abbr === state) {
          selectedStateLable = value.label;
        }
      }
    }
    return selectedStateLable;
  };


  return (
    <Fragment>
      <div className="provider__container">
        <div className="provider__title">
          <span className="provider__title__text">{localStorage.getItem("isCreateSite") === "true" ?
            STRINGS.CREATE_AND_EDITSITE_DETAILS.CREATE_SITE : "Edit Site"}
            <div onClick={toggleDrawer()}>
              <Cancel />
            </div>
          </span>
        </div>
        <div className="provider__title">
          <span className="site__details__text">
            {STRINGS.CREATE_AND_EDITSITE_DETAILS.SITE_DETAILS}

          </span>
        </div>


        <div className="profileDetailFields">
          <TextField
            id={STRINGS.CREATE_AND_EDITSITE_DETAILS.SITE_NAME}
            label={STRINGS.CREATE_AND_EDITSITE_DETAILS.SITE_NAME}
            type="text"
            value={siteName}
            name={STRINGS.CREATE_AND_EDITSITE_DETAILS.SITE_NAME}
            handleChange={(event) => handleChange(event, "siteName")}
            error={siteNameError ? siteNameError : snameError}
            helperText={
              `${siteNameError
                ? `${STRINGS.SIGNUP_LOGIN.ERRORS.SITE_NAME_API_ERROR}`
                : ""
              }` ||
              `${snameError
                ? `${STRINGS.SIGNUP_LOGIN.ERRORS.SITE_NAME_REQUIRED}`
                : ""
              }`
            }
          />


          {/* <div >
            <FormControl className={classes.root}>
              <InputLabel>Institution Names</InputLabel>
              <MuiSelect
                name='Institution Names'
                label='Institution Names'
                options={institutionNames}
                value={institution}
                error={institutionError}
                // helperText="error"
                onChange={(event) => handleChange(event, 'institution')}
              >
                {institutionNames.map((item) => (
                  <MenuItem key={item.id} value={item.institutionName}>
                    {item.institutionName}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          </div> */}
          <Grid container>
            <Grid item xs={10}>
              <TextField
                id={STRINGS.CREATE_AND_EDITSITE_DETAILS.AGENT_AE_TITLE}
                label={STRINGS.CREATE_AND_EDITSITE_DETAILS.AGENT_AE_TITLE}
                type='text'
                value={agentAETitle}
                error={agentAETitleError ? agentAETitleError : (aetError || aetCharLimitError)}
                helperText={
                  `${agentAETitleError
                    ? `${STRINGS.SIGNUP_LOGIN.ERRORS.AGENT_AE_API_ERROR}`
                    : ''
                  }` ||
                  `${aetError ? `${STRINGS.SIGNUP_LOGIN.ERRORS.AGENT_AE_REQUIRED}` : ''
                  }` ||
                  `${aetCharLimitError
                    ? `${STRINGS.SIGNUP_LOGIN.ERRORS.AGENT_AE_CHARLIMIT}`
                    : ""
                  }`
                }
                disabled={localStorage.getItem("isCreateSite") === "false"}
                name='agentAETitle'
                handleChange={(event) => handleChange(event, 'agentAETitle')}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{ marginTop: "1.5rem", marginLeft: "2.9rem" }}
            >
              <Tooltip
                title="This is the unique ID of your site that is used to associate the studies with your site.It must be 5-12 characters with no spaces. This will be used in the network settings in your ultrasound machine."
                placement="top"
              >
                <IconButton>
                  <img alt="info" src={info} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <div className='row d-flex'>
            <div className='address col-md-6'>
              <TextField
                id={STRINGS.CREATE_AND_EDITSITE_DETAILS.PHONENUMBER}
                label={STRINGS.CREATE_AND_EDITSITE_DETAILS.PHONENUMBER}
                type='text'
                value={sitePhoneNumber}
                name='phoneNumber'
                error={phoneNumberError}
                helperText={
                  phoneNumberError
                    ? `${STRINGS.SIGNUP_LOGIN.ERRORS.PHONENOVALIDATIONERROR}`
                    : ''
                }
                handleChange={(event) => handleChange(event, 'phoneNumber')}
              />
            </div>
            <div className='address col-md-6'>
              <TextField
                id={STRINGS.CREATE_AND_EDITSITE_DETAILS.ADDRESS}
                label={STRINGS.CREATE_AND_EDITSITE_DETAILS.ADDRESS}
                type='text'
                value={address}
                name='ADDRESS'
                error={addressError}
                helperText={
                  addressError
                    ? `${STRINGS.SIGNUP_LOGIN.ERRORS.ADDRESS_REQUIRED}`
                    : ''
                }
                handleChange={(event) => handleChange(event, 'address')}
              />
            </div>


          </div>
          <div className="row d-flex">
            <div className="address col-md-12">
              <TextField
                id={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.ADDRESS}
                label={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.ADDRESS}
                type="text"
                value={addressTwo}
                name="ADDRESS"
                // error={addressError}
                // helperText={
                //   addressError
                //     ? `${STRINGS.SIGNUP_LOGIN.ERRORS.ADDRESS_REQUIRED}`
                //     : ""
                // }
                handleChange={(event) => handleChange(event, "addressTwo")}
              />
            </div>
          </div>
          <div className='row d-flex'>

            <div className='country col-md-6'>
              <FormControl className={classes.root}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo1"
                  options={CountryList}
                  onChange={(event, value) => handleChange(event, value, "country")}
                  value={country}
                  error={countryError}
                  sx={{ width: 274 }}
                  renderInput={(params) => (
                    <TextFieldAutoComplete
                      variant="standard"
                      {...params}
                      label="country"
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className='state col-md-6'>

              <FormControl className={classes.root}>
                {" "}
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={StateList}
                  onChange={(event, value) =>
                    handleChange(event, value, "state")
                  }
                  value={getStateNameByAbbr()}
                  error={stateError}
                  sx={{ width: 275 }}
                  renderInput={(params) => (
                    <TextFieldAutoComplete
                      variant="standard"
                      {...params}
                      label="State"
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className='row d-flex'>
            <div className="address col-md-6">
              <TextField
                id={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.CITY}
                label={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.CITY}
                type="text"
                value={city}
                name="city"
                error={cityError}
                helperText={
                  addressError ? `${STRINGS.SIGNUP_LOGIN.ERRORS.CITYERROR}` : ""
                }
                handleChange={(event) => handleChange(event, "city")}
              />
            </div>
            <div className="zip code col-md-6">
              <TextField
                id={STRINGS.CREATE_AND_EDITSITE_DETAILS.ZIP_CODE}
                label={STRINGS.CREATE_AND_EDITSITE_DETAILS.ZIP_CODE}
                type='text'
                value={zipCode}
                name='zipCode'
                handleChange={(event) => handleChange(event, 'zipCode')}
                error={zipcodeError}
                helperText={
                  zipcodeError
                    ? `${STRINGS.SIGNUP_LOGIN.ERRORS.ZIPCODE_REQUIRED}`
                    : ''
                }
              />
            </div></div>
          {/* {emailAddress.length === 0 ? <div className="errorMsg"> {STRINGS.PROFILE_DETAILS.ERROR_EMAIL}</div> : ""} */}


          <div className="errorMsg"> {STRINGS.CREATE_AND_EDITSITE_DETAILS.ERROR_PHONE_NO}</div>
        </div>
        <div className="profileButtons">
          <Button className={"button__style"} onClick={handleNext()}>
            {STRINGS.CREATE_AND_EDITSITE_DETAILS.NEXT_BUTTON}
          </Button>
          <Button className={"cancel_Button"} onClick={toggleDrawer()} >
            {STRINGS.CREATE_AND_EDITSITE_DETAILS.CANCEL_BUTTON}
          </Button>
        </div>
      </div>
      <div>

      </div>
    </Fragment>
  )
}

export default EditProfileComponentOne;
