import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Group from "../../resources/images/Group71.png";

const useStyles = makeStyles({
    root: {
        marginLeft: "8%",
        background: "#C9D9EF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        borderRadius: "40px",
        height: "107%",
        justifiyContent: "cener",
        alignItems: "center"
    },

    imageClass: {
        margin: "auto",
        width: "100%",
        padding: "10px",
        // width: "100%",
        height: "100%",
        marginTop: "6rem"
        // marginLeft: 25,
        // paddingTop: "2%",
        // 16:9
        // paddingLeft: "4%",
    },

});

export default function HomeProfileImageComponent() {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <div>
                <CardMedia
                    component="img"
                    alt="Contemplative Reptile"
                    image={Group}
                    title="Contemplative Reptile"
                    className={classes.imageClass}
                />
            </div>
        </Card>
    );
}
