import React, { Fragment, useState } from "react";

//CustomComponents
import TextField from "CUSTOM_COMPONENTS/TextField/TextField";
import CustomError from "COMMON/CustomError/CustomError";
import Cancel from "COMMON/Cancel/Cancel";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";

import { useStyles } from "./Styles";
//Constants
import STRINGS from "CONSTANTS/Strings.js";

//Styles
import "./ProviderLogin.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { API_ENDPOINT } from "../../api/ApiBaseUrl";
import { disableButton } from "../../redux/Slices/SiteSlice";
function ProviderLogin(props) {
  const history = useHistory();
  const classes = useStyles();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setError] = useState("");
  const [isNameHighlighted, setNameHighlighted] = useState(false);
  const [isPasswordHighlighted, setpasswordHighlighted] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [userNamePresentError, setUserNamePresentError] = useState(false);
  const checkIsExistingUser = (userName) => {
    const config = {
      method: "GET",
      headers: { "content-type": "application/json" },
      credentials: "include",
    };
    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/masterManager?method=checkUserLoginPresent&userLogin=${userName}`,
      config
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData !== undefined) {
          if (responseData.isUserLoginPresent === true) {
            setUserNamePresentError(false);
          } else {
            setUserNamePresentError(true);
          }
        }
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  };
  const handleChange = (event, type) => {
    props.resetError();
    switch (type) {
      case "userName":
        setUserName(event.target.value);
        localStorage.setItem("logInUserName", event.target.value);
        setError("");
        setNameHighlighted(false);
        break;
      case "password":
        setPassword(event.target.value);
        setError("");
        setpasswordHighlighted(false);
        break;
      default:
        break;
    }
  };
  const handleChange1 = (event, type) => {
    const value = event.target.value;
    switch (type) {
      case "userName":
        checkIsExistingUser(value);
        setUserName(value);
        break;
    }
  };

  const loginClick = () => {
    localStorage.setItem("isNewUser", false);
    if (userName === "" && password === "") {
      setError("Please fill the details");
      setNameHighlighted(true);
      setpasswordHighlighted(true);
    } else if (userName.length > 0) {
      if (password.length >= 8) {
        props.loginClick(userName, password);
      } else {
        setNameHighlighted(false);
        setpasswordHighlighted(true);
        setError("Password must be eight digits");
      }
    } else {
      setError("Username required");
      setpasswordHighlighted(false);
      setNameHighlighted(true);
    }
  };

  const handleSignupScreen = () => {
    props.handleSignupScreen();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleForgetPasswordSubmit = () => {
    // generate a random password
    var iteration = 0;
    var password = "";
    var randomNumber;
    while (iteration < 10) {
      randomNumber = (Math.floor(Math.random() * 100) % 94) + 33;
      if (randomNumber <= 0 && randomNumber >= 9) {
        continue;
      }
      if (randomNumber >= 91 && randomNumber <= 96) {
        continue;
      }
      if (randomNumber >= 123 && randomNumber <= 126) {
        continue;
      }
      iteration++;
      password += String.fromCharCode(randomNumber);
    }
    return password;
  };
  const forgetPasswordApiCall = () => {
    const jspwd = handleForgetPasswordSubmit();
    const payload = {
      userLogin: userName,
      newUserPassword: jspwd,
    };
    const config = {
      method: "POST",
      headers: { "content-type": "application/json" },
      credentials: "include",
      body: JSON.stringify(payload),
    };
    fetch(
      `${API_ENDPOINT.BaseUrl}bb-bbwebsite/userManager?method=updateUserPassword`,
      config
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData !== undefined) {
          forgetPasswordApiMsg(payload);
        }
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  };
  const forgetPasswordApiMsg = (payload) => {
    const config = {
      method: "POST",
      headers: { "content-type": "application/json" },
      credentials: "include",
      body: JSON.stringify(payload),
    };
    fetch(
      `${API_ENDPOINT.BaseUrl}bb-bbwebsite/userManager?method=passwordRecoveryMessage`,
      config
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData !== undefined) {
          handleClose();
          handleClickOpen1();
        }
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  return (
    <Fragment>
      <div className="provider__container">
        <div className="provider__title">
          <span className="provider__title__text">
            {STRINGS.PROVIDERS.TITLE}
          </span>
        </div>

        <div className="error-text">
          {errorMessage && (
            <CustomError
              errorMessage={errorMessage}
              isNotFixedPosition={true}
            />
          )}
          {props.errorMessage !== "" && (
            <CustomError
              serverErrorMessage={props.errorMessage}
              isNotFixedPosition={true}
              isClose={true}
              onClose={props.resetError}
            />
          )}
        </div>
        <TextField
          id={STRINGS.PROVIDERS.USERNAME}
          label={STRINGS.PROVIDERS.USERNAME}
          type="text"
          value={userName}
          name="userName"
          error={isNameHighlighted}
          handleChange={(event) => handleChange(event, "userName")}
        />
        <TextField
          id={STRINGS.PROVIDERS.PASSWORD}
          label={STRINGS.PROVIDERS.PASSWORD}
          type="password"
          name="password"
          value={password}
          error={isPasswordHighlighted}
          handleChange={(event) => handleChange(event, "password")}
        />

        <div className="forgot__password py-3" onClick={handleClickOpen}>
          {STRINGS.PROVIDERS.FORGOT_PWD}
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              borderRadius: "30px",
              width: "100%",
            },
          }}
        >
          <DialogContent>
            <Cancel onClick={handleClose} />
            <DialogContentText style={{ color: "black" }}>
              Use this page to request the new password. Your new password will
              be sent to your registered email Id/ Phone No.
            </DialogContentText>
            <div>
              <TextField
                id={STRINGS.CREATE_USER_FORM.USERNAME}
                label="Please enter your login id :"
                type="text"
                value={userName}
                name={STRINGS.CREATE_USER_FORM.USERNAME}
                handleChange={(event) => handleChange1(event, "userName")}
                //errorBorder={isNameHighlighted}
              />
              {userNamePresentError && (
                <label style={{ color: "red" }}>
                  Please enter valid username
                </label>
              )}
            </div>
          </DialogContent>
          <button
            disabled={userNamePresentError}
            onClick={forgetPasswordApiCall}
            className={"forgetPWDButton"}
          >
            Submit
          </button>
        </Dialog>
        <div>
          <Dialog
            open={open1}
            onClose={handleClose1}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                borderRadius: "30px",
              },
            }}
          >
            <div style={{ marginTop: "1%" }}>
              <Cancel onClick={handleClose1} />
            </div>
            <DialogTitle id="alert-dialog-title">{"Add"}</DialogTitle>

            <DialogContent>
              <DialogContentText
                style={{ color: "black" }}
                id="alert-dialog-description"
              >
                You have requested for a new password successfully. You will
                shortly recieve an email/sms on your registered emailId/PhoneNo.
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>

        <div className="signin__button">
          <Button className={`button__style`} onClick={loginClick}>
            {STRINGS.PROVIDERS.SIGN_IN}
          </Button>
        </div>
        <div className="signup__text py-3">
          {STRINGS.PROVIDERS.SIGN_UP_TEXT1}
          <span className="signup" onClick={handleSignupScreen}>
            {STRINGS.PROVIDERS.SIGN_UP}
          </span>
          {STRINGS.PROVIDERS.SIGN_UP_TEXT2}
        </div>
      </div>
    </Fragment>
  );
}

export default ProviderLogin;
