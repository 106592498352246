import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.backgroundColor.backgroundColor3,
    opacity: "0.9",
    padding: "1%",
    boxShadow: "0px 3px 6px #00000029",
  },
  logo: {
    width: "50%",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
  },

  headerText: {
    fontSize: "20px",
    color: "#707070",
    fontFamily: "Comfortaa",
    // fontWeight: "bold",
  },
  loginSignIn: {
    color: "#484848",
    fontFamily: "Comfortaa",
    //fontWeight: "bold",
    fontSize: "20px",
  },
}));
