import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import "./ViewInstructionComponent.css";

import Cancel from "COMMON/Cancel/Cancel";
import Grid from "@material-ui/core/Grid";

import Avatar from "@material-ui/core/Avatar";

import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {
  showSetupMachineBtn,
  hideViewInstructionBtn,
} from "REDUX/Slices/AppSlice";
import { useDispatch } from "react-redux";
import { showSiteDetailsForm } from "REDUX/Slices/SiteSlice";
import ViewInstructionDrawer from "../ProfileDetails/ViewInstructionDrawer";

const useStyles = makeStyles((theme) => ({
  orange: {
    color: "white",
    backgroundColor: "#FF4355",
    marginLeft: "30px",
    marginRight: "30px",
    width: "30px",
    height: "30px",
    font: "normal normal normal 15px Comfortaa",
    marginTop: "8%",
  },
  para1: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
  },
  para2: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginRight: "5%",
  },
  para3: {
    font: " normal 13.5px/25px Comfortaa",
    color: "#000000",
    marginRight: "2%",
  },
  divider: {
    marginTop: "5%",
    marginBottom: "5%",
    backgroundColor: "red",
  },
  instruction: {
    width: "",
    marginLeft: "4%",
    marginTop: "4%",
    font: "normal normal bold 18px/30px Comfortaa",
    color: "#FF4355",
    opacity: 1,
  },
  yourSite: {
    font: "normal normal normal 13px/25px Comfortaa",
    color: "#000000",
    marginBottom: "2%",
  },
  viewInstructionButton: {
    borderRadius: "23px",
    width: "30%",
    maxWidth: "100%",
    height: "45px",
    marginLeft: "35%",
    // // background: "#484848 0% 0% no-repeat padding-box",
    // boxShadow: "0px 3px 6px #00000029",
    // opacity: 1,
    // color: "#ffffff",
    font: "normal normal bold  12px Comfortaa",
    textTransform: "none",
    // "&:hover": {
    //   backgroundColor: "#484848",
    //   boxShadow: "none",
    // },
  },
}));
export default function ViewInstructionComponent() {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    localStorage.setItem("SecondStep", true);
    dispatch(showSiteDetailsForm());
  };

  // const handleClose = () => {
  //   dispatch(hideViewInstructionBtn());

  //   dispatch(showSetupMachineBtn());

  //   setOpen(false);
  // };
  const classes = useStyles();

  return (
    <div>
      <Button
        hidden
        onClick={handleClickOpen}
        className={classes.viewInstructionButton}
      >
        View Instructions
      </Button>
      <div className={classes.viewInstructionButton}></div>
      <div>
        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              borderRadius: "40px",
            },
          }}
        >
          <div>
            <div className={classes.instruction} id="alert-dialog-title">
              {/* <Cancel handleClose={handleClose} /> */}
              Instructions to complete Site Configuration
            </div>
          </div>
          <DialogContent>
            <div className={classes.yourSite}>
              Your site configuration is complete. To complete your setup:
            </div>
            <div>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid>
                  <Avatar className={classes.orange}>1</Avatar>
                </Grid>
                <Grid>
                  <div className={classes.para1}>
                    Click on "Finish and Download Agent Settings". A "Save As"
                    box should pop up (if it doesn't). Save "config" file to a
                    place that will be easy for you to locate.{" "}
                  </div>
                </Grid>
              </Grid>
              <br />
              <Grid container wrap="nowrap" spacing={2}>
                <Grid>
                  <Avatar size={"2"} className={classes.orange}>
                    2
                  </Avatar>
                </Grid>
                <Grid>
                  <div className={classes.para2}>
                    Click on "Download Bellybaloo Agent" and walk through the
                    necessary steps to complete the setup.
                  </div>
                </Grid>
              </Grid>
            </div>
            <Divider className={classes.divider} variant="middle" />

            <div className={classes.para3}>
              <b>IMPORTANT:</b> Make sure the computer you are using to download
              the Bellybaloo agent is on the same network as your ultrasound
              machine. Once installed, the ultrasound machines will send the
              images to the Bellybaloo Agent so that the patients can view their
              images.
            </div>
            <div className={classes.para3}>
              To setup your Ultrasound machine to communicate with Bellybaloo,
              use the instructions on the next screen.
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <ViewInstructionDrawer />
    </div>
  );
}
