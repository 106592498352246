import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import TextField from "CUSTOM_COMPONENTS/TextField/TextField";
import ButtonComponent from "CUSTOM_COMPONENTS/ButtonComponent/ButtonComponent.jsx";

import STRINGS from "CONSTANTS/Strings";
import { showUserDetails, clearState } from "REDUX/Slices/AppSlice";
import {
  GetSiteName,
  GetInstitutionName,
  GetAgentAETitle,
  storeSiteDetailsObject,
  enableUserScreenToggle,
  clearSiteDetailsData,
} from "REDUX/Slices/SiteSlice";

import "./SiteDetails.scss";

import CountryList from "../../constants/New_Country_State.json";

import { siteSelector } from "../../redux/Slices/SiteSlice";
import Autocomplete from "@mui/material/Autocomplete";
import TextFieldAutoComplete from "@mui/material/TextField";
import { useToastContainer } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import info from "../../resources/images/info.png";
import Grid from "@material-ui/core/Grid";
import IconButton from "@mui/material/IconButton";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));
var phoneno = /^(?=.*?[1-9])[0-9()-]+$/;
// var phoneNoBracket = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;

function SiteDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { siteNameError, institutionNames, agentAETitleError } =
    useSelector(siteSelector);
  const [siteName, setSitename] = useState("");
  const [agentAETitle, setagentAETitle] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [institution, setInstitution] = useState("");
  const [sitePhoneNumber, setSitePhoneNumber] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [snameError, setSnameError] = useState(false);
  const [institutionError, setInstitutionError] = useState(false);
  const [aetError, setAetError] = useState(false);
  const [aetCharLimitError, setAetCharLimitError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNoValidationMsg, setPhoneNoValidationMsg] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [StateList, setStateList] = useState([]);
  const [addressTwo, setAddressTwo] = useState("");
  const { siteDetailsObject } = useSelector(siteSelector);

  /**Toggle Issue:below Code for get old site details when come from userdetails toggle */
  // useEffect(() => {
  //   if (siteDetailsObject.siteName !== undefined) {
  //     setSitename(siteDetailsObject.siteName);
  //     setagentAETitle(siteDetailsObject.aet);
  //     setSitePhoneNumber(siteDetailsObject.sitePhoneNumber);
  //     setAddress(siteDetailsObject.addressString1);
  //     setAddressTwo(siteDetailsObject.addressString2);
  //     setCountry(siteDetailsObject.country);
  //     setState(siteDetailsObject.state);
  //     setCity(siteDetailsObject.city);
  //     setZipCode(siteDetailsObject.zipCode);
  //   }
  // }, []);

  useEffect(() => {
    if (siteName.length > 0) {
      dispatch(GetSiteName(siteName));
    }
    if (agentAETitle.length > 0) {
      dispatch(GetAgentAETitle(agentAETitle));
    }
    dispatch(GetInstitutionName());
  }, [siteName, dispatch, agentAETitle]);
  const isLetter = (str) => {
    return str.match(/[a-z]/i);
  };
  const isNoLimitAboveTen = (str) => {
    return str.length >= 10 && str.length <= 18;
  };
  const isLetterZipCode = (str) => {
    return str.match(/[a-z]/i);
  };
  const isNoLimitAboveFive = (str) => {
    return str.length >= 1 && str.length <= 6;
  };
  const handleChange = (event, obj, type) => {
    let value = event.target.value;

    let capitalized = "";
    if (type !== undefined && obj !== null) {
      if (type === "state") {
        value = obj.abbr;
      } else {
        value = obj.label;
      }
    } else {
      type = obj;
      if (value !== undefined) {
        capitalized = value.toUpperCase();
      }
    }
    switch (type) {
      case "siteName":
        event.target.value = capitalized;
        setSitename(capitalized);
        setSnameError(false);
        break;
      case "institution":
        setInstitution(value);
        setInstitutionError(false);
        break;
      case "agentAETitle":
        event.target.value = capitalized;
        if (event.target.value.length < 5) {
          setAetCharLimitError(true);
        } else {
          setAetCharLimitError(false);
        }
        setagentAETitle(capitalized);
        setAetError(false);

        break;
      case "sitePhoneNumber":
        event.target.value = capitalized;
        if (!isLetter(value) && isNoLimitAboveTen(value)) {
          setPhoneNumberError(false);
        } else {
          setPhoneNumberError(true);
        }
        setSitePhoneNumber(capitalized);

        break;
      case "address":
        event.target.value = capitalized;
        setAddress(capitalized);
        setAddressError(false);
        break;
      case "addressTwo":
        event.target.value = capitalized;
        setAddressTwo(capitalized);
        setAddressError(false);
        break;

      case "state":
        setState(value);
        setStateError(false);
        break;
      case "country":
        var byCountryName = CountryList.filter(function (entry) {
          return entry.label === value;
        });
        setStateList(byCountryName[0].states);

        setCountry(value);
        setCountryError(false);
        break;
      case "city":
        event.target.value = capitalized;
        setCity(capitalized);
        setCityError(false);
        break;
      case "zipCode":
        setZipCode(value);
        if (value !== "") {
          if (!isLetterZipCode(value) && isNoLimitAboveFive(value)) {
            setZipcodeError(false);
          } else {
            setZipcodeError(true);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    dispatch(clearState());
    dispatch(clearSiteDetailsData(false));
  };

  const handleNext = () => {
    let SiteDetailsData = {
      siteName: siteName,
      aet: agentAETitle,
      sitePhoneNumber: sitePhoneNumber,
      addressString1: address,
      addressString2: addressTwo,
      zipCode: zipCode,
      state: state,
      country: country,
      city: city,
    };

    if (siteNameError) {
      return;
    } else if (agentAETitleError) {
      return;
    } else if (!siteName) {
      setSnameError(true);
    } else if (!agentAETitle) {
      setAetError(true);
    } else if (!sitePhoneNumber) {
      setPhoneNumberError(true);
    } else if (phoneNumberError) {
      return;
    } else if (!address) {
      setAddressError(true);
    }
    // else if (!addressTwo) {
    //   setAddressError(true);
    // }
    else if (!country) {
      setCountryError(true);
    } else if (!state) {
      setStateError(true);
    } else if (!city) {
      setCityError(true);
    } else if (!zipCode) {
      setZipcodeError(true);
    } else {
      if (aetCharLimitError === false && zipcodeError === false) {
        dispatch(enableUserScreenToggle(true));
        dispatch(showUserDetails());
        dispatch(storeSiteDetailsObject(SiteDetailsData));
      }
    }
  };
  const getStateNameByAbbr = () => {
    var selectedStateLable = "";
    for (var key in StateList) {
      if (StateList.hasOwnProperty(key)) {
        var value = StateList[key];
        if (value.abbr === state) {
          selectedStateLable = value.label;
        }
      }
    }
    return selectedStateLable;
  };

  return (
    <div className="site__details__container">
      <div className="row d-flex">
        <div className=" col-md-12">
          <TextField
            id={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.SITE_NAME}
            label={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.SITE_NAME}
            type="text"
            value={siteName}
            handleChange={(event) => handleChange(event, "siteName")}
            error={siteNameError ? siteNameError : snameError}
            helperText={
              `${
                siteNameError
                  ? `${STRINGS.SIGNUP_LOGIN.ERRORS.SITE_NAME_API_ERROR}`
                  : ""
              }` ||
              `${
                snameError
                  ? `${STRINGS.SIGNUP_LOGIN.ERRORS.SITE_NAME_REQUIRED}`
                  : ""
              }`
            }
            capitatal={true}
          />
        </div>
        <div className="col-md-6">
          {/* <FormControl className={classes.root}>
            <InputLabel>Institution Names</InputLabel>
            <MuiSelect
              name="Institution Names"
              label="Institution Names"
              options={institutionNames}
              value={institution}
              error={institutionError}
              onChange={(event) => handleChange(event, "institution")}
            >
              {institutionNames.map((item) => (
                <MenuItem key={item.id} value={item.institutionName}>
                  {item.institutionName}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl> */}
        </div>
      </div>
      <Grid container>
        <Grid item xs={10}>
          <TextField
            id={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.AGENT_AE_TITLE}
            label={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.AGENT_AE_TITLE}
            type="text"
            value={agentAETitle}
            error={
              agentAETitleError
                ? agentAETitleError
                : aetError || aetCharLimitError
            }
            helperText={
              `${
                agentAETitleError
                  ? `${STRINGS.SIGNUP_LOGIN.ERRORS.AGENT_AE_API_ERROR}`
                  : ""
              }` ||
              `${
                aetError
                  ? `${STRINGS.SIGNUP_LOGIN.ERRORS.AGENT_AE_REQUIRED}`
                  : ""
              }` ||
              `${
                aetCharLimitError
                  ? `${STRINGS.SIGNUP_LOGIN.ERRORS.AGENT_AE_CHARLIMIT}`
                  : ""
              }`
            }
            name="agentAETitle"
            handleChange={(event) => handleChange(event, "agentAETitle")}
          />
        </Grid>
        <Grid item xs={1} style={{ marginTop: "1.5rem", marginLeft: "2.9rem" }}>
          <Tooltip
            title="This is the unique ID of your site that is used to associate the studies with your site.It must be 5-12 characters with no spaces. This will be used in the network settings in your ultrasound machine."
            placement="top"
          >
            <IconButton>
              <img alt="info" src={info} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <div className="row d-flex">
        <div className="address col-md-6">
          <TextField
            id={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.PHONENUMBER}
            label={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.PHONENUMBER}
            type="text"
            value={sitePhoneNumber}
            name="sitePhoneNumber"
            error={phoneNumberError}
            helperText={
              phoneNumberError
                ? `${STRINGS.SIGNUP_LOGIN.ERRORS.PHONENOVALIDATIONERROR}`
                : ""
            }
            handleChange={(event) => handleChange(event, "sitePhoneNumber")}
          />
        </div>
        <div className="address col-md-6">
          <TextField
            id={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.ADDRESS_LINE_1}
            label={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.ADDRESS_LINE_1}
            type="text"
            value={address}
            name="ADDRESS"
            error={addressError}
            helperText={
              addressError
                ? `${STRINGS.SIGNUP_LOGIN.ERRORS.ADDRESS_REQUIRED}`
                : ""
            }
            handleChange={(event) => handleChange(event, "address")}
          />
        </div>
      </div>
      <div className="row d-flex">
        <div className="address col-md-12">
          <TextField
            id={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.ADDRESS}
            label={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.ADDRESS}
            type="text"
            value={addressTwo}
            name="ADDRESS"
            // error={addressError}
            // helperText={
            //   addressError
            //     ? `${STRINGS.SIGNUP_LOGIN.ERRORS.ADDRESS_REQUIRED}`
            //     : ""
            // }
            handleChange={(event) => handleChange(event, "addressTwo")}
          />
        </div>
      </div>

      <div className="row d-flex">
        <div className="country col-md-6">
          <FormControl className={classes.root}>
            {" "}
            <Autocomplete
              disablePortal
              id="combo-box-demo1"
              options={CountryList}
              onChange={(event, value) => handleChange(event, value, "country")}
              value={country}
              error={countryError}
              // sx={{ width: 361 }}
              renderInput={(params) => (
                <TextFieldAutoComplete
                  variant="standard"
                  {...params}
                  label="country"
                />
              )}
            />
          </FormControl>
        </div>
        <div className="state col-md-6">
          <FormControl className={classes.root}>
            {" "}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={StateList}
              onChange={(event, value) => handleChange(event, value, "state")}
              value={getStateNameByAbbr()}
              error={stateError}
              // sx={{ width: 361 }}
              renderInput={(params) => (
                <TextFieldAutoComplete
                  variant="standard"
                  {...params}
                  label="State"
                />
              )}
            />
          </FormControl>
        </div>
      </div>
      <div className="row d-flex">
        <div className="address col-md-6">
          <TextField
            id={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.CITY}
            label={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.CITY}
            type="text"
            value={city}
            name="city"
            error={cityError}
            helperText={
              addressError ? `${STRINGS.SIGNUP_LOGIN.ERRORS.CITYERROR}` : ""
            }
            handleChange={(event) => handleChange(event, "city")}
          />
        </div>
        <div className="zip code col-md-6">
          <TextField
            id={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.ZIP_CODE}
            label={STRINGS.SIGNUP_LOGIN.SITE_DETAILS.ZIP_CODE}
            type="text"
            value={zipCode}
            name="zipCode"
            handleChange={(event) => handleChange(event, "zipCode")}
            error={zipcodeError}
            helperText={
              zipcodeError
                ? `${STRINGS.SIGNUP_LOGIN.ERRORS.ZIPCODE_REQUIRED}`
                : ""
            }
          />
        </div>
      </div>
      <div className="row buttonStyle">
        <ButtonComponent buttonStyle={`button`} onClick={handleNext}>
          {STRINGS.SIGNUP_LOGIN.BUTTONS.NEXT}
        </ButtonComponent>

        <ButtonComponent buttonStyle={`cancel`} onClick={handleCancel}>
          {STRINGS.SIGNUP_LOGIN.BUTTONS.CANCEL}
        </ButtonComponent>
      </div>
    </div>
  );
}

export default SiteDetails;
