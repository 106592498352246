import React, { useEffect } from "react";
import { withRouter } from "react-router";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import ButtonComponent from "CUSTOM_COMPONENTS/ButtonComponent/ButtonComponent.jsx";
import CaptchaComponent from "COMMON/CaptchaComponent/CaptchaComponent";
import { useSelector, useDispatch } from "react-redux";
import { clearState, showCreateUser } from "REDUX/Slices/AppSlice";
import CreateUser from "COMPONENTS/CreateUser/CreateUser";
import {
  clearSiteDetailsData,
  showSiteDetailsForm,
} from "./../../redux/Slices/SiteSlice";
import ExistingUser from "COMPONENTS/ExistingUser/ExistingUser";
import { ROUTES } from "CONSTANTS/RouteConstants";

//Constants
import STRINGS from "CONSTANTS/Strings";

//Slices

import {
  setToken,
  LoginPost,
  clearApiResponseState,
} from "REDUX/Slices/ProviderLoginSlice";
import {
  hideCreateUser,
  selectedRadioButton,
  showProviderForm,
} from "REDUX/Slices/AppSlice";
import {
  clearError,
  clearUserStateError,
  userSelector,
  CreateNewUser,
  addEditUserPermission,
  addEditUserSite,
} from "../../redux/Slices/UserSlice";
import { siteSelector, createSite } from "../../redux/Slices/SiteSlice";

import "./UserDetails.scss";
import { useHistory } from "react-router";
import { FormatColorReset } from "@material-ui/icons";
import { loadCaptchaEnginge } from "react-simple-captcha";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function UserDetails() {
  const history = useHistory();
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const { createButton } = useSelector(userSelector);
  // const { buttonClick } = useSelector(siteSelector);
  const { siteDetailsObject } = useSelector(siteSelector);
  const { isRadioButtonSelected, selectedRadioButtonValue } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("Create New User");
  const [buttonClick, setUserDataSecoundPage] = React.useState(true);
  const [existingPassword, setExistingPassword] = React.useState("");
  useEffect(() => {
    dispatch(selectedRadioButton(value));
  }, [dispatch, value]);
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  const handleChange = (event) => {
    let radioButtonValue = event.target.value;
    let data = JSON.parse(localStorage.getItem("createUserData"));
    console.log(data);
    if (radioButtonValue === "Create New User") {
      setValue(radioButtonValue);
      loadCaptchaEnginge(6);
    } else {
      setValue(radioButtonValue);
      loadCaptchaEnginge(6);
    }
    dispatch(selectedRadioButton(radioButtonValue || value));

    dispatch(clearError());
    dispatch(hideCreateUser());
    dispatch(clearUserStateError());
  };
  const handleCancel = () => {
    dispatch(clearState());
    dispatch(clearSiteDetailsData(false));
    dispatch(showCreateUser());
  };

  const validateExistingUser = () => {};
  const checkUserFieldValidation = (userDataSecoundPage) => {
    setExistingPassword(userDataSecoundPage.password);
    if (
      userDataSecoundPage.firstName !== "" &&
      userDataSecoundPage.lastName !== "" &&
      userDataSecoundPage.email !== "" &&
      userDataSecoundPage.phoneNumber !== "" &&
      userDataSecoundPage.userName !== "" &&
      userDataSecoundPage.password !== "" &&
      userDataSecoundPage.confirmPassword !== ""
    ) {
      setUserDataSecoundPage(false);
    } else {
      setUserDataSecoundPage(true);
    }
  };
  const CreateSiteApicall = () => {
    localStorage.setItem("isNewUser", true);
    localStorage.setItem("homeSiteName", siteDetailsObject.siteName);
    const requestData = {
      id: null,
      state: siteDetailsObject.state,
      country: siteDetailsObject.country,
      timeZone: "America/New_York",
      uploadStudyWithoutPatientInfo: false,
      sitePassword: "",
      lastPingTime: null,
      sundayClose: "2012-10-01T09:45:00.000+02:00",
      releaseHours: 24,
      pingFailureProcessed: false,
      institution: null,
      addressString1: siteDetailsObject.addressString1,
      dateModified: null,
      mondayClose: "2012-10-01T09:45:00.000+02:00",
      addressString2: siteDetailsObject.addressString2,
      wednesdayOpen: "2012-10-01T09:45:00.000+02:00",
      tuesdayOpen: "2012-10-01T09:45:00.000+02:00",
      fridayClose: "2012-10-01T09:45:00.000+02:00",
      saturdayOpen: "2012-10-01T09:45:00.000+02:00",
      thursdayOpen: "2012-10-01T09:45:00.000+02:00",
      thursdayClose: "2012-10-01T09:45:00.000+02:00",
      saturdayClose: "2012-10-01T09:45:00.000+02:00",
      tuesdayClose: "2012-10-01T09:45:00.000+02:00",
      wednesdayClose: "2012-10-01T09:45:00.000+02:00",
      aet: siteDetailsObject.aet,
      sitePhoneNumber: siteDetailsObject.sitePhoneNumber,
      holdHours: 24,
      aeDesc: "",
      siteStatus: "Approved",
      mobileTag: null,
      sundayOpen: "2012-10-01T09:45:00.000+02:00",
      siteName: siteDetailsObject.siteName,
      fridayOpen: "2012-10-01T09:45:00.000+02:00",
      emailTag: null,
      machines: [],
      zipCode: siteDetailsObject.zipCode,
      city: siteDetailsObject.city,
      mondayOpen: "2012-10-01T09:45:00.000+02:00",
      dateAdded: null,
    };

    dispatch(createSite(requestData)).then((response) => {
      try {
        if (response.payload.isSuccess) {
          const siteData = response.payload.site;
          const siteDataObj = JSON.parse(siteData);
          localStorage.setItem("siteData", siteData);
          if (selectedRadioButtonValue === "Create New User") {
            console.log("Create new user radio button selected");

            let userData = localStorage.getItem("createUserData");
            const obj = JSON.parse(userData);

            const userDataObject = {
              userFirstName: obj.firstName,
              userLastName: obj.lastName,
              userLogin: obj.userName,
              userPassword: obj.password,
              email: obj.email,
              phoneNo: obj.phoneNumber,
              active: true,
            };

            dispatch(CreateNewUser(userDataObject)).then((response) => {
              const permissionObject = {
                userLogin: obj.userName,
                roleId: "2",
                permissions: ["1", "2", "3"],
              };
              dispatch(addEditUserPermission(permissionObject)).then(
                (response) => {
                  let siteId = siteDataObj.id;
                  if (siteId !== null && siteId !== undefined) {
                    siteId = siteId.toString();
                  }
                  const assignSiteObject = {
                    userLogin: obj.userName,
                    siteId: [siteId],
                  };
                  dispatch(addEditUserSite(assignSiteObject)).then(
                    (response) => {
                      if (response.payload.isSuccess) {
                        let requestData = {
                          j_username: obj.userName,
                          j_password: obj.password,
                        };

                        dispatch(setToken("123"));
                        dispatch(LoginPost(requestData)).then((response) => {
                          if (localStorage.getItem("isSuccess") === "true") {
                            dispatch(clearApiResponseState());
                            history.push(ROUTES.DASHBOARD);
                            handleCancel();
                          } else {
                            CreateSiteApicall();
                          }
                        });
                      }
                    }
                  );
                }
              );
            });
          } else {
            console.log("Exisiting user radio button selected");
            const userName = localStorage.getItem("userName");
            localStorage.setItem("logInUserName", userName);
            let siteId = siteDataObj.id;
            siteId = siteId.toString();
            const assignSiteObject = {
              userLogin: userName,
              siteId: [siteId],
            };
            dispatch(addEditUserSite(assignSiteObject)).then((response) => {
              if (response.payload.isSuccess) {
                let requestData = {
                  j_username: userName,
                  j_password: existingPassword,
                };

                dispatch(setToken("123"));
                dispatch(LoginPost(requestData)).then((response) => {
                  if (localStorage.getItem("isSuccess") === "true") {
                    localStorage.setItem("isCreateSite", false);
                    localStorage.setItem("isFromSignUP", true);
                    dispatch(clearApiResponseState());
                    history.push(ROUTES.DASHBOARD);
                    handleCancel();
                  } else {
                    CreateSiteApicall();
                  }
                });
              }
            });
          }
        } else {
          if (localStorage.getItem("isSuccess") === "false") {
            const userName = localStorage.getItem("userName");
            let requestData = {
              j_username: userName,
              j_password: existingPassword,
            };

            dispatch(setToken("123"));
            dispatch(LoginPost(requestData)).then((response) => {
              if (localStorage.getItem("isSuccess") === "true") {
                dispatch(clearApiResponseState());
                localStorage.setItem("isFromSignUP", true);
                history.push(ROUTES.DASHBOARD);
                handleCancel();
              }
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  };

  return (
    <div className="userdetails__container">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Please Try Again.
        </Alert>
      </Snackbar>
      ;
      <div className="radio__container py-2">
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="users"
            name="users"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="Create New User"
              control={<Radio />}
              label="Create New User"
              checked={isRadioButtonSelected}
            />
            <FormControlLabel
              value="Select Existing User"
              control={<Radio />}
              label="Select Existing User"
            />
          </RadioGroup>
        </FormControl>
      </div>
      {isRadioButtonSelected ? (
        <CreateUser checkUserFieldValidation={checkUserFieldValidation} />
      ) : (
        <ExistingUser checkUserFieldValidation={checkUserFieldValidation} />
      )}
      <CaptchaComponent existingUserValidation={validateExistingUser} />
      <div className="buttonStyle">
        {!buttonClick && !createButton ? (
          <ButtonComponent buttonStyle={`create`} onClick={CreateSiteApicall}>
            {STRINGS.SIGNUP_LOGIN.BUTTONS.CREATE}
          </ButtonComponent>
        ) : (
          <ButtonComponent
            buttonStyle={`create`}
            disabled
            onClick={CreateSiteApicall}
          >
            {STRINGS.SIGNUP_LOGIN.BUTTONS.CREATE}
          </ButtonComponent>
        )}

        <ButtonComponent buttonStyle={`cancel`} onClick={handleCancel}>
          {STRINGS.SIGNUP_LOGIN.BUTTONS.CANCEL}
        </ButtonComponent>
      </div>
    </div>
  );
}

export default withRouter(UserDetails);
