import PropTypes from "prop-types";
import React, { Component } from "react";

import "./ToggleButton.scss";

class ToggleButton extends Component {
  render() {
    const { selected, toggleSelected } = this.props;
    return (
      <div className="toggle-container" onClick={toggleSelected}>
        <div className={`dialog-button ${selected ? "" : "disabled"}`}>
          {selected ? "USER DETAILS" : "SITE DETAILS"}
        </div>
        {selected ? (
          <div className="site__details">SITE DETAILS</div>
        ) : (
          <div className="user__details">USER DETAILS</div>
        )}
      </div>
    );
  }
}

ToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
};

export default ToggleButton;
