import React from "react";
import NavBar from "../Common/Sidebar/Sidebar";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import ProviderLogout from "../ProviderLogout/ProviderLogout";
import { useHistory } from "react-router";
const drawerWidth = "auto";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    // backgroundColor: "#E4E4E4",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    color: "white",
  },
  root: {
    display: "flex",
  },
}));

function Layout(props) {
  const classes = useStyles();
  const history = useHistory();

  //   window.onhashchange = function () {
  //     const isLogout = localStorage.getItem("logout");
  //     if (isLogout === "logout") {
  //       history.pop("/");
  //     }
  // }

  return (
    <div className={classes.root}>
      <NavBar />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: false,
        })}
      >
        <ProviderLogout />

        {props.children}
      </main>
    </div>
  );
}

export default Layout;
