import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Components
import TextField from "CUSTOM_COMPONENTS/TextField/TextField";

import { CheckUsername, userSelector } from "../../redux/Slices/UserSlice";

//Constants
import STRINGS from "./../../constants/Strings";

//styles
import "./CreateUser.scss";
var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
var strongRegex = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,})$/);

var dataObject = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  userName: "",
  password: "",
  confirmPassword: "",
}
function CreateUser(props) {
  const { createUserNameError } = useSelector(userSelector);
  const [firstName, setFirstName] = useState("");
  const [fnameError, setFnameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastnameError, setLastnameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [userName, setUsername] = useState("");
  const [userNameError, setUsernameError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [phoneNumberErrorMsg, setPhoneNumberErrorMsg] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [cnfPasswordErrorMsg, setCnfPasswordErrorMsg] = useState("")
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [cnfPasswordError, setCnfPasswordError] = useState(false);
  const dispatch = useDispatch();
  const [objectofCreateUser, setObjectofCreateUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    userName: "",
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    dataObject = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      userName: "",
      password: "",
      confirmPassword: "",
    }
  }, [])
  const isLetter = (str) => {
    return str.match(/[a-z]/i);
  };
  const isNoLimitAboveTen = (str) => {
    return str.length >= 10 && str.length <= 18;
  };
  const handleChange = (event, type) => {

    const value = event.target.value;

    try {
      switch (type) {

        case "firstName":
          dataObject.firstName = value
          props.checkUserFieldValidation(dataObject)
          setFirstName(value);
          if (value !== "") {
            setFnameError(false);
          } else {
            dataObject.firstName = "";
            setFnameError(true);
          }

          break;
        case "lastName":
          dataObject.lastName = value
          props.checkUserFieldValidation(dataObject)
          setLastName(value);

          if (value !== "") {
            setLastnameError(false);
          } else {
            dataObject.lastName = "";
            setLastnameError(true);
          }
          break;
        case "email":

          setEmail(value);
          if (value !== "") {
            if (!pattern.test(value)) {
              dataObject.email = "";
              props.checkUserFieldValidation(dataObject)
              setEmailError(true);
              setEmailErrorMsg("Invalid email")
            } else {
              setEmailErrorMsg("")
              setEmailError(false);
              dataObject.email = value
              props.checkUserFieldValidation(dataObject)

            }
          } else {
            setEmailErrorMsg("Email Required")
            setEmailError(true);
            dataObject.email = value
            props.checkUserFieldValidation(dataObject)
          }
          break;
        case "phoneNumber":


          setPhoneNumber(value);
          if (value !== "") {
            if (!isLetter(value) && isNoLimitAboveTen(value)) {
              setPhoneNumberErrorMsg("");
              setPhoneError(false);
              dataObject.phoneNumber = value
              props.checkUserFieldValidation(dataObject)
            } else {
              dataObject.phoneNumber = "";
              props.checkUserFieldValidation(dataObject)
              setPhoneError(true);
              setPhoneNumberErrorMsg("Please enter a valid phone number")
            }

          } else {
            setPhoneNumberErrorMsg("Phone Number Required")
            setPhoneError(true);
            dataObject.phoneNumber = value
            props.checkUserFieldValidation(dataObject)
          }
          break;
        case "userName":
          dataObject.userName = value
          props.checkUserFieldValidation(dataObject)
          localStorage.setItem("logInUserName", value);
          localStorage.setItem("userName", value);
          setUsername(value);
          dispatch(CheckUsername(value));
          if (value !== "") {
            setUsernameError(false);
          } else {
            dataObject.userName = "";
            setUsernameError(true);
          }
          break;
        case "password":

          setPassword(value);
          if (value !== "") {
            if (!strongRegex.test(value)) {
              dataObject.password = "";
              props.checkUserFieldValidation(dataObject)
              setPasswordError(true);
              setPasswordErrorMsg("Password length must be 8 characters with alphanumeric")
            } else {
              setPasswordErrorMsg("");
              setPasswordError(false);
              dataObject.password = value
              props.checkUserFieldValidation(dataObject)

            }

          } else {
            setPasswordErrorMsg("Password Required")
            setPasswordError(true);
            dataObject.password = value
            props.checkUserFieldValidation(dataObject)
          }

          break;
        case "confirmPassword":


          setConfirmPassword(value);
          if (value !== "") {
            if (password !== value) {
              dataObject.confirmPassword = "";
              props.checkUserFieldValidation(dataObject)
              setCnfPasswordError(true);
              setCnfPasswordErrorMsg("Password and Confirm Password does not match")
            } else {
              setCnfPasswordErrorMsg("");
              setCnfPasswordError(false);
              dataObject.confirmPassword = value
              props.checkUserFieldValidation(dataObject)
            }

          } else {
            setCnfPasswordErrorMsg("Password Required")
            setCnfPasswordError(true);
            dataObject.confirmPassword = value
            props.checkUserFieldValidation(dataObject)
          }
          break;

        default:
          break;
      }
    } catch (e) {
      console.log("e: ", e);
    } finally {
      let createUserData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        userName: userName,
        password: password,
        confirmPassword: confirmPassword,
      };
      setObjectofCreateUser(createUserData);
      localStorage.setItem(
        "createUserData",
        JSON.stringify(objectofCreateUser)
      );
    }
  };

  return (
    <div className="create__user__container">
      <div className="d-flex justify-content-between">
        <div className="half__width">
          <TextField
            id={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.FIRST_NAME}
            label={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.FIRST_NAME}
            type="text"
            value={firstName}
            error={fnameError}
            name="firstName"
            helperText={fnameError ? "First name required" : ""}
            handleChange={(event) => handleChange(event, "firstName")}
          />
        </div>
        <div className="half__width">
          <TextField
            id={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.LAST_NAME}
            label={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.LAST_NAME}
            type="text"
            value={lastName}
            name="lastName"
            error={lastnameError}
            helperText={lastnameError ? "Lastname required" : ""}
            handleChange={(event) => handleChange(event, "lastName")}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="half__width">
          <TextField
            id={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.EMAIL}
            label={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.EMAIL}
            type="email"
            value={email}
            name="email"
            error={emailError}
            helperText={emailError ? emailErrorMsg : ""}
            handleChange={(event) => handleChange(event, "email")}
          />
        </div>
        <div className="half__width">
          <TextField
            id={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.PHONE_NO}
            type="text"
            value={phoneNumber}
            name="phoneNumber"
            error={phoneError}
            helperText={phoneError ? phoneNumberErrorMsg : ""}
            label={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.PHONE_NO}
            handleChange={(event) => handleChange(event, "phoneNumber")}
          />


        </div>


      </div>
      <TextField
        id={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.USER_NAME}
        type="text"
        value={userName}
        name="userName"
        error={createUserNameError ? createUserNameError : userNameError}
        helperText={
          `${createUserNameError
            ? `${STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.ERROR.USERNAME_ERROR}`
            : ""
          }` || `${userNameError ? "Username required" : ""}`
        }
        label={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.USER_NAME}
        handleChange={(event) => handleChange(event, "userName")}
      />
      <TextField
        id={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.PASSWORD}
        type="password"
        value={password}
        name="password"
        label={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.PASSWORD}
        error={passwordError}
        helperText={passwordError ? passwordErrorMsg : ""}
        handleChange={(event) => handleChange(event, "password")}
      />

      <TextField
        id={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.CNF_PASSWORD}
        type="password"
        value={confirmPassword}
        name="confirmPassword"
        error={cnfPasswordError}
        helperText={cnfPasswordError ? cnfPasswordErrorMsg : ""}
        label={STRINGS.SIGNUP_LOGIN.USER_DETAILS.CREATE_USER.CNF_PASSWORD}
        handleChange={(event) => handleChange(event, "confirmPassword")}
      />

    </div>
  );
}

export default CreateUser;
