import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { showEditProfileForm } from "REDUX/Slices/SiteSlice";
import CreateUserFormDrawer from "../UserManagementComponent/CreateUserFormDrawer";
import { API_ENDPOINT } from '../../api/ApiBaseUrl'
import RefreshIcon from '@material-ui/icons/Refresh';

const drawerWidth = "275px";
var dataFetched = false;

function createData(
    UserName,
    FirstName,
    LastName,
    UserRole,
    Site,
    MobileNumber,
    EmailAddress,
    id,
    havePermission,
    userPassword
) {
    const density = LastName / UserRole;
    return {
        UserName,
        FirstName,
        LastName,
        UserRole,
        Site,
        MobileNumber,
        EmailAddress,
        id,
        havePermission,
        userPassword
    };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: "UserManagementUserName", label: "User Name", minWidth: 170 },
    { id: "FirstName", label: "First Name", minWidth: 100 },
    {
        id: "UserManagementLastName",
        label: "Last Name",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "UserManagementUserRole",
        label: "User Role",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "UserManagementSite",
        label: "Site",
        minWidth: 170,
        align: "right",

        format: (value) => value.toFixed(2),
    },
    {
        id: "UserManagementMobileNumber",
        label: "Mobile Number",
        minWidth: 170,
        align: "right",
        format: (value) => value.toFixed(2),
    },
    {
        id: "UserManagementEmailAddress",
        label: "Email Address",
        minWidth: 170,
        align: "right",
        format: (value) => value.toFixed(2),
    },
    {
        id: "UserManagementIcon1",
        minWidth: 170,
        align: "right",
        format: (value) => value.toFixed(2),
    },
    {
        id: "UserManagementIcon2",
        minWidth: 170,
        align: "right",
        format: (value) => value.toFixed(2),
    },

];

function EnhancedTableHead(props) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        style={{ backgroundColor: "#C9D9EF" }}
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: "100%",
    },
    container: {
        height: 400,
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        backgroundColor: "#E4E4E4",
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: "1 1 100%",
        color: "#484848",
        font: "normal normal bold 20px Comfortaa",
        marginTop: "1%",
    },
    // AddFilterBtn: {
    //     flex: "1 1 100%",
    //     width: "100%",
    //     height: "42px",
    //     boxShadow: "0px 3px 6px #00000029",
    //     border: "1px solid #2D2929",
    //     borderRadius: "51px",
    //     opacity: 1,
    //     textTransform: "none",
    //     marginLeft: "20px",
    //     font: "normal normal bold 13px Comfortaa",
    // },
    CreateUserBtn: {
        flex: "1 1 100%",
        width: "100%",
        height: "42px",
        background: "#484848 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "51px",

        opacity: 1,
        color: "white",
        textTransform: "none",
        font: "normal normal bold 13px Comfortaa",
        "&:hover": {
            backgroundColor: "#484848",
            boxShadow: "none",
        },
    },
    TextField: {
        flex: "1 1 100%",
        marginTop: "2%",
        width: "auto",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        color: "white",
    },
    inputBase: {
        width: "600px",
        height: "17px",
        textAlign: "left",
        font: "normal normal bold 15px / 17px Comfortaa",
        letterSpacing: "0px",
        color: "#242424",
        opacity: 0.5,
    },
    refreshBtn: {
        flex: "1 1 100%",
        width: "100%%",
        height: "42px",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #2D2929",
        background: "#484848 0% 0% no-repeat padding-box",
        color: "#fff",
        borderRadius: "51px",
        opacity: 1,
        textTransform: "none",
        font: "normal normal bold 13px Comfortaa",
        "&:hover": {
            backgroundColor: "#484848",
            boxShadow: "none",
        },
    },

}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const dispatch = useDispatch();
    var isCreateBtn = false;
    const openCreateUserForm = () => (event) => {
        isCreateBtn = true;
        localStorage.setItem("isCreateUser", true);
        dispatch(showEditProfileForm());
    };
    const handleSearch = (e) => {
        props.handleSearch(e.target.value);
    };
    const refreshBTn = () => {
        props.callGetUserApi()
    }
    return (
        <div className="toolbar">
            <CardContent>
                <div>
                    <div className={classes.root}>
                        <Grid container spacing={3}>
                            <Grid item xs={11}>
                                <Typography
                                    className={classes.title}
                                    variant="h6"
                                    id="tableTitle"
                                    component="div"
                                >
                                    User Management
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={9}>
                                <div>
                                    <TextField
                                        className={classes.inputBase}
                                        placeholder="Search For User Name,First Name,Last Name,Email,Mobile No."
                                        inputProps={{ "aria-label": "search google maps" }}
                                        onChange={handleSearch}
                                    />
                                    <IconButton type="submit" aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                            {/* 
                            <Grid item xs={2}>
                                <Button className={classes.AddFilterBtn} >
                                    Add Filter
                                </Button>
                            </Grid> */}

                            <Grid item xs={2}>
                                <Button
                                    className={classes.CreateUserBtn}
                                    onClick={openCreateUserForm()}
                                >
                                    Create User
                                </Button>
                            </Grid>
                            <Grid item xs>
                                <Button className={classes.refreshBtn}>
                                    <RefreshIcon onClick={refreshBTn} style={{ height: "2rem", width: "2rem" }} />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </CardContent>

            <CreateUserFormDrawer
                selectedUserDataForEdit={props.selectedUserDataForEdit}
                isCreateBtn={isCreateBtn}
                callGetUserApi={props.callGetUserApi}
            />
        </div>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        borderRadius: "5%",
        flexGrow: 1,
    },
    paper: {
        width: "auto",
        borderTopLeftRadius: "30px",
        borderTopRightRadius: "30px",
        borderBottomLeftRadius: "30px",
        borderBottomRightRadius: "30px",
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    container: {
        maxHeight: 450,
        height: 450,
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'flex-end',
        padding: theme.spacing(0, 2),
        paddingLeft: theme.spacing(2),
    },
    tableRow: {
        backgroundColor: "#DADADA",
    },
    loaderStyle: {
        position: "absolute",
        marginLeft: "45%",
        marginTop: "10%"
    }

}));

let roleObj = [];
export default function UserManagementComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [studyResData, setUserResData] = React.useState([]);
    const [currentUserRole, setCurrentUserRole] = useState([]);
    const [rows, setRows] = React.useState([]);
    const [temprows, seTemprows] = React.useState([]);
    const [isRowPerPageSelect, setIsRowPerPageSelect] = React.useState(true);
    const [showLoader, setShowLoader] = React.useState(true);
    const [selectedUserDataForEdit, setSelectedUserDataForEdit] =
        React.useState(null);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setIsRowPerPageSelect(true);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;
    useEffect(() => {
        callGetUserRoleApi()
        callGetUserApi();
    }, []);

    const checkPermission = (role) => {
        let isPresent = false;
        roleObj.map(item => {
            if (item.roleName === role) {
                if (!isPresent) {
                    isPresent = true;
                }
            }
        })
        return isPresent;
    }
    const callGetUserRoleApi = (userName, roleName) => {
        const config = {
            method: "GET",
            headers: { "content-type": "application/json" },
            credentials: "include",
        };
        fetch(
            `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/userManager?method=getVisibleRoles`,
            config
        )
            .then((response) => {
                return response.json();
            })
            .then((responseData) => {
                console.log(responseData);
                if (responseData != undefined) {
                    roleObj = JSON.parse(responseData.simpleroles)
                    setCurrentUserRole(roleObj)
                }
            })
            .catch((err) => {
                console.log("fetch error" + err);
            });
    };

    const callGetUserApi = () => {
        const config = {
            method: "GET",
            headers: { "content-type": "application/json" },
            credentials: "include",
        };
        fetch(
            `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/userManager?method=getUserList`,
            config
        )
            .then((response) => {
                return response.json();
            })
            .then((responseData) => {


                console.log(responseData);
                if (responseData != undefined) {
                    let userData = [];
                    dataFetched = true;
                    setUserResData(responseData.users);
                    responseData.users.map((item) => {
                        let data = createData(
                            item.userLogin,
                            item.firstName,
                            item.lastName,
                            item.roleName,
                            item.siteName,
                            item.phone,
                            item.email,
                            item.id,
                            checkPermission(item.roleName),
                            item.userPassword,
                        );
                        userData.push(data);
                    });
                    setShowLoader(false);
                    setRows(userData);
                    seTemprows(userData);
                }
            })
            .catch((err) => {
                console.log("fetch error" + err);
            });
    };
    const editUser = (e, id) => {
        localStorage.setItem("isCreateUser", false);
        const userData = studyResData;
        var userObject = userData.filter(function (entry) {
            return entry.id === id;
        });
        setSelectedUserDataForEdit(userObject);
        dispatch(showEditProfileForm());
    };
    const deleteUser = (e, UserName) => {

        fetch(
            `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/userManager?method=deleteUser&userLogin=${UserName}`,
            {
                method: "GET",
                credentials: "include",
                header: {
                    Accept: "application/json",
                },
                body: JSON.stringify(),
            }
        )
            .then((response) => {
                return response.json();
            })
            .then((responseData) => {
                callGetUserApi();
            })
            .catch((err) => {
                console.log("fetch error" + err);
            });
    };
    const handleSearch = (searchKeyword) => {
        setIsRowPerPageSelect(false);
        let val = searchKeyword.toLowerCase();
        if (val === "") {
            setIsRowPerPageSelect(true);
        }
        let matches = temprows.filter((v) => {
            return v.UserName?.toLowerCase().includes(val) ||
                v.FirstName?.toLowerCase().includes(val) ||
                v.LastName?.toLowerCase().includes(val) ||
                v.EmailAddress?.toLowerCase().includes(val) ||
                v.MobileNumber?.toLowerCase().includes(val)

        }
        );

        console.log(matches);
        setRows(matches);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    selectedUserDataForEdit={selectedUserDataForEdit}
                    isCreateBtn={false}
                    callGetUserApi={callGetUserApi}
                    handleSearch={handleSearch}
                />
                <TableContainer className={classes.container}>
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                        className={classes.table}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            callGetUserApi={callGetUserApi}
                        />
                        {
                            showLoader === true ? <CircularProgress className={classes.loaderStyle} /> :
                                <TableBody>
                                    {
                                        isRowPerPageSelect ?
                                            stableSort(rows, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    const isItemSelected = isSelected(row.name);
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            key={index + "userMang"}
                                                            selected={isItemSelected}
                                                            className={index % 2 !== 0 ? classes.tableRow : ""}
                                                        >
                                                            <TableCell
                                                                style={{ fontSize: "12px" }}
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                                align="left"
                                                            >
                                                                {row.UserName}
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: "12px" }} align="left">{row.FirstName}</TableCell>
                                                            <TableCell style={{ fontSize: "12px" }} align="left">{row.LastName}</TableCell>
                                                            <TableCell style={{ fontSize: "12px" }} align="left">{row.UserRole}</TableCell>

                                                            <TableCell style={{ fontSize: "12px" }} align="left">{row.Site}</TableCell>
                                                            <TableCell style={{ fontSize: "12px" }} align="left">{row.MobileNumber}</TableCell>
                                                            <TableCell style={{ fontSize: "12px" }} align="left">{row.EmailAddress}</TableCell>
                                                            {/* <TableCell style={{ fontSize: "12px" }} align="left">{row.Status}</TableCell> */}
                                                            <TableCell align="left">
                                                                {<EditIcon hidden={!row.havePermission} onClick={(e) => editUser(e, row.id)} />}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {
                                                                    <DeleteIcon
                                                                        hidden={!row.havePermission} onClick={(e) => editUser(e, row.id)}
                                                                        style={{ color: "red" }}
                                                                        onClick={(e) => deleteUser(e, row.UserName)}
                                                                    />
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }) : stableSort(rows, getComparator(order, orderBy))
                                                    .map((row, index) => {
                                                        const isItemSelected = isSelected(row.name);
                                                        const labelId = `enhanced-table-checkbox-${index}`;

                                                        return (
                                                            <TableRow
                                                                hover
                                                                tabIndex={-1}
                                                                key={index + "userMang"}
                                                                selected={isItemSelected}
                                                                className={index % 2 !== 0 ? classes.tableRow : ""}
                                                            >
                                                                <TableCell
                                                                    style={{ fontSize: "12px" }}
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                    align="left"
                                                                >
                                                                    {row.UserName}
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: "12px" }} align="left">{row.FirstName}</TableCell>
                                                                <TableCell style={{ fontSize: "12px" }} align="left">{row.LastName}</TableCell>
                                                                <TableCell style={{ fontSize: "12px" }} align="left">{row.UserRole}</TableCell>

                                                                <TableCell style={{ fontSize: "12px" }} align="left">{row.Site}</TableCell>
                                                                <TableCell style={{ fontSize: "12px" }} align="left">{row.MobileNumber}</TableCell>
                                                                <TableCell style={{ fontSize: "12px" }} align="left">{row.EmailAddress}</TableCell>
                                                                {/* <TableCell style={{ fontSize: "12px" }} align="left">{row.Status}</TableCell> */}
                                                                <TableCell align="left">
                                                                    {<EditIcon hidden={!row.havePermission} onClick={(e) => editUser(e, row.id)} />}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {
                                                                        <DeleteIcon
                                                                            hidden={!row.havePermission} onClick={(e) => editUser(e, row.id)}
                                                                            style={{ color: "red" }}
                                                                            onClick={(e) => deleteUser(e, row.UserName)}
                                                                        />
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                </TableBody>}
                    </Table>
                </TableContainer>
                <TablePagination

                    style={{ marginRight: "40%" }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage="Rows"
                />
            </Paper>
        </div>
    );
}
