import React, { useState, useEffect, Fragment } from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import TextField from "CUSTOM_COMPONENTS/TextField/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import info from "../../resources/images/info.png";
import STRINGS from "CONSTANTS/Strings";
import {
  GetSiteName,
  GetInstitutionName,
  GetAgentAETitle,
} from "REDUX/Slices/SiteSlice";
import { siteSelector } from "../../redux/Slices/SiteSlice";
import { hideEditProfileForm } from "REDUX/Slices/SiteSlice";
import Cancel from "COMMON/Cancel/Cancel";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import {

  CreateNewUser,
  addEditUserPermission,
  addEditUserSite,
} from "../../redux/Slices/UserSlice";
import { API_ENDPOINT } from '../../api/ApiBaseUrl'


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  list: {
    width: 665,
  },
  fullList: {
    width: "auto",
  },
  userDetailsTxt: {
    marginLeft: "5%",
    font: "normal normal bold 18px/28px Comfortaa",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
  },
  createBtn: {
    width: "150px",
    height: "50px",
    fontSize: "15px",
    font: "normal normal bold 20px/23px Comfortaa",
    borderRadius: "51px",
    backgroundColor: "#484848",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  updatePasswordBtn: {
    width: "180px",
    height: "50px",
    marginLeft: "2rem",
    fontSize: "15px",
    font: "normal normal bold 15px/20px Comfortaa",
    borderRadius: "51px",
    backgroundColor: "#484848",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  cancelBtn: {
    width: "150px",
    height: "50px",
    fontSize: "15px",
    font: "normal normal bold 20px/23px Comfortaa",
    borderRadius: "51px",
    color: "#484848",
    marginLeft: "5%",
    backgroundColor: "#ffffff",
    border: "1px solid black",
    opacity: 1,
    letterSpacing: "0px",
    "&:hover": {
      backgroundColor: "#ffffff",
      boxShadow: "none",
    },
  },
  okBtn: {
    borderRadius: "23px",
    width: "30%",
    maxWidth: "100%",
    height: "45px",
    marginLeft: "35%",
    marginBottom: "2%",
    background: "#484848 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: 1,
    color: "#ffffff",
    font: "normal normal bold  12px Comfortaa",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
}));

function CreateUserFormComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { institutionNames, agentAETitleError } = useSelector(siteSelector);
  const [siteName, setSitename] = useState("");
  const [agentAETitle, setagentAETitle] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [userRole, setUserRole] = useState([]);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [encryptedPassword, setEncryptedPassword] = useState("");
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [userSite, setUserSite] = useState([]);
  const [userSiteNameArray, setUserSiteNameArray] = useState([]);
  const [selectedMultipleSite, setSelectedMultipleSite] = useState([]);
  const [selectedUserDataForEdit, setSelectedUserDataForEdit] = React.useState(props.selectedUserDataForEdit)
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userNamePresentError, setUserNamePresentError] = useState(false);
  const [userRoleError, setUserRoleError] = useState(false)
  const [multiplesiteError, setMultiplesiteError] = useState(false)
  const [openDialogChangePassword, setOpenDialogChangePassword] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [userId, setUserId] = useState("");
  useEffect(() => {
    if (siteName.length > 0) {
      dispatch(GetSiteName(siteName));
    }
    if (agentAETitle.length > 0) {
      dispatch(GetAgentAETitle(agentAETitle));
    }
    dispatch(GetInstitutionName());
  }, [siteName, dispatch, agentAETitle]);
  useEffect(() => {

    const logInUserName =
      localStorage.getItem("logInUserName");
    if (selectedUserDataForEdit != null && localStorage.getItem('isCreateUser') === "false") {
      setIsEditForm(true);
      setFirstName(selectedUserDataForEdit[0].firstName);
      setLastName(selectedUserDataForEdit[0].lastName)
      setEmailAddress(selectedUserDataForEdit[0].email)
      setPhoneNumber(selectedUserDataForEdit[0].phone)
      setUserName(selectedUserDataForEdit[0].userLogin)
      setPassword(selectedUserDataForEdit[0].userPassword)
      setConfirmPassword(selectedUserDataForEdit[0].userPassword);
      setEncryptedPassword(selectedUserDataForEdit[0].userPassword);
      setUserId(selectedUserDataForEdit[0].id)
      callGetUserRoleApi(selectedUserDataForEdit[0].userLogin, selectedUserDataForEdit[0].roleName);
      callGetUserSiteApi(selectedUserDataForEdit[0].siteName, logInUserName);
    } else {
      callGetUserRoleApi(logInUserName, "");
      callGetUserSiteApi([], logInUserName);
      setIsEditForm(false);

    }

  }, []);

  const callGetUserRoleApi = (userName, roleName) => {

    const config = {
      method: "GET",
      headers: { "content-type": "application/json" },
      credentials: "include",
    };
    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/userManager?method=getVisibleRoles`,
      config
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {

        console.log(responseData);
        if (responseData != undefined) {

          const userRole = JSON.parse(responseData.simpleroles);
          const newUserRole = [];
          (userRole || []).map(item => {
            if (item.roleName === "Regular User" || item.roleName === "Site Admin") {
              newUserRole.push(item);
            }
          });
          setUserRole(newUserRole);
          if (localStorage.getItem('isCreateUser') === "false") {
            userRole.map((item) => {
              if (item.roleName === roleName) {
                setSelectedUserRole(item.id);
              }
            })
          }
        }
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  };
  //Comparer Function    
  const GetSortOrder = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  const callGetUserSiteApi = (siteNameEdited, logInUserName) => {
    const config = {
      method: "GET",
      headers: { "content-type": "application/json" },
      credentials: "include",
    };
    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/siteSupportManager?method=getSiteListByUser`,
      config
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {

        console.log(responseData);
        if (responseData != undefined) {

          if (localStorage.getItem('isCreateUser') === "false") {
            if (siteNameEdited.includes(',')) {
              siteNameEdited = siteNameEdited.replaceAll(' ', '');
            }

            var sNamesarray = siteNameEdited.split(',');
            const userSite = JSON.parse(responseData.sites);
            setUserSite(userSite);
            let editedSiteIds = []
            userSite.map(item => {
              if (sNamesarray.includes(item.siteName)) {
                editedSiteIds.push(item.id)
              }
            })
            setSelectedMultipleSite(editedSiteIds);
            const sortedArray = userSite.sort(GetSortOrder("siteName"));
            setUserSiteNameArray(sortedArray);
          } else {

            const userSite = JSON.parse(responseData.sites);
            const sortedArray = userSite.sort(GetSortOrder("siteName"));
            setUserSiteNameArray(sortedArray);
          }
        }

      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  }


  const checkIsExistingUser = (userName) => {
    const config = {
      method: "GET",
      headers: { "content-type": "application/json" },
      credentials: "include",
    };
    fetch(
      `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/masterManager?method=checkUserLoginPresent&userLogin=${userName}`,
      config
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData != undefined) {

          if (responseData.isUserLoginPresent === true) {
            setUserNamePresentError(true)
          } else {
            setUserNamePresentError(false)
          }

        }
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  }
  const isLetter = (str) => {
    return str.match(/[a-z]/i);
  };
  const isNoLimitAboveTen = (str) => {
    return str.length >= 10 && str.length <= 18;
  };
  const handleChange = (event, type) => {

    const value = event.target.value;

    switch (type) {
      case "userRole":
        setSelectedUserRole(value);
        setUserRoleError(false)
        break;
      case "userSite":
        setSelectedMultipleSite(value);
        setMultiplesiteError(false)
        break;
      case "userFirstName":
        setFirstName(value);
        setFirstNameError(false);
        break;
      case "userLastName":
        setLastName(value)
        setLastNameError(false)
        break;
      case "userEmailAddress":
        setEmailAddress(value)
        setEmailError(false);
        break;
      case "userPhoneNumber":

        if (!isLetter(value) && isNoLimitAboveTen(value)) {
          setPhoneNumberError(false);
        } else {
          setPhoneNumberError(true);
        }
        setPhoneNumber(value)
        break;
      case "userName":
        checkIsExistingUser(value);
        setUserName(value)
        setUserNameError(false)
        break;
      case "userPassword":
        if (confirmPassword !== "") {
          if (confirmPassword === value) {
            setConfirmPasswordError(false);

          } else {
            setConfirmPasswordError(true);
          }
        }
        setPassword(value)
        setPasswordError(false)
        break;
      case "userConfirmPassword":
        if (password === value) {
          setConfirmPasswordError(false);

        } else {
          setConfirmPasswordError(true);
        }
        setConfirmPassword(value)

        break;
      case "country":
        setUserName(value)

        break;
      default:
        break;
    }
  };

  const handleNext = () => (event) => {
    props.nextButtonClicked();
    // let SiteDetailsData = {
    //   siteName: siteName,
    //   institution: institution,
    //   aet: agentAETitle,
    //   addressString1: address,
    //   zipCode: zipCode,
    //   state: state,
    //   country: country,
    // };


  };

  const toggleDrawer = () => (event) => {
    dispatch(hideEditProfileForm());
  };
  const createUser = () => {

    const userDataObject = {
      userFirstName: firstName,
      userLastName: lastName,
      userLogin: userName,
      userPassword: password,
      email: emailAddress,
      phoneNo: phoneNumber,
      active: true,
    };
    if (!selectedUserRole) {
      setUserRoleError(true);
    }
    else if (selectedMultipleSite.length === 0) {
      setMultiplesiteError(true);
    }
    else if (!firstName) {
      setFirstNameError(true);
    } else if (!lastName) {
      setLastNameError(true);
    } else if (!emailAddress) {
      setEmailError(true);
    } else if (!phoneNumber) {
      setPhoneNumberError(true);
    } else if (!userName) {
      setUserNameError(true);
    } else if (!password) {
      setPasswordError(true);
    }
    else if (!confirmPassword) {
      setConfirmPasswordError(true);
    }
    else if (confirmPasswordError) {
      return
    }
    else {
      dispatch(CreateNewUser(userDataObject)).then((response) => {

        const permissionObject = {
          userLogin: userName,
          roleId: selectedUserRole,
          permissions: ["1", "2", "3"],
        };
        dispatch(addEditUserPermission(permissionObject)).then(
          (response) => {
            let selectedSites = selectedMultipleSite
            var selectedMultipleSiteArr = selectedSites.map(function (e) { return e.toString() });

            const assignSiteObject = {
              userLogin: userName,
              siteId: selectedMultipleSiteArr,
            };
            dispatch(addEditUserSite(assignSiteObject)).then(
              (response) => {

                props.callGetUserApi();
              }

            );

          }
        );
      });
      dispatch(hideEditProfileForm());
    }

  }

  const updateUser = () => {

    const userDataObject = {
      id: userId,
      userFirstName: firstName,
      userLastName: lastName,
      userLogin: userName,
      userPassword: password,
      email: emailAddress,
      phoneNo: phoneNumber,
      active: true,
      passwordChanged: false
    };
    if (!selectedUserRole) {
      setUserRoleError(true);
    }
    else if (selectedMultipleSite.length === 0) {
      setMultiplesiteError(true);
    }
    else if (!firstName) {
      setFirstNameError(true);
    } else if (!lastName) {
      setLastNameError(true);
    } else if (!emailAddress) {
      setEmailError(true);
    } else if (!phoneNumber) {
      setPhoneNumberError(true);
    } else if (!userName) {
      setUserNameError(true);
    }

    else {
      fetch(
        `${API_ENDPOINT.BaseUrl}/bb-bbwebsite/userManager?method=updateUserInfo`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userDataObject),
        }
      ).then((response) => {
        return response.json();
      })
        .then((responseData) => {
          const permissionObject = {
            userLogin: userName,
            roleId: selectedUserRole,
            permissions: ["1", "2", "3"],
          };
          dispatch(addEditUserPermission(permissionObject)).then(
            (response) => {
              let selectedSites = selectedMultipleSite
              var selectedMultipleSiteArr = selectedSites.map(function (e) { return e.toString() });

              const assignSiteObject = {
                userLogin: userName,
                siteId: selectedMultipleSiteArr,
              };
              dispatch(addEditUserSite(assignSiteObject)).then(
                (response) => {
                  props.callGetUserApi();
                }
              );
            }
          );
        });
      dispatch(hideEditProfileForm());

    }
  }

  const handleClickOpenDialog = () => {
    const userDataObject = {
      id: userId,
      userFirstName: firstName,
      userLastName: lastName,
      userLogin: userName,
      userPassword: password,
      email: emailAddress,
      phoneNo: phoneNumber,
      active: true,
      passwordChanged: isPasswordChanged
    };
    props.handleClickOpenDialog(userDataObject);
  }
  const rolesExplanationObj = {
    'Regular User': 'A user role who can Release / Hold / Edit and Delete Study.',

    'Site Admin': "A user role who can Release / Hold / Edit and Delete Study. Also this user can manage their Sites."
  }

  return (
    <Fragment>

      <div className="provider__container">
        <div className="provider__title">
          <span className="provider__title__text">
            {isEditForm === true ?
              'Edit User'
              :
              STRINGS.CREATE_USER_FORM.CREATE_A_NEW_USER
            }
            <div onClick={toggleDrawer()}>
              <Cancel />
            </div>
          </span>
        </div>
        <div className="provider__title">
          <span className={classes.userDetailsTxt}>
            {STRINGS.CREATE_USER_FORM.USER_DETAILS}
          </span>
        </div>

        <div className="profileDetailFields">
          <div className="row d-flex">
            <div className="state col-md-6">
              <FormControl className={classes.root}>
                <InputLabel>Select Role</InputLabel>
                <MuiSelect
                  name="userRole"
                  label="userRole"
                  options={userRole}
                  value={selectedUserRole}
                  error={userRoleError}
                  // helperText={
                  //   userRoleError
                  //     ? `${STRINGS.CREAT_USER_ERROR_MSG.USER_ROLE_ERROR}`
                  //     : ""
                  // }
                  onChange={(event) => handleChange(event, "userRole")}
                >
                  {userRole.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <Tooltip
                        title={rolesExplanationObj[item.roleName]}
                        placement="right-end"
                      >
                        <div>{item.roleName}</div>
                      </Tooltip>
                    </MenuItem>
                  ))}

                </MuiSelect>
              </FormControl>
              {userRoleError
                ? <div style={{ marginLeft: "0.5rem", color: "red" }}>{STRINGS.CREAT_USER_ERROR_MSG.USER_ROLE_ERROR}</div> : ""}
            </div>

            <div className="country col-md-6">
              <FormControl className={classes.root}>
                <InputLabel id="demo-mutiple-name-label">
                  Select Site(s)
                </InputLabel>
                <Select
                  labelId="demo-mutiple-name-label"
                  id="demo-mutiple-name"
                  multiple
                  value={selectedMultipleSite}
                  onChange={(event) => handleChange(event, "userSite")}
                  input={<Input />}
                  error={multiplesiteError}
                // helperText={
                //   multiplesiteError
                //     ? `${STRINGS.CREAT_USER_ERROR_MSG.MULTIPAL_SITE_ERROR}`
                //     : ""
                // }
                >
                  {userSiteNameArray.map((item) => (
                    <MenuItem key={item.id} value={item.id} >
                      {item.siteName}
                    </MenuItem>
                  ))}
                </Select>

              </FormControl>
              {multiplesiteError
                ? <div style={{ marginLeft: "0.5rem", color: "red" }}>{STRINGS.CREAT_USER_ERROR_MSG.MULTIPAL_SITE_ERROR}</div> : ""}
            </div>
          </div>
          <div className="row d-flex">
            <div className="address col-md-6">
              <TextField
                id={STRINGS.CREATE_USER_FORM.FIRST_NAME}
                label={STRINGS.CREATE_USER_FORM.FIRST_NAME}
                type="text"
                value={firstName}
                name="FIRSTNAME"
                error={firstNameError}
                helperText={
                  firstNameError
                    ? `${STRINGS.CREAT_USER_ERROR_MSG.FIRST_NAME_ERROR}`
                    : ""
                }
                handleChange={(event) => handleChange(event, "userFirstName")}
              />
            </div>
            <div className="zip code col-md-6">
              <TextField
                id={STRINGS.CREATE_USER_FORM.LAST_NAME}
                label={STRINGS.CREATE_USER_FORM.LAST_NAME}
                type="text"
                value={lastName}
                name="lastName"
                handleChange={(event) => handleChange(event, "userLastName")}
                error={lastNameError}
                helperText={
                  lastNameError
                    ? `${STRINGS.CREAT_USER_ERROR_MSG.LAST_NAME_ERROR}`
                    : ""
                }
              />
            </div>
          </div>
          <div className="row d-flex">
            <div className="address col-md-6">
              <TextField
                id={STRINGS.CREATE_USER_FORM.EMAIL_ADDRESS}
                label={STRINGS.CREATE_USER_FORM.EMAIL_ADDRESS}
                disabled={isEditForm === true}
                type="text"
                value={emailAddress}
                name="emailAddress"
                error={emailError}
                helperText={
                  emailError
                    ? `${STRINGS.CREAT_USER_ERROR_MSG.EMAIL_ADDRESS_ERROR}`
                    : ""
                }
                handleChange={(event) => handleChange(event, "userEmailAddress")}
              />
            </div>
            <div className="zip code col-md-6">
              <TextField
                id={STRINGS.CREATE_USER_FORM.PHONE_NO}
                label={STRINGS.CREATE_USER_FORM.PHONE_NO}
                type="text"
                value={phoneNumber}
                name="PhoneNumber"
                handleChange={(event) => handleChange(event, "userPhoneNumber")}
                error={phoneNumberError}
                helperText={
                  phoneNumberError
                    ? `${STRINGS.CREAT_USER_ERROR_MSG.PHONE_NUMBER_ERROR}`
                    : ""
                }
              />
            </div>
          </div>
          <div>
            <TextField
              id={STRINGS.CREATE_USER_FORM.USERNAME}
              label={STRINGS.CREATE_USER_FORM.USERNAME}
              type="text"
              value={userName}
              name={STRINGS.CREATE_USER_FORM.USERNAME}
              handleChange={(event) => handleChange(event, "userName")}
              error={userNameError}
              helperText={
                userNameError
                  ? `${STRINGS.CREAT_USER_ERROR_MSG.USER_NAME_ERROR}`
                  : ""
              } />
            {userNamePresentError && <label style={{ color: 'red' }}>Username already exists!</label>}
          </div>


          {/* {emailAddress.length === 0 ? <div className="errorMsg"> {STRINGS.PROFILE_DETAILS.ERROR_EMAIL}</div> : ""} */}

          <div className="errorMsg">
            {" "}
            {STRINGS.CREATE_SITE_DETAILS.ERROR_PHONE_NO}
          </div>
        </div>
        <div className="profileButtons">
          {!userNamePresentError ?
            isEditForm === true ?
              <>
                <Button onClick={updateUser} className={classes.createBtn}>
                  Update
            </Button>
                <Button onClick={handleClickOpenDialog} className={classes.updatePasswordBtn}>
                  Update Password
            </Button>
              </>
              :
              <Button onClick={createUser} className={classes.createBtn}>
                {STRINGS.CREATE_USER_FORM.CREATE_BUTTON}
              </Button> :
            <Button disabled className={classes.createBtn}>
              {STRINGS.CREATE_USER_FORM.CREATE_BUTTON}
            </Button>
          }

          <Button className={classes.cancelBtn} onClick={toggleDrawer()}>
            {STRINGS.CREATE_USER_FORM.CANCEL_BUTTON}
          </Button>
        </div>
      </div>
      <div></div>

    </Fragment>

  );
}

export default CreateUserFormComponent;
