import React, {useState} from "react";
import { makeStyles, MenuItem } from "@material-ui/core";
import TextField from "CUSTOM_COMPONENTS/TextField/TextField";
import STRINGS from "CONSTANTS/Strings";
import { StateList } from "CONSTANTS/StateCountryList";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "../../resources/images/InfoIcon.png";
import Select from "@material-ui/core/Select";
import { useHistory } from "react-router";
import Paper from "@material-ui/core/Paper";
import cross_Image from "ASSETS/cancel.svg";
import './SiteConfigFormComponent.scss'
import Group from "../../resources/images/Group.png";

const useStyles = makeStyles((theme) => ({
  textFiledPatient: {
    width: "30px",
    textAlign: "left",
  },
  textFiledAgentConf: {
    width: "80%",
  },
  paper: {
    width: "100%",
    height: "100%",
    borderRadius: "40px",
    textAlign: "left",
  },
  saveBtn: {
    marginLeft: "5%",
    width: "10%",
    fontSize: "15px",
    borderRadius: "51px",
    backgroundColor: "#484848",
    color: "#ffffff",
    border: "1px solid black",
    opacity: 1,
    letterSpacing: "0px",
    "&:hover": {
      backgroundColor: "#484848",
      boxShadow: "none",
    },
  },
  cancelBtn: {
    marginLeft: "5%",
    width: "10%",
    fontSize: "15px",
    borderRadius: "51px",
    backgroundColor: "#ffffff ",
    color: "#484848",
    border: "1px solid black",
    opacity: 1,
    letterSpacing: "0px",
  },
  siteConfigButtons: {
    textAlign: "center",
    marginTop: "5%",
  },
  cancel: {
    right: "10px",
    textAlign: "right",
    padding: "5% 5%",
  },

  cancelImg: {
    width: "30px",
  },
  selectSite:{
    width:"30%",
    
  },
  img:{
    width:"28px",
    height:"28px",
    marginRight:"6%"
  },
  siteConfigAgenConfig:{
    top: "576px",
    left: "247px",
    width: "323px",
    height: "28px",
    textAlign: "left",
    font: "normal normal bold 16px Comfortaa",
    letterSpacing: "0px",
    color: "#484848",
    opacity: 1,
  }
}));

function SiteConfigFormComponent(props) {
  const history = useHistory();
  const classes = useStyles();
  const [patientInfo, setpatientInfo] = useState("");
  const [StudyReleaseTime, setStudyReleaseTime] = useState("");
  const [patientInfoDicamTag, setpatientInfoDicamTag] = useState("");
  const [selectSite, setselectSite] = useState("");
  const [DicamTag, setDicamTag] = useState("");
  const [sitePassword, setsitePassword] = useState("");

  const handleChange = (event, type) => {
    const value = event.target.value;
    let capitalized = value.toUpperCase();
    switch (type) {
      case "patientInfo":
        event.target.value = capitalized;
        setpatientInfo(capitalized);
        // setSnameError(false);
        break;
      case "StudyReleaseTime":
        setStudyReleaseTime(value);
        // setInstitutionError(false);
        break;
      case "patientInfoDicamTag":
        event.target.value = capitalized;
        setpatientInfoDicamTag(capitalized);
        // setAetError(false);
        break;
        case "selectSite":
          event.target.value = capitalized;
          setselectSite(capitalized);
          // setAetError(false);
          break;
      case "DicamTag":
        setDicamTag(value);
        // setAddressError(false);
        break;
      case "sitePassword":
        setsitePassword(value);
        // setZipcodeError(false);
        break;
      default:
        break;
    }
  };

  const handleBack = () => (event) => {
    history.goBack();
  };
  const handleSave = () => (event) => {
    history.goBack();
  };

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={6}>
          <div className="siteConfigForm__container">
            <div className="siteConfigurationTitle">
            <img
                src={Group}
                alt="Ultrasound Status"
                className={classes.img}
              ></img>
              <span>
                {STRINGS.SITECONFIGFORM.SITE_CONFIGURATION}
               
              </span>
            </div>
            <div>
                    {" "}
                    <Select
                      id={STRINGS.SITECONFIGFORM.PATIENT_INFO}
                      type="text"
                      label="Selct Sites"
                      select
                      options={StateList}
                      className={classes.selectSite}
                      value={selectSite}
                      handleChange={(event) => handleChange(event)}
                      //errorBorder={isNameHighlighted}
                    >
                      {" "}
                      <MenuItem>Ten</MenuItem>
                    </Select>
                  </div>
            <div>
              <span className="siteConfigServer">
                {STRINGS.SITECONFIGFORM.SITE_SERVER_CONFIG}
              </span>
            </div>

            <div>
              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <div>
                    {" "}
                    <label >
                      {" "}
                      {STRINGS.SITECONFIGFORM.STUDY_HOLD_TIME}
                    </label>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div>
                    {" "}
                    <TextField
                      id={STRINGS.SITECONFIGFORM.STUDY_AUTO_RELEASE}
                      type="text"
                      className={classes.textFiledPatient}
                      name={STRINGS.SITECONFIGFORM.STUDY_AUTO_RELEASE}
                      value={patientInfo}
                      handleChange={(event) => handleChange(event)}
                    />
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div>
                    <img alt="info" src={InfoIcon} />
                  </div>
                </Grid>
              </Grid>
              {/* {firstName.length === 0 ? <div className="errorMsg"> {STRINGS.PROFILE_DETAILS.ERROR_FIRST_NAME}</div> : ""} */}
              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <div>
                    {" "}
                    <label>
                      {" "}
                      {STRINGS.SITECONFIGFORM.STUDY_AUTO_RELEASE}{" "}
                    </label>
                  </div>
                  
                </Grid>
                <Grid item xs={3}>
                  <div>
                    {" "}
                    <TextField
                      id={STRINGS.SITECONFIGFORM.STUDY_AUTO_RELEASE}
                      type="text"
                      className={classes.textFiledPatient}
                      name={STRINGS.SITECONFIGFORM.STUDY_AUTO_RELEASE}
                      value={patientInfo}
                      handleChange={(event) => handleChange(event)}
                    />
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div>
                    <img alt="info" src={InfoIcon} />
                  </div>
                </Grid>
              </Grid>
              <div style={{ marginBottom: "25px", marginTop: "25px" }}>
                <span className={classes.siteConfigAgenConfig}>
                  {STRINGS.SITECONFIGFORM.SITE_AGENT_CONFIG}
                </span>
              </div>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div>
                    {" "}
                    <label> {STRINGS.SITECONFIGFORM.PATIENT_INFO} </label>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    {" "}
                    <Select
                      id={STRINGS.SITECONFIGFORM.PATIENT_INFO}
                      type="text"
                      select
                      options={StateList}
                      className={classes.textFiledAgentConf}
                      name={STRINGS.SITECONFIGFORM.PATIENT_INFO}
                      value={patientInfoDicamTag}
                      handleChange={(event) => handleChange(event)}
                      //errorBorder={isNameHighlighted}
                    >
                      {" "}
                      <MenuItem>Ten</MenuItem>
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div>
                    <img alt="info" src={InfoIcon} />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div>
                    {" "}
                    <label style={{ marginRight: "30px" }}>
                      {" "}
                      {STRINGS.SITECONFIGFORM.DICOM_TAG}{" "}
                    </label>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    {" "}
                    <TextField
                      id={STRINGS.SITECONFIGFORM.DICOM_TAG}
                      type="text"
                      className={classes.textFiledAgentConf}
                      name={STRINGS.SITECONFIGFORM.DICOM_TAG}
                      value={DicamTag}
                      handleChange={(event) => handleChange(event)}
                      //errorBorder={isNameHighlighted}
                    />
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div>
                    <img alt="info" src={InfoIcon} />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div>
                    {" "}
                    <label style={{ marginRight: "30px" }}>
                      {" "}
                      {STRINGS.SITECONFIGFORM.SITE_PASSWORD}{" "}
                    </label>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    {" "}
                    <TextField
                      id={STRINGS.SITECONFIGFORM.SITE_PASSWORD}
                      type="text"
                      className={classes.textFiledAgentConf}
                      name={STRINGS.SITECONFIGFORM.SITE_PASSWORD}
                      value={sitePassword}
                      handleChange={(event) => handleChange(event)}
                      //errorBorder={isNameHighlighted}
                    />
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div>
                    <img alt="info" src={InfoIcon} />
                  </div>
                </Grid>
              </Grid>

              {/* {emailAddress.length === 0 ? <div className="errorMsg"> {STRINGS.PROFILE_DETAILS.ERROR_EMAIL}</div> : ""} */}

              <div className="">
                {" "}
                {STRINGS.CREATE_SITE_DETAILS_2.ERROR_PHONE_NO}
              </div>
            </div>
          </div>
          <div></div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.cancel} onClick={handleBack()}>
            <img src={cross_Image} className={classes.cancelImg} alt="cancel" />
          </div>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <div className={classes.siteConfigButtons}>
          <Button className={classes.saveBtn} onClick={handleSave()}>
            {STRINGS.SITECONFIGFORM.SAVE_BUTTON}
          </Button>
          <Button className={classes.cancelBtn} onClick={handleBack()}>
            {STRINGS.SITECONFIGFORM.BACK_BUTTON}
          </Button>
        </div>
      </Grid>
    </Paper>
  );
}

export default SiteConfigFormComponent;
